import React, { Component } from 'react';
import { Form, Button, Input, Row, Col, Menu, Radio, Popconfirm, Select, InputNumber, Card, Modal, Divider, message, TreeSelect, Descriptions, Badge } from 'antd';
import { AntTable, AntDescriptions } from '../../../components'
import { Element } from 'react-scroll'
import { SearchOutlined, SaveOutlined } from '@ant-design/icons';
import { observable, toJS } from 'mobx';
import { observer } from "mobx-react"
import moment from 'moment'
import constants from '../../../utils/constants'
import ExportJsonExcel from "js-export-excel"
import serviceStore from '../../../stores/serviceStore';
import staticData from '../../../utils/staticData.json'
import qs from 'qs'
const { Option } = Select;

export default @observer class ServicePurchaseListPartial extends Component {
    store = new serviceStore();

    @observable edit = {
        visible: false,
        item: null
    };

    @observable query=null
    constructor(props) {
        super(props)
        console.log("this.props", this.props);
        this.query = this.props.query;
        this.store.filter.serviceType = this.query.service + ",企业服务";

        console.log("  this.store.filter.serviceType ", this.store.filter.serviceType);
        ////企业 只看自己发布的需求
        //if (this.query.buyId == 3) {
        //    this.store.filter.userId = this.query.id;
        //}

        this.store.filter.enterpriseBuyId = 5;
        if (this.query.buyId == 3) {
          
            this.store.filter.userId = this.query.id;
        }


    }

    async componentDidMount() {
        await this.store.fetch_demand();
    }

    handleTableChange = async (pagination) => {
        try {
            await this.store.fetch_demand(pagination)

        }
        catch (e) {
            message.error(e.message)
        }
    }


    handleSearch = async () => {


        try {
            await this.store.fetch_demand()
        }
        catch (e) {
            message.error(e.message)
        }
    }

    handleQuote = () => {
            
    }

   

    render() {
        const { filter, loading, quoteds, pagination } = this.store
        const { props } = this.props

        const { id,service } = this.props.query
      
        return (
            <>

                <div className='filter-box filter-box-title-110'>

                    <AntDescriptions>

                        <Descriptions.Item label="企业名称" span={1}>
                            <Input defaultValue={filter.companyName} onChange={e => filter.companyName = e.target.value} allowClear />
                        </Descriptions.Item>

                        <Descriptions.Item label="需求标题" span={1}>
                            <Input defaultValue={filter.title} onChange={e => filter.title = e.target.value} allowClear />
                        </Descriptions.Item>

                        <Descriptions.Item label="需求报价" span={1}>
                            <InputNumber defaultValue={filter.minPrice} style={{ width: 80 }} onChange={e => filter.minPrice = e} allowClear />-
                             <InputNumber defaultValue={filter.maxPrice} style={{ width: 80 }} onChange={e => filter.maxPrice = e} allowClear />
                        </Descriptions.Item>

                        <Descriptions.Item label="需求状态" span={1}>
                            <Select
                                style={{ width: '100%' }}
                                value={filter.currentState}
                                onChange={(value, options) => {
                                    filter.currentState = value;
                                }}
                            >
                                <Option value={null}>请选择...</Option>
                                {["可报价", "待回复","已回复", "已完成", "已关闭"].map((d) => {
                                    return (
                                        <Option value={d}>{d}</Option>
                                    )
                                })}

                            </Select> 
                        </Descriptions.Item>

                    </AntDescriptions>

                    <Row>
                        <Col>
                           
                        </Col>
                        <Col flex={1} />
                        <Col>
                            <Button type='primary' onClick={this.handleSearch} icon={<SearchOutlined />} loading={this.loading}>搜索</Button>
                        </Col>
                    </Row>
                </div>


                <Element name="scrollElement">
                    <AntTable
                        dataSource={quoteds}
                        loading={loading}
                        rowKey={record => record.id}
                        pagination={{
                            ...pagination,
                            showSizeChanger: true
                        }}
                        onChange={this.handleTableChange}
                        columns={[
                            {
                                title: '企业名称',
                                width: 100,

                                dataIndex: 'companyName',

                            },

                            {
                                title: '需求类型',
                                width: 100,
                                dataIndex: 'serviceType',
                            },
                            {
                                title: '需求报价',
                                width: 130,
                                render: (text, record) => (

                                    <div style={{ color:'red' }}>
                                        {record.enterprise.budget}
                                    </div>
                                 )
                               
                            },
                           
                           
                            {
                                title: '发布日期',

                                width: 100,
                                render: (text, record) => (
                                    moment(record.createTime).format(constants.DEFAULT_DATETIME_FORMAT)
                                ),
                            },

                            {
                                title: '操作',
                                width: 50,

                                render: (text, record) => (
                                    <>

                                        <Button type='link' onClick={() => {
                                            props.history.push(`/service/detailPurchase?id=${record.id}&buyId=${this.query.buyId}&service=${this.query.service}&key=1`)
                                        }}>详情</Button>



                                    </>
                                ),
                            },
                        ]}
                    />
                </Element>
               
            </>
        )

    }

 
}
import { observable, action } from "mobx"
import api from "../utils/api"
import { remove } from "lodash"

export default class PurviewRoleStore {
    @observable roleList = []
    @observable loading = false
    @observable roleInfo = {}



    @action.bound
    async fetch() {

        try {
            this.loading = true;
            var res = await api.purview.getRoles();
            this.roleList = res.items
        } catch{

        } finally {
            this.loading = false;
        }

      
    }

    @action.bound
    async save() {
        var res = await api.purview.roleEdit(this.roleInfo);
        console.log("res", this.roleInfo);
        return res;
    }

    @action.bound
    async gets() {
        var res = await api.purview.roleById(this.roleInfo);
        this.roleInfo = res.info;
      
    }
}
import React, { Component } from 'react';
import { Form, Button, Input, Row, Col, Modal, Tooltip, PageHeader, Descriptions, Select, Space, message, Popconfirm, Divider, Radio } from 'antd';
import { AntTable, AntDescriptions } from '../../components'
import { observable } from 'mobx';
import { observer } from "mobx-react"
import { Element } from 'react-scroll'
import { SearchOutlined, SaveOutlined } from '@ant-design/icons';
import moment from 'moment'
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';
import constants from '../../utils/constants'
import serviceStore from '../../stores/serviceStore';
import ExportJsonExcel from "js-export-excel"
import utils from '../../utils';
import staticData from '../../utils/staticData.json'
const { Option } = Select;
export default @observer class Service extends Component {

    store = new serviceStore()

    @observable checkItems = [];
    @observable imgLogo = "";
    @observable account = {
        visible: false,
        item: {}
    }
    state = {
        value: 1,
        showPoster: false,
        mobanVisible:false
    };

    onChange = e => {
        console.log('radio checked', e.target.value);
        this.setState({
            value: e.target.value,
        });
    };
    async componentDidMount() {
        console.log("staticData", staticData);

        this.store.filter.buyId = 2;
        await this.store.fetch();
    }

    handleTableChange = async (pagination) => {
        try {
            await this.store.fetch(pagination)

        }
        catch (e) {
            message.error(e.message)
        }
    }

    handleSearch = async () => {
        const pagination = {
            ...this.store.pagination,
            current: 1,
        }

        try {
            await this.store.fetch(pagination)
        }
        catch (e) {
            message.error(e.message)
        }
    }
    handleIsPublish = async (data) => {
        this.store.dataInfo = data;
        this.store.dataInfo.isPublish = !data.isPublish;
        await this.store.save();
        await this.store.fetch();
    }

    handleDel = async (id) => {
        var res = await this.store.del(id);
        console.log("res", res);
        if (res.code == 0) {
            message.success("删除成功");
            await this.store.fetch();
        } else {
            message.error("删除失败")

        }

    }

    handleExcel = () => {
        let option = {};

        if (this.store.dataList.length == 0) {
            message.error("没有相关数据，导出数据失败"); return;
        }

        option.fileName = "服务商信息" + moment().format('YYYYMMDDHHmmss'); 
        var items = this.store.dataList.map((d) => {
            return {
                ...d,
                cate:d.categorys.map((t, i) => t.cate + (d.categorys.length > i + 1 ? '' : ''))
            }
        })
        option.datas = [
            {
                sheetData: items,
                sheetName: 'Sheet1',
                sheetFilter: ['companyName', 'companyEName', 'name', 'phone', 'email', 'service', 'cate', , 'createTime'],
                sheetHeader: ['公司名称', '公司英文名称',  '姓名', '手机', '邮箱', '提供服务', '产品类别', '添加时间'],
            }
        ]
        let toExcel = new ExportJsonExcel(option);  //生成excel文件
        toExcel.saveExcel();  //下载excel文件
    }

    saveAccount = async () => {
        const { account, passWord, passWord1 } = this.account.item;
        if (utils.isNullOrEmpty(account)) {
            message.error("请输入账户"); return;
        }

        if (utils.isNullOrEmpty(passWord)) {
            message.error("请输入密码"); return;
        }

        if (passWord != passWord1) {
            message.error("两次密码不相等"); return;
        }

        var res = await this.store.save_service(this.account.item);
        console.log("res", res);

        if (res.code == 0) {
            this.account.visible = false

            this.handleSearch();
        } else {
            message.error(res.msg); return;
        }
    }

    allCheck(selectedRows) {

        this.checkItems = selectedRows;
    }


    grendPoster = () => {
    
        var that = this;
        try {
            message.loading("生成中...",);
            this.checkItems.forEach((item, i) => {
                if (item.logoImgs.length > 0) {
                   
                    setTimeout(function () {
                        that.imgLogo = item.logoImgs[0]
                        console.log(" that.imgLogo", that.imgLogo);
                        const node = document.getElementById("node");
                        var hg = document.getElementById("node").offsetHeight;
                        var wh = document.getElementById("node").offsetWidth;
                        const option = {
                            width: wh, // 高度宽度自行设定
                            height: hg,
                            scale: 4// 后面的字表示需要把一个像素转为几个像素
                        }
                        var dome = document.getElementById("pngImg");

                        if (dome != null) {
                            document.getElementById('export-img').removeChild(dome);
                        }
                      
                        domtoimage.toPng(node, { ...option }).then((defaultUrl) => {


                            var img = new Image();
                          
                            img.src = defaultUrl;

                            img.setAttribute('id', 'pngImg');// 方便设置样式

                            document.getElementById('export-img').appendChild(img);


                            var imgName = moment().format('MM月DD日') + '海报';
                            img.addEventListener('click', () => {
                                var link = document.createElement('a');
                                link.download = `${imgName}.png`;
                                link.href = defaultUrl;
                                link.click();
                            })
                          
                            saveAs(defaultUrl, `${imgName}.png`);

                         
                        }).catch((e) => {
                            console.log("error", e)
                        })
                    }, 1000)
                  
                }
            })
        } finally {
            message.destroy()
        }
    }
   

    render() {
        const { filter, pagination, dataList, loading } = this.store
        const { value } = this.state
        const { props } = this.props;
     
        return (
            <>
                <PageHeader title='服务商管理' style={{ paddingBottom: 0}} />

                <div className='filter-box filter-box-title-110'>
                    <AntDescriptions>

                        <Descriptions.Item label="公司名称">
                            <Input defaultValue={filter.companyName} onChange={e => filter.companyName = e.target.value} allowClear />
                        </Descriptions.Item>
                        <Descriptions.Item label="姓名|电话|邮箱" >
                            <Input defaultValue={filter.title} onChange={e => filter.title = e.target.value} allowClear />
                        </Descriptions.Item>
                        <Descriptions.Item label="提供服务" >
                            <Select
                                style={{ width: '100%' }}
                                value={filter.serviceType}
                                onChange={(value, options) => {
                                    filter.serviceType = value;
                                }}
                            >
                                <Option value={null}>请选择...</Option>
                                {staticData.provideServices.map((d) => {
                                    return (
                                        <Option value={d}>{d}</Option>
                                        )
                                })}
                            
                            </Select> 
                        </Descriptions.Item>
                        <Descriptions.Item label="产品类别" >
                            <Select
                                style={{ width: '100%' }}
                               
                                onChange={(value, options) => {
                                    console.log("value", value);
                                    if (value != null) {
                                        filter.cate = staticData.service.filter(c => c.title == value).map((a) => {
                                            return {
                                                ID: a.id,
                                                Cate: a.title
                                            }
                                        })[0];
                                    } else {
                                        filter.cate = null;
                                    }
                                }}
                            >
                                <Option value={null}>请选择...</Option>
                                {staticData.service.map((d) => {
                                    return (
                                        <Option value={d.title}>{d.title}</Option>
                                    )
                                })}
                            </Select> 
                        </Descriptions.Item>
                    </AntDescriptions>
                    <Row>
                        <Col>
                           
                        </Col>
                        <Col flex={1} />
                        <Col>
                            <Button type='primary' style={{ marginBottom: 10 }} onClick={this.handleSearch} icon={<SearchOutlined />} loading={this.loading}>查询</Button>
                        </Col>
                    </Row>
                  

                    <Row>
                        <Col>
                            <Button type='primary' onClick={() => this.handleExcel()}>导出当前服务商信息</Button> <Divider type="vertical" />
                            <Button type='primary' onClick={() => {
                                this.props.history.push(`../service/edit?buyId=${filter.buyId}`)
                            }}>新增服务商</Button> 
                        </Col>
                        <Col flex={1} />
                        <Col>
                            <Button type='primary' onClick={() => {
                                this.setState({
                                    showPoster:true
                                })
                            }}>批量生成海报</Button>
                        </Col>
                    </Row>
                </div>


                <Element name="scrollElement">
                    <AntTable
                        dataSource={dataList}
                        rowSelection={{
                            type: "checkbox",
                            onChange: (selectedRowKeys, selectedRows) => {
                                this.allCheck(selectedRows);
                            },
                            fixed: false
                        }}
                        loading={loading}
                        rowKey={record => record.id}
                        autoHeight="auto"
                        pagination={{
                            ...pagination,
                            showSizeChanger: true
                        }}
                        onChange={this.handleTableChange}
                        columns={[


                            {
                                title: '公司名称',
                                width: 100,
                                ellipsis: true,
                                dataIndex: 'companyName',
                                render: (text, record) => (
                                    <Tooltip placement="topLeft" title={record.companyName}>
                                        {record.companyName}
                                    </Tooltip>
                                ),

                            },
                            {
                                title: '公司英文名称',
                                width: 80,
                                ellipsis: true,
                                dataIndex: 'companyEnName',
                                render: (text, record) => (
                                    <Tooltip placement="topLeft" title={record.companyEnName}>
                                        {record.companyEnName}
                                    </Tooltip>
                                ),
                            },
                            {
                                title: '姓名|电话|邮箱',
                                width: 150,
                                ellipsis: true,
                                render: (text, record) => (
                                    <Tooltip placement="topRight" title={`${record.name}|${record.phone}|${record.email && record.email}`}>
                                        {record.name}<>|</>{record.phone}<>|</>{record.email}
                                    </Tooltip>
                                    ),
                            },
                            {
                                title: '提供服务',
                                width: 80,
                                ellipsis: true,
                                dataIndex: 'service',
                                render: (text, record) => (
                                    <Tooltip placement="topLeft" title={record.service}>
                                        {record.service}
                                    </Tooltip>
                                ),
                            },
                            {
                                title: '产品类别',
                                width: 120,
                                ellipsis: true,
                                render: (text, record) => (

                                    <Tooltip  title={record.categorys && record.categorys.map((t, i) => t.cate + (record.categorys.length > i + 1 ? ',' : ''))}>
                                        {record.categorys && record.categorys.map((t, i) => t.cate + (record.categorys.length > i + 1 ? ',' : ''))}
                                    </Tooltip>
                             
                                    )
                            },
                            {
                                title: '推荐公司',
                                width: 80,
                                dataIndex:'shareName'
                            },
                            {
                                title: '操作',
                                width: 120,
                                align: 'center',
                          
                                render: (text, record) => (
                                    <>
                                        <Button type='link' onClick={() => this.props.history.push(`/service/detail?id=${record.id}&service=${record.service}&buyId=${record.buyId}`)
                                       
                                        }>详情</Button>
                                        <Divider type="vertical" />
                                        <Button type='link' onClick={() => this.props.history.push(`/service/edit?id=${record.id}&buyId=${record.buyId}`)

                                        }>编辑</Button>
                                        {/**   
                                        <Divider type="vertical" />
                                        <Button disabled={record.account} type='link' onClick={() => {
                                            this.account = {
                                                visible: true,
                                                item: record
                                            }
                                        }}>开通账户</Button>**/}
                                        <Divider type="vertical" />
                                        <Popconfirm title="确定要删除吗？" onConfirm={() => { this.handleDel(record.id) }}>
                                            <Button type='link' >删除</Button>
                                        </Popconfirm>
                                    </>
                                ),
                            },
                        ]}
                    />
                </Element>

                {this.renderAccount()}

                <Modal title="生成海报" visible={this.state.showPoster} okText="生成并下载海报" onOk={() => {
                    this.setState({
                        mobanVisible:true
                    })
                    this.grendPoster()
                   
                }} onCancel={() => {
                    this.setState({
                        showPoster:false
                    })
                }}>
                    {/*<div className="mb20 gray9">*/}
                    {/*    选择海报样式*/}
                    {/*</div>*/}
                    <Radio.Group onChange={this.onChange} value={this.state.value} className="">
                        <Row type="flex" align="top">
                        <Radio value={1} style={{ width: "46%", marginBottom: "20px", display: "flex", alignItems:"flex-start" }}>
                                <img src={require("../../assets/image/example/example-img.jpg")} style={{ width: "75%" }} />
                                <div className="mt10" style={{marginLeft:"39px"}}><Button type="primary" onClick={() => {
                                    this.setState({
                                        viewPoster:true
                                    })
                                }}>预览</Button></div>
                        </Radio>
                        </Row>
                    </Radio.Group>
                </Modal>
                <Modal title="海报预览" visible={this.state.viewPoster} onCancel={() => {
                    this.setState({
                        viewPoster: false
                    })
                }} onOk={() => {
                    this.setState({
                        viewPoster: false
                    })
                }}>
                    {/*<Row type="flex" justify="space-between">*/}
                    {/*<div>上一张</div>*/}
                    {/*<div>5/20</div>*/}
                    {/*<div>下一张</div>*/}
                    {/*</Row>*/}
                    <div className='content haibao-box'>
                        <div className='flex-center haibao-img-b'>
                            <div className="haibao-img-haibao">
                                <img src={require("../../assets/image/example/example-img.jpg")} style={{ width: "75%", height: "84px" }} className="mt10" />
                           
                            </div>
                        </div>
                        <div className='haibao-b'>
                            <div className='flex-center'>
                                <div className='haibao-erweima' style={{ width: "30%" }}>
                                    <img src={require("../../assets/image/example/code-erweima.jpg")} style={{ width: "100%" }} />
                                </div>

                            </div>
                            <div className='flex-center mt10'>
                                <span className='haibao-qiye'>长按识别，了解企业详情</span>
                            </div>
                           
                        </div>

                    </div>
                </Modal>

                <Modal title="海报模板" visible={this.state.mobanVisible} onOk={() => {
                    this.setState({
                        mobanVisible: false
                    })
                }} onCancel={() => {
                    this.setState({
                        mobanVisible:false
                    })
                }}>
                    <div id="node" style={{ width: "300px", height: "540px" }}>

                        <div className='content haibao-box' >
                            <div className='flex-center haibao-img-b'>
                                <div className="haibao-img-haibao">
                                    <img src={`${'https://image.gkbts.com' + this.imgLogo}`} style={{ width: "75%", height: "84px" }} className="mt10" />

                                </div>
                            </div>
                            <div className='haibao-b'>
                                <div className='flex-center'>
                                    <div className='haibao-erweima' style={{ width: "30%" }}>
                                        <img src={require("../../assets/image/example/code-erweima.jpg")} style={{ width: "100%" }} />
                                    </div>

                                </div>
                                <div className='flex-center mt10'>
                                    <span className='haibao-qiye'>长按识别，了解企业详情</span>
                                </div>

                            </div>

                        </div>



                    </div>
                </Modal>

          

                <div id="export-img" style={{ display:'none' }}></div>
            </>
        )
    }

    renderAccount() {

        return (
            <Modal
                title="账户编辑"
                centered
                autoComplete="off"
                visible={this.account.visible}
                onCancel={() => this.account.visible = false}
                okText='保存'
                onOk={() => { this.saveAccount() }}
                okButtonProps={{ icon: <SaveOutlined />, htmlType: 'submit' }}
            >

                <Form.Provider>

                    <Form.Item label="账户名称" name="username" rules={[{ required: true }]}>
                        <Input style={{ width: '300px' }} value={this.account.item.account} onChange={(e) => { this.account.item.account = e.target.value }} />

                    </Form.Item>

                    <Form.Item label="账户密码" name="passWord" rules={[{ required: true}]}>
                        <Input.Password style={{ width: '300px' }} value={this.account.item.passWord} onChange={(e) => { this.account.item.passWord = e.target.value }} />

                    </Form.Item>

                    <Form.Item label="再次输入" name="passWord1" rules={[{ required: true }]}>
                        <Input.Password style={{ width: '300px' }} value={this.account.item.passWord1} onChange={(e) => { this.account.item.passWord1 = e.target.value }} />
                    </Form.Item>

                </Form.Provider>

            </Modal>
        )
    }
}
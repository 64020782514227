import React, { Component } from 'react';
import { Descriptions } from 'antd';
import { DescriptionsProps } from 'antd/lib/descriptions'
import omit from 'lodash/omit';

export default class AntDescriptions extends Component<DescriptionsProps> {
    render() {
        const { pagination } = this.props
        const props = omit(this.props, ['pagination'])

        return (
            <Descriptions
                size='small'
                bordered={true}
                column={{ xxl: 4, xl: 3, lg: 2, md: 2, sm: 1, xs: 1 }}
                {...props}
            />
        )
    }
}
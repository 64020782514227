import React, { Component } from 'react';
import { Form, Button, Input, Row, Col, Checkbox, Popconfirm, Radio,Popover, Select, PageHeader, DatePicker, Modal, Divider, Menu, message, Badge, Descriptions} from 'antd';
import { AntTable, AntDescriptions } from '../../components'
import { observable, toJS } from 'mobx';
import { observer } from "mobx-react"
import { Element } from 'react-scroll'
import { SearchOutlined, SaveOutlined } from '@ant-design/icons';
import utils from '../../utils';
import moment from 'moment'
import constants from '../../utils/constants'
import ActivityStore from '../../stores/activityStore';
const { Option } = Select;
const { TextArea } = Input;
const { SubMenu } = Menu;
export default @observer class Activity extends Component {

    store = new ActivityStore()

    @observable modalVisible = false;
    @observable flag = 1;
    constructor(props) {
        super(props)
        console.log("store",this.store);
    }
    async componentDidMount() {

        await this.store.fetch();
    }

    handleTableChange = async () => {
        try {
            await this.store.fetch()

        }
        catch (e) {
            message.error(e.message)
        }
    }

    handleSearch = async () => {
      

        try {
            await this.store.fetch()
        }
        catch (e) {
            message.error(e.message)
        }
    }
    handleIsPublish = async (data) => {
        console.log("data",toJS(data));
        this.store.dataInfo = data;
        this.store.dataInfo.isPublish = !data.isPublish;
        await this.store.save();
        await this.store.fetch();
    }


    handleSave = async () => {
        const { dataInfo } = this.store;
        if (utils.isNullOrEmpty(dataInfo.name)) {
            message.error("请输入活动名称"); return;
        }
        
        var res = await this.store.save();
        if (res.code == 0) {
            this.modalVisible = false;
            message.success("保存成功");
            await this.store.fetch();
        } else {
            message.error(res.message);
        }

    }
  


    render() {
        const { dataInfo, pagination, dataList, loading, filter } = this.store

        return (
            <>
                <PageHeader title='旅讯活动' style={{ paddingBottom: 0 }} />
               
                <div className='filter-box filter-box-title-110'>

                    <Menu onClick={this.handleClick} selectedKeys={['mail']} mode="horizontal">
                        <Menu.Item key="mail">
                            所有活动
                        </Menu.Item>
                        <Menu.Item key="ww">
                            已结束活动
                        </Menu.Item>
                    </Menu>


                    <AntDescriptions style={{ marginTop:20 }}>

                        <Descriptions.Item label="活动类型" span={1}>
                            <Select
                                style={{ width: '100%' }}
                                value={filter.lable}
                                onChange={(value, options) => {
                                    filter.lable = value

                                }}
                            >
                                <Option value={null}>请选择...</Option>
                                <Option value="GDHR">GDHR</Option>
                                <Option value="SOA">SOA</Option>

                            </Select>
                        </Descriptions.Item>

                        <Descriptions.Item label="活动标题" span={1}>
                            <Input defaultValue={filter.title} onChange={e => filter.title = e.target.value} allowClear />
                        </Descriptions.Item>

                        <Descriptions.Item  span={1}>
                            <Radio.Group
                                options={[{ label: '最新发布', value: 1 },
                              
                                { label: '活动开始', value: 2 }
                                ]}
                                onChange={() => {

                                }}
                                value={1}
                                optionType="button"
                                buttonStyle="solid"
                            />
                        </Descriptions.Item>
                    </AntDescriptions>



                    <Row>
                        <Col>
                            <Button type='primary' onClick={() => {
                                this.props.history.push(`/activity/activity-editor/add`)
                            }}>新建活动</Button>
                        </Col>
                        <Col flex={1} />
                        <Col>
                            <Button type='primary' onClick={this.handleSearch} icon={<SearchOutlined />} loading={this.loading}>搜索</Button>
                        </Col>
                    </Row>
                </div>


                <Element name="scrollElement">
                    <AntTable
                   
                        dataSource={dataList}
                        loading={loading}
                        rowKey={record => record.code}
                        pagination={{
                            ...pagination,
                            showSizeChanger: true
                        }}
                      
                        onChange={this.handleTableChange}
                        columns={[
                            {
                                title: '活动名称',
                                width: 150,
                                ellipsis: true,
                                dataIndex: 'name',

                            },

                            {
                                title: '活动时间',
                                width: 100,
                              
                                render: (text, record) => (
                                    moment(record.startDate).format('YYYY.MM.DD')+ "-"+ moment(record.endDate).format('YYYY.MM.DD')
                                    )
                            },
                            {
                                title: '状态',
                                width: 90,
                               
                                render: (text, record) => (
                                    <Badge
                                        color={record.isEditing ? 'yellow' : record.isPublish ? 'green' : '#d9d9d9'}
                                        text={record.isEditing ? '编辑中' : record.isPublish ? '已发布' : '未发布'}
                                    />
                                ),
                            },
                            {
                                title: '编辑人',
                                width: 50,
                             
                                dataIndex: 'fullName',
                            },
                            {
                                title: '创建时间',
                                width: 100,
                              
                                render: (text, record) => (
                                    moment(record.createTime).format(constants.DEFAULT_DATETIME_FORMAT)
                                ),
                            },
                            {
                                title: '操作',
                                width: 120,
                             
                                fixed: 'right',
                                render: (text, record) => (
                                    <>
                                        <div>
                                             <Button type='link' onClick={() => {
                                                this.props.history.push(`/activity/activity-list?id=${record.id}`)
                                            }}>详情</Button>
                                          
                                            {record.permissions && record.permissions.includes("活动视频") &&
                                                <Button type='link'> 上传视频</Button>
                                            }
                                            {record.permissions && record.permissions.includes("活动图片") &&
                                                <Button type='link'> 上传图片</Button>
                                            }
                                            <Divider type="vertical" />
                                            <Button type='link' onClick={() => {
                                                this.handleIsPublish(record)
                                            }}>{record.isPublish ? '取消发布' : '发布'}</Button>
                                            <Divider type="vertical" />
                                            {!record.isPublish &&
                                                <Button type='link'>删除</Button>
                                            }
                                        </div>
                                        

                                    </>
                                ),
                            },
                        ]}
                    />
                </Element>


                
            </>
        )
    }


    editMenuRender = (data) => {

        return (
            <div>
                <p onClick={() => {
                    this.props.history.push(`/activity/activity-list?id=${data.id}`)
                }}>详情</p>
                {data.permissions && data.permissions.includes("活动视频") &&
                    <p>上传视频</p>
                }
                {data.permissions && data.permissions.includes("活动图片") &&
                    <p>上传图片</p>
                }

                <p onClick={() => {
                    this.handleIsPublish(data)
                }}>{data.isPublish ? '取消发布' : '发布'}</p>

                {!data.isPublish &&
                    <p>删除</p>
                }
            </div>
        )
    }
}
import session from './sessionStore';
import news from './newsStore';
import staff from './staffStore'
import staffCompany from './staffCompanyStore'
import staffDepartment from './staffDepartmentStore'
import purviewRoleStore from './purviewRoleStore'
import categoryStore from './categoryStore'
import advertiStore from './advertiStore'
import briefingStore from './briefingStore'
import surveyStore from './surveyStore'
import cardStore from './cardStore'
import activityCategoryStore from './activityCategoryStore'
import videoStore from './videoStore'
import memberStore from './memberStore'
import activityStore from './activityStore'
import serviceStore from './serviceStore'
import drillStore from './drillStore'
export default {
    session,
    news,
    staff,
    staffCompany,
    staffDepartment,
    purviewRoleStore,
    categoryStore,
    advertiStore,
    briefingStore,
    surveyStore,
    cardStore,
    serviceStore,
    activityCategoryStore,
    videoStore,
    memberStore,
    activityStore,
    drillStore
}
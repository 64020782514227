import { observable, action, toJS } from "mobx"
import api from "../utils/api"
import { remove } from "lodash"

export default class PurviewPermissionStore {

    @observable loading = false
    @observable saving = false
    @observable dataList = []
    @observable roleInfo = null

    roleID

    constructor(roleID) {
      
        this.roleID = roleID
    }

    @action.bound
    async fetch() {
       
        try {
            this.loading = true;
            let that = this;
            const role = await api.purview.roleById({ id: this.roleID });
            this.roleInfo = role.info;
            var resource = await api.purview.getResource();
       
            resource.items.forEach(function (it) {

                if (that.roleInfo && that.roleInfo.purviews != null) {
                    it.checked = that.roleInfo.purviews.some(c => c == it.key);
                } else {
                    it.checked = false;
                }
                if (it.children != null) {
                    it.children.forEach(function (t) {
                        if (that.roleInfo.purviews != null)
                            t.checked = that.roleInfo.purviews.some(c => c == t.key);
                        else
                            t.checked = false;

                    })
                }
              
            })

            this.dataList = resource.items

          
      

        } catch{

        } finally {
            this.loading = false;
        }

    }

    @action.bound
    async save() {
        try {
            this.saving = true;
            let keys = [];
            this.dataList.forEach(function (it) {
                if (it.checked) {
                    keys.push(it.key);
                }
                it.children.forEach(function (t) {
                    if (t.checked) {
                        keys.push(t.key);
                    }
                })
            })
            this.roleInfo.purviews = keys;

            var res = await api.purview.roleEdit(this.roleInfo);
            return res;

        } catch{

        } finally {
            this.saving = false;
        }
     
       

    }

    @action.bound
    async gets() {
       
      
    }
}
import { observable, action, toJS } from "mobx"
import api from "../utils/api"


export default class NewsStore {
    @observable dataList = []
    @observable dataInfo = {}
    @observable loading = false

    @observable activityList = []
    @observable activityInfo = {}

    @observable pagination = {
        current: 1,
        pageSize: 15,
        total: 0,
    }


    @observable filter = {
        title: '',
        className: '',
        author: '',
        columnName: '',

    }

    @action.bound
    async fetch_status() {
        const { current, pageSize } = this.pagination;
        const {
            categoryIDs
        } = this.filter;

        var result = await api.news.getNews({
            pageCount: current,
            pageSize,
           
            categoryIDs: categoryIDs
        });

        return result.items
    }

    @action.bound
    async fetch(pagination) {
        pagination = {
            ...this.pagination,
            ...pagination,
        }

        try {
            this.loading = true;
            const { current, pageSize } = pagination;
            const {
                title,
                className,
                author,
                categoryIDs,
                columnName
            } = this.filter;

          
            var result = await api.news.getNews({
                pageCount: current,
                pageSize,
                title,
                className,
                author,
                categoryIDs: categoryIDs,
                columnName
            });
            this.pagination = {
                current,
                pageSize,
                total: result.totalResults
            }
            this.dataList = result.items
            console.log("result",result);
        } catch (e) {
            throw e
        } finally {
            this.loading = false;
        }
    }

    @action.bound
    async gets(id) {
        try {
            this.loading = true;
            var res = await api.news.getNewsById({ id: id, columnId: this.dataInfo.columnId });
            console.log("res", toJS(res));

            this.dataInfo = res.info;
        } catch (e) {
            throw e
        } finally {
            this.loading = false;
        }
    }

    @action.bound
    async save() {

           var res = await api.news.newsEdit(this.dataInfo);
       
       return res;
    }

    @action.bound
    async newsDel(id) {
        try {
            this.loading = true;
            var res = await api.news.newsDel({ id: id });
            return res;
         
        } catch (e) {
            throw e
        } finally {
            this.loading = false;
        }
    }

    @action.bound
    async getActivity() {
        try {
            this.loading = true;
            var res = await api.activity.getActivity();
            console.log("res", res);
            this.activityList = res.items
        } catch{

        } finally {
            this.loading = false;
        }
    }

    @action.bound
    async saveActivity() {
        console.log("this.activityInfo", this.activityInfo);
        var res = await api.activity.categoryEdit(this.activityInfo);
        console.log("res", res);
        return res;
    }

    @action.bound
    async delActivity() {
        var res = await api.activity.categoryDel(this.activityInfo);
        console.log("res", res);
        return res;
    }

}
import { observable, action, toJS } from "mobx"
import api from "../utils/api"


export default class BriefingStore {
    @observable dataList = []
    @observable dataInfo = {}
    @observable loading = false

    @observable pagination = {
        current: 1,
        pageSize: 15,
        total: 0,
    }

    @observable filter = {
        title: '',
        createTime: null,
        author:'',
        context:''
    }


    @action.bound
    async fetch(pagination) {
        pagination = {
            ...this.pagination,
            ...pagination,
        }

        try {
            this.loading = true;
            const { current, pageSize } = pagination;
            const {
                title,
                isPublish,
                createTime,
                author
            } = this.filter;

            var result = await api.news.getBriefings({
                pageCount: current,
                pageSize,
                title,
                author,
                createTime,
                isPublish
            });


            this.pagination = {
                current,
                pageSize,
                total: result.totalResults
            }

            this.dataList = result.items
            console.log("result", result);
        } catch (e) {
            throw e
        } finally {
            this.loading = false;
        }



    }


    @action.bound
    async gets(id) {
        try {
            this.loading = true;
            var res = await api.news.briefingById({ id: id });
            console.log("res", toJS(res));

           
            this.dataInfo = res.info;
            if (res.code == -1) {
                this.refactorInfo();
            }

        } catch (e) {
            throw e
        } finally {
            this.loading = false;
        }
    }

    refactorInfo() {
        this.dataInfo.children = [
            {
                title: '',
                idx: 0,
                items: [],
                inputTitle:''
            },
            {
                title: '',
                idx: 1,
                items: [],
                inputTitle: ''
            }
        ];

    }

    @action.bound
    async save() {

        console.log("res", toJS(this.dataInfo));
        var res = await api.news.briefingEdit(this.dataInfo);

        return res;
    }

    @action.bound
    async del(id) {
        try {
            this.loading = true;
            var res = await api.news.briefingDel({ id: id })
            return res;

        } catch (e) {
            throw e
        } finally {
            this.loading = false;
        }
    }
}
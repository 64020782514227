import React, { Component } from 'react';
import { Form, Button, Input, Row, Col, DatePicker, Alert, PageHeader, message, Divider, Checkbox, Spin, Select, Radio} from 'antd';
import { RichEditor, PicturesWall, VideosWall } from '../../components'
import { observable, toJS } from 'mobx';
import { observer } from "mobx-react"
import ActivityEditorPartial from './layouts/activity-editor.partial'
import constants from '../../utils/constants'
import stores from '../../stores'
import utils from '../../utils';
import ActivityStore from '../../stores/activityStore';
import qs from 'qs'
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;
export default @observer class ActivityEditor extends Component {
    store = new ActivityStore();
    constructor(props) {
        super(props)
        this.query = qs.parse(props.location.search.substr(1))

    }

    async componentDidMount() {

    }

    render() {
        const { editorType } = this.props;
        const { filter, loading, dataInfo } = this.store

        return (
            <>
                <PageHeader
                    title={editorType == constants.EDITOR_TYPE_ADD ? '新建活动' : '修改活动'}
                    onBack={() => {
                        if (!utils.isNullOrEmpty(this.query.categoryID)) {
                            this.props.history.push('../category')
                        } else {
                            this.props.history.push('../index')
                        }
                    }}
                />
                <p style={{ marginBottom: -10, marginTop: -10, marginLeft:30 }}>活动基础信息</p>
                <Divider orientation="left" orientationMargin="0"></Divider>

                <ActivityEditorPartial props={this.props} />
               
            </>
        )

    }
}
import React, { Component } from 'react';
import { withRouter, Link, Redirect } from 'react-router-dom';
import { observable } from 'mobx';
import { observer } from "mobx-react"
import stores from '../stores'
import constants from '../utils/constants'
import utils from '../utils'
import config from '../config'
import logo from '../assets/logo.png'
import { Scrollbars } from 'react-custom-scrollbars';
import { Layout, Menu, Spin, Dropdown, Avatar, Breadcrumb, Col } from 'antd';
import classnames from 'classnames'
import { animateScroll } from 'react-scroll'
import {
    AppstoreOutlined,
    BarChartOutlined,
    CloudOutlined,
    ShopOutlined,
    TeamOutlined,
    UserOutlined,
    UploadOutlined,
    VideoCameraOutlined,
    LaptopOutlined,
    NotificationOutlined,
    LogoutOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    DashboardOutlined,
    PieChartOutlined,
    MessageOutlined,
    ShoppingOutlined,
    SolutionOutlined,
    InboxOutlined,
} from '@ant-design/icons';

const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;


export default @withRouter @observer class MainLayout extends Component {
    @observable isAuthenticating = true
    @observable navChains = []
    @observable navCollapsed = false
    @observable navOpenKeys = []
    @observable navSelectedKeys = []

    navChainsBreak = false
    isLogout = false
    historyUnlisten = null
    hideNav = false

    constructor(props) {
        super(props)

        this.update(props)
        this.hideNav = new Boolean(sessionStorage.getItem('hide_nav')) == true
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.update(props)
    }

    update = (props) => {
        this.navChainsBreak = false

        let navChains = []
        this.findNavChains(props, navChains, constants.SYSTEM_MODULES, 0)
        if (!this.navChainsBreak) {
            navChains.length = 0
        }

        if (navChains.length == 1) {
            this.navSelectedKeys = [navChains[0].key]
        }
        else if (navChains.length > 1) {
            this.navSelectedKeys = [navChains[1].key]

            if (this.navOpenKeys.indexOf(navChains[0].key) < 0) {
                this.navOpenKeys.push(navChains[0].key)
            }
        }
        else {
            this.navSelectedKeys.length = 0
        }

        this.navChains.replace(navChains)
    }

    findNavChains = (props, navChains, modules, level) => {
        for (var m of modules) {
            if (this.navChainsBreak) {
                break
            }
            else {
                navChains.splice(level)
            }

            navChains.push(m)

            var path = this.getItemPath(m)
            if (path && props.location.pathname.toLowerCase() == path.toLowerCase()) {
                this.navChainsBreak = true
                break
            }

            if (m.children) {
                this.findNavChains(props, navChains, m.children, level + 1)
            }
        }
    }

    getItemPath = (item, props) => {
        return typeof item.path == 'function' ? item.path(props) : item.path
    }

    async componentDidMount() {
        await stores.session.isAuthenticated()
        this.isAuthenticating = false
        this.updateNavScrollBars()
    }

    UNSAFE_componentWillMount() {
        this.historyUnlisten = this.props.history.listen(route => {
            // 确保跳转到新页面滚动条会滚到顶部
            animateScroll.scrollToTop({
                duration: 0,
                delay: 0
            })
        });
    }

    componentWillUnmount() {
        this.historyUnlisten && this.historyUnlisten()
    }

    handleScrollbarsRenderThumbVertical = ({ style, ...props }) => {
        const thumbStyle = {
            backgroundColor: '#333D45',
            borderRadius: 'inherit'
        };
        return (
            <div
                style={{ ...style, ...thumbStyle }}
                {...props} />
        );
    }

    handleNavCollapse = (collapsed) => {
        this.navCollapsed = collapsed
    }

    handleLogout = async () => {
        this.isLogout = true
        await stores.session.logout()
    }

    updateNavScrollBars = (openKeys) => {
        if (openKeys) {
            this.navOpenKeys.replace(openKeys)
        }

        setTimeout(() => this.scrollBar && this.scrollBar.update(), 200)
    }

    handleNavMenuSelect = ({ item, key, keyPath, selectedKeys, domEvent }) => {
        var m = utils.treeFind(constants.SYSTEM_MODULES, x => x.key == key)
        m && m.path && this.props.history.push(m.path)
    }

    hasMenuPermission = (key) => {
        if (stores.session.role != null) {
            return stores.session.role.purviews.some(c => c == key);
        }
    }


    render() {
        const user = stores.session.user

        if (!this.isAuthenticating && user == null) {
            return <Redirect to={{ pathname: '/account/login', state: this.isLogout ? null : { redirectUri: this.props.location } }} />
        }

        return (
            <Layout className={this.props.location.pathname == "/ticket/mobile/detail" || this.props.location.pathname == "/ticket/mobile/index" ? 'components-layout-trigger mobileTicket' : 'components-layout-trigger'}>
                <Sider
                    breakpoint="lg"
                    hidden={this.hideNav}
                    collapsedWidth="0"
                    onCollapse={this.handleNavCollapse}
                >
                    <div className="logo">
                        <img src={logo} />
                        {!this.navCollapsed && <h1>{constants.APPLICATION_TITLE}</h1>}
                    </div>
                    <div className="nav_menu">
                        <Scrollbars ref={ref => this.scrollBar = ref} renderThumbVertical={this.handleScrollbarsRenderThumbVertical}>
                            <Menu
                                theme="dark"
                                mode="inline"
                                selectedKeys={this.navSelectedKeys}
                                openKeys={this.navOpenKeys}
                                onOpenChange={this.updateNavScrollBars}
                                onSelect={this.handleNavMenuSelect}
                            >
                                {constants.SYSTEM_MODULES.filter(m => m.hidden !== true).map(m => (
                                    m.children ?
                                        (
                                            m.children.some(sm => sm.hidden !== true && (sm.component || sm.render)) && <SubMenu
                                                key={m.key}
                                                title={m.title}
                                                icon={m.icon}
                                            >
                                                {
                                                    m.children.filter(sm => sm.hidden !== true && (sm => sm.component || sm.render)).map(sm => (
                                                        <Menu.Item
                                                            disabled={!this.hasMenuPermission(sm.key)}
                                                            key={sm.key}
                                                            icon={sm.icon}
                                                        >
                                                            {sm.title}
                                                        </Menu.Item>
                                                    ))
                                                }
                                            </SubMenu>
                                        )
                                        :
                                        (
                                            (m.component || m.render) && <Menu.Item
                                                key={m.key}
                                                icon={m.icon}
                                            >
                                                {m.title}
                                            </Menu.Item>
                                        )
                                ))}
                            </Menu>
                        </Scrollbars>
                    </div>
                </Sider>
                <Layout className={classnames('site-layout', { 'site-layout-full': this.navCollapsed || this.hideNav })}>
                    <Header className={classnames('site-layout-background', { 'header-full': this.navCollapsed || this.hideNav })}>
                        <Breadcrumb>
                            {this.navChains.map((item, index) => (
                                <Breadcrumb.Item key={item.key}>
                                    {item.path && index < this.navChains.length - 1 ? <Link to={this.getItemPath(item, this.props)}>{item.title}</Link> : item.title}
                                </Breadcrumb.Item>
                            ))}
                        </Breadcrumb>
                        <Col flex={1} />
                        <div className="profile">
                            {user && (
                                <Dropdown overlay={
                                    <Menu>
                                        <Menu.Item key="logout" onClick={this.handleLogout}>
                                            <LogoutOutlined />退出登录
                                        </Menu.Item>
                                    </Menu>}>
                                    <div>
                                        <Avatar
                                            size="small"
                                            src={config.faceRoot + user.userLogo + '!30'}
                                        >
                                            {user.displayName && user.displayName[0]}
                                        </Avatar>
                                        {user.displayName}
                                    </div>
                                </Dropdown>
                            )}
                        </div>
                    </Header>
                    <Content>
                        <div className="site-layout-body">
                            <div className='site-layout-background'>
                                <Spin
                                    spinning={this.isAuthenticating}
                                    tip="加载中..."
                                    size="large"
                                >
                                    {(this.isAuthenticating || user == null) && <div style={{ height: 200 }} />}
                                    {!this.isAuthenticating && user != null && this.props.children}
                                </Spin>
                            </div>
                        </div>
                        <div className='site-layout-footfull'></div>
                    </Content>
                    {/*<Footer>Copyright © 2020 Yiqifei 版权所有</Footer>*/}
                </Layout>
            </Layout >
        )
    }
}

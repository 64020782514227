import React, { Component } from 'react';
import { Form, Button, Input, Row, Col, Checkbox, Popconfirm, PageHeader, DatePicker, Modal, Divider, message, Badge } from 'antd';
import { AntTable, AntDescriptions } from '../../components'
import { observable, toJS } from 'mobx';
import { observer } from "mobx-react"
import { Element } from 'react-scroll'
import qs from 'qs'
import { SearchOutlined, SaveOutlined } from '@ant-design/icons';
import utils from '../../utils';
import moment from 'moment'
import constants from '../../utils/constants'
import memberStore from '../../stores/memberStore';
const { TextArea } = Input;
export default @observer class Member extends Component {

    store = new memberStore()

    @observable modalVisible = false;

    @observable categorys= [
        {
            id: 4,
            title: '旅行社/主题旅行服务商'
          
        },
        {
            id: 1,
            title: '旅游机构/协会'
        
        }, {
            id: 2,
            title: '在线旅游企业'
        },
        {
            id: 3,
            title: '酒店/住宿'
        },

        {
            id: 5,
            title: '交通出行服务'
        },
        {
            id: 8,
            title: '商务差旅/会奖旅游'
        },
        {
            id: 6,
            title: '目的地细分领域（当地游玩/餐饮购物等）'
        },
        {
            id: 7,
            title: '数字营销'
        }

    ]

    constructor(props) {
        super(props)
        this.query = qs.parse(props.location.search.substr(1))
        console.log("   this.query", this.query);
    }
    async componentDidMount() {
        this.store.filter.phone = this.query.phone;
        await this.store.fetch();
    }

    handleTableChange = async () => {
        try {
            await this.store.fetch()

        }
        catch (e) {
            message.error(e.message)
        }
    }

    handleSearch = async () => {

        this.store.filter.phone = "";
        try {
            await this.store.fetch()
        }
        catch (e) {
            message.error(e.message)
        }
    }


  
    render() {
        const { dataInfo, pagination, datas, loading } = this.store
        return (
            <>
                <PageHeader title='参展商信息' style={{ paddingBottom: 0 }}
                    onBack={() => this.props.history.push('../account')}
                />

                <div className='filter-box filter-box-title-110'>
                    <AntDescriptions>


                    </AntDescriptions>
                    <Row>
                        <Col>
                        </Col>
                        <Col flex={1} />
                        <Col>
                            <Button type='primary' onClick={this.handleSearch} icon={<SearchOutlined />} loading={this.loading}>全部数据</Button>
                        </Col>
                    </Row>
                </div>


                <Element name="scrollElement">
                    <AntTable
                        dataSource={datas}
                        loading={loading}
                        rowKey={record => record.id}
                        pagination={{
                            ...pagination,
                            showSizeChanger: true
                        }}
                        onChange={this.handleTableChange}
                        columns={[
                            {
                                title: '姓名',
                                width: 80,
                                align: 'center',
                                dataIndex: 'name',

                            },

                            {
                                title: '公司名称',
                                width: 80,
                                dataIndex: 'companyName',
                            },
                            {
                                title: '职位',
                                width: 80,
                                dataIndex: 'legalPerson',
                            },
                            {
                                title: '手机',
                                width: 80,
                                dataIndex: 'buyPhone',
                            },
                            {
                                title: '选择类型',
                                width: 150,
                                render: (text, record) => (

                                    <>{record.categorys.map((r) => {
                                        return (
                                            <div>{r.cate}</div>
                                            )
                                    })}</>
                                ),
                            },
                            {
                                title: '类型',
                                width: 50,
                                align: 'center',
                                render: (text, record) => (
                                    <Badge
                                        color={ record.buyId==0 ? 'green' : '#d9d9d9'}
                                        text={record.buyId==0 ? '买家' : '卖家'}
                                    />
                                ),
                            },
                            {
                                title: '添加时间',
                                width: 100,
                                render: (text, record) => (
                                    moment(record.createTime).format(constants.DEFAULT_DATETIME_FORMAT)
                                ),
                            },
                          
                            {
                                title: '操作',
                                width: 110,
                                align: 'center',
                                fixed: 'right',
                                render: (text, record) => (
                                    <>
                                       
                                        <Divider type="vertical" />
                                        <Button type='link' onClick={() => {
                                            this.store.dataInfo = record
                                            this.modalVisible = true;
                                            console.log(" this.store.dataInfo", toJS(this.store.dataInfo));
                                        }}>详情</Button>
                                        <Divider type="vertical" />


                                    </>
                                ),
                            },
                        ]}
                    />
                </Element>


                {this.modalVisible && (
                    <Modal
                        title={'参展商详情'}
                        centered
                        visible={true}
                        onCancel={() => this.modalVisible = false}
                   
                        onOk={() => { this.modalVisible = false}}
                    >
                        <Form.Provider>

                            <Form.Item label="姓名">
                                {dataInfo.name}
                            </Form.Item>
                            <Form.Item label="职位">
                                {dataInfo.legalPerson}
                            </Form.Item>
                            <Form.Item label="公司名称">
                                {dataInfo.companyName}
                            </Form.Item>
                            <Form.Item label="类型">
                                {dataInfo.buyId == 0 ? '买家' : '卖家'}
                            </Form.Item>
                            <Form.Item label="手机">
                                {dataInfo.buyPhone}
                            </Form.Item>
                            <Form.Item label="邮箱">
                                {dataInfo.email}
                            </Form.Item>
                            <Form.Item label="类别">
                                {dataInfo.categorys && dataInfo.categorys.map((d) => {
                                    return (
                                        <div>
                                            {this.categorys.find(c => c.id == d.id).title}：{d.cate}
                                        </div>
                                        )
                                })}
                            </Form.Item>
                          
                        </Form.Provider>
                    </Modal>
                )}
            </>
        )
    }
}
import React, { Component } from 'react';
import { TreeSelect } from 'antd';

import { observer } from "mobx-react"
import CategoryStore from '../../stores/categoryStore';

const { TreeNode } = TreeSelect
export default @observer class CategorySelectCmpt extends Component {
    store = new CategoryStore()
  
    static defaultProps = {
        categoryName:'请选择栏目'
    }

    constructor(props) {
        super(props)

    }



    async componentDidMount() {
        await this.store.fetch();
    }

    categoryOnclick = (item) => {
     
        this.props.onClick(item);
    }

  

   

    renderTreeNodes = (pcProductCategories) => {
        return (
            pcProductCategories.map(x => (
                <TreeNode
                    key={x.categoryID}
                    value={x.categoryID}
                    title={x.categoryName}
                    dropdownStyle={{ minWidth: 800, overflow: 'auto' }}
                >
                    {x.children && this.renderTreeNodes(x.children)}
                </TreeNode>
            ))
        )
    }

    render() {
        const { categoryList } = this.store;
       
        return (
            <TreeSelect
                showSearch
                allowClear
                multiple
                {...this.props}
            >
                {categoryList && this.renderTreeNodes(categoryList)}
            </TreeSelect>
        )
    }
}
import React, { Component } from 'react';
import { Form, Button, Input, Row, Col, DatePicker, Alert, PageHeader, message, Divider, Checkbox, Spin, Select, Radio } from 'antd';
import { RichEditor, PicturesWall, VideosWall } from '../../../components'
import { observable, toJS } from 'mobx';
import { observer } from "mobx-react"
import constants from '../../../utils/constants'
import BraftEditor from 'braft-editor'
import utils from '../../../utils'
import stores from '../../../stores'
import moment from 'moment'
import ActivityStore from '../../../stores/activityStore';
import qs from 'qs'
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;
export default @observer class ActivityEditorPartial extends Component {

    @observable loadingEnd = false

    @observable categorys = []
    constructor(props) {
        super(props)
     
        this.store = !utils.isNullOrEmpty(props.store) ? props.store : new ActivityStore();
        console.log("this.store", this.store.dataInfo);

        if (!utils.isNullOrEmpty(this.store.dataInfo.id)) {

            //   await this.store.gets(this.store.dataInfo.id);

            if (this.store.dataInfo.content) {

                this.store.dataInfo.content = BraftEditor.createEditorState(this.store.dataInfo.content);
            }
            if (this.store.dataInfo.intro) {

                this.store.dataInfo.intro = BraftEditor.createEditorState(this.store.dataInfo.intro);
            }

            this.store.dataInfo.site = this.store.dataInfo.site && this.store.dataInfo.site.split(',')
           
            this.loadingEnd = true;

        }
     
    }

    async componentDidMount() {
      
       

        let res = await this.store.getType();

        this.categorys = res.item
    }

    publish = () => {
        this.store.dataInfo.isPublish = true;
        this.save();
    }

    save = async () => {
         const { props } = this.props;
        let { dataInfo } = this.store;

        if (utils.isNullOrEmpty(dataInfo.name)) {
            message.error("请输入活动名称"); return;
        }

        if (utils.isNullOrEmpty(dataInfo.categoryName)) {
            message.error("请选择或数据活动类型"); return;
        }
        console.log(" dataInfo", toJS(dataInfo));
        if (dataInfo.intro) {
            dataInfo.intro = dataInfo.intro.toHTML();
        }

        if (dataInfo.content) {
            dataInfo.content = dataInfo.content.toHTML();
        }

        if (dataInfo.site) {
            dataInfo.site = dataInfo.site.join(',');
        }


        const user = stores.session.user
        dataInfo.accountNo = user.accountNo;
        dataInfo.fullName = user.fullName;
        ///dataInfo.permissions = dataInfo.permissions.filter(c => c != "活动基础信息");
        dataInfo.permissions = dataInfo.permissions.filter(c => c != "活动基础信息");
        dataInfo.permissions = dataInfo.permissions.filter(c => c != "表单配置");


        let res = await this.store.save();
        if (res.code == 0) {
            message.success("保存成功");
            props.history.push('/activity/index')
         
        }
        else {
            message.error(res.msg);
        }
    }

    getImageFileList = (images) => {

        let array = [];
        if (!images) {
            return array;
        }
        for (let i = 0; i < images.length; i++) {
            let item = images[i];
            if (item.action != 3) {
                array.push({ uid: i, id: (i + 1), status: 'done', url: item.fileName });
            }

        }


        console.log("array", array);
        return array;

    }


    render() {
        const { props } = this.props;
       
        const { loading, dataInfo } = this.store

     
     
        return (
            <>
             
                <div className='product-single' >
                    <Spin spinning={loading} size='large'></Spin>
                    <Form className='product-single-form'>
                        <Form.Item style={{ marginBottom: 0 }} label="活动名称" name="title">
                            <Form.Item>
                                <Input style={{ width: '60%' }} name="title" value={dataInfo.name} onChange={e => dataInfo.name = e.target.value} />
                            </Form.Item>
                        </Form.Item>

                        <Form.Item style={{ marginBottom: 0 }} label="活动类型" name="title">
                            <Form.Item>
                                <Select
                                    style={{ width: '30%' }}
                                    value={dataInfo.categoryName}
                                    onChange={(value, options) => {
                                        console.log(value);
                                        dataInfo.categoryName = value
                                    }}
                                >
                                    <Option value={null}>请选择...</Option>
                                    {this.categorys.map((d) => {
                                        return (
                                            <Option value={d.key}>{d.key}</Option>
                                        )
                                    })}
                                   
                                </Select>

                                <Input style={{ width: '30%', marginLeft: 5 }} name="title" value={dataInfo.categoryName} onChange={e => dataInfo.categoryName = e.target.value} />
                            </Form.Item>
                        </Form.Item>

                        <Form.Item style={{ marginBottom: 0 }} label="广告图" name="title">
                            <Form.Item style={{ width: '60%' }}>

                                {(utils.isNullOrEmpty(dataInfo.id) || this.loadingEnd) &&
                                    <div>
                                    <PicturesWall
                                        style={{ width: '20%', height: '100' }}
                                        fileList={this.getImageFileList(dataInfo.logoImages)}
                                     
                                        defaultDomain={constants.IMAGE_MDD_DOMAIN}
                                        showPosterIcon={true}
                                        bucketName={constants.IMAGE_MDD_BUCKETNAME}
                                        onChange={(images) => {
                                            dataInfo.logoImages = [];
                                            images.forEach(function (img) {
                                                dataInfo.logoImages.push({
                                                    fileName: img.fileName
                                                });
                                            })

                                        }}
                                    />
                                    <div style={{ color: "red" }}>建议尺寸：346x167</div>
                                    </div>
                                }
                              

                            </Form.Item>
                        </Form.Item>
                     
                        <Form.Item style={{ marginBottom: 0 }} label="首页广告图" name="title">
                            <Form.Item style={{ width: '60%' }}>

                                {(utils.isNullOrEmpty(dataInfo.id) || this.loadingEnd) &&
                                    <div>
                                        <PicturesWall
                                            style={{ width: '20%', height: '150' }}
                                            fileList={this.getImageFileList(dataInfo.pageImages)}

                                            defaultDomain={constants.IMAGE_MDD_DOMAIN}
                                            showPosterIcon={true}
                                            bucketName={constants.IMAGE_MDD_BUCKETNAME}
                                            onChange={(images) => {
                                                dataInfo.pageImages = [];
                                                images.forEach(function (img) {
                                                    dataInfo.pageImages.push({
                                                        fileName: img.fileName
                                                    });
                                                })

                                            }}
                                        />
                                        <div style={{ color: "red" }}>建议尺寸：750x234</div>
                                        <div>
                                            <Checkbox.Group options={['是否显示']} defaultValue={dataInfo.logoImageFlg?'是否显示':''} onChange={(v) => {
                                           
                                                dataInfo.logoImageFlg = v.length > 0;
                                            }} />
                                           
                                        </div>
                                    </div>
                                }


                            </Form.Item>
                        </Form.Item>
                        <Form.Item style={{ marginBottom: 0 }} label="活动时间" name="title">
                            <Form.Item>
                                {(utils.isNullOrEmpty(dataInfo.id) || this.loadingEnd) &&
                                    <RangePicker defaultValue={dataInfo.startDate ? [moment(dataInfo.startDate), moment(dataInfo.endDate)] : null}
                                        onChange={(value) => {
                                            dataInfo.startDate = moment(value[0]).format('YYYY-MM-DD')
                                            dataInfo.endDate = moment(value[1]).format('YYYY-MM-DD')
                                        }} />
                                }
                            
                            </Form.Item>


                        </Form.Item>


                        <Form.Item style={{ marginBottom: 0 }} label="是否支持报名" name="title">
                            <Form.Item>
                                <Radio.Group
                                    options={[{ label: '报名活动', value: true },
                                    { label: '非报名活动', value: false }]}
                                    onChange={(v,o) => {
                                        console.log("v", v);
                                        dataInfo.isActivity = v.target.value;
                                    }}
                                    value={dataInfo.isActivity}
                                    optionType="button"
                                    buttonStyle="solid"
                                />
                            </Form.Item>
                        </Form.Item>
                        <Form.Item style={{ marginBottom: 0 }} label="发布位置" name="title">
                            <Form.Item>
                                {(utils.isNullOrEmpty(dataInfo.id) || this.loadingEnd) &&
                                    <Checkbox.Group options={['小程序', 'pc']} defaultValue={dataInfo.site} onChange={(v) => {
                                        dataInfo.site = v
                                    }} />
                                }
                            </Form.Item>
                        </Form.Item>

                        <Form.Item style={{ marginBottom: 0 }} label="活动报名时间" name="title">
                            <Form.Item>
                                {(utils.isNullOrEmpty(dataInfo.id) || this.loadingEnd) &&
                                    <RangePicker defaultValue={dataInfo.bmStartTime ? [moment(dataInfo.bmStartTime), moment(dataInfo.bmEndTime)] : null}
                                        onChange={(value) => {
                                            dataInfo.bmStartTime = moment(value[0]).format('YYYY-MM-DD')
                                            dataInfo.bmEndTime = moment(value[1]).format('YYYY-MM-DD')
                                        }} />
                                }
                            </Form.Item>
                        </Form.Item>

                        <Form.Item style={{ marginBottom: 0 }} name="title">
                            <Form.Item style={{ marginLeft: 115 }}>
                                {(utils.isNullOrEmpty(dataInfo.id) || this.loadingEnd) &&
                                    <Checkbox.Group options={['活动视频', '活动图片', '参展商信息', '买家信息', '观众']} defaultValue={dataInfo.permissions} onChange={(v) => {
                                        dataInfo.permissions = v;
                                    }} />
                                }
                            </Form.Item>
                        </Form.Item>

                        <Form.Item label="活动简介" >
                            <TextArea style={{ width: '60%' }} rows={2} value={dataInfo.basicIntro} onChange={e => dataInfo.basicIntro = e.target.value} />
                        </Form.Item>

                        <Form.Item label="活动介绍" >
                            {(utils.isNullOrEmpty(dataInfo.id) || this.loadingEnd) &&
                                <RichEditor placeholder="活动介绍" style={{ height: '50%' }} value={dataInfo.intro}
                                    onChange={(value) => {
                                        dataInfo.intro = value;

                                    }}

                                    contentStyle={{ height: 300, boxShadow: 'inset 0 1px 3px rgba(0,0,0,.1)' }} />
                            }
                        </Form.Item>


                        <Form.Item label="活动规则" >
                            {(utils.isNullOrEmpty(dataInfo.id) || this.loadingEnd) &&
                                <RichEditor placeholder="活动规则" style={{ height: '50%' }} value={dataInfo.content}
                                    onChange={(value) => {
                                        dataInfo.content = value;

                                    }}

                                    contentStyle={{ height: 300, boxShadow: 'inset 0 1px 3px rgba(0,0,0,.1)' }} />
                            }
                          
                        </Form.Item>


                        <div className='save-btnbox flex-center'>
                            <Button className='btn btn-primary' onClick={() => this.save()}>保存</Button>
                            <Button className='btn btn-primary' onClick={() => this.publish()}>保存并发布</Button>
                            <Button className='btn btn-customize' onClick={() => {
                                props.history.push('/activity/index')
                            }}>取消</Button>
                        </div>
                    </Form>
                </div>
            </>
        )

    }
}
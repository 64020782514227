import React, { Component } from 'react';
import { Form, Button, Input, Row, Col, Checkbox, Alert, PageHeader, message, Descriptions, DatePicker, Spin, Select } from 'antd';
import { observable } from 'mobx';
import { observer } from "mobx-react"
import constants from '../../utils/constants'
import CardStore from '../../stores/cardStore'
const { Option } = Select;
export default @observer class CardEditor extends Component {
    store = new CardStore();
    async componentDidMount() {

    }

    save() {

    }

    publish() {

    }

    render() {
        const { loading, dataInfo } = this.store
        const { editorType } = this.props;
        return (
            <>
                <PageHeader
                    title={editorType == constants.EDITOR_TYPE_ADD ? '添加卡号' : '编辑卡号'}
                    onBack={() => this.props.history.push('../card')}
                />

                <div className='product-single' >
                    <Spin spinning={loading} size='large'>
                        <Form className='product-single-form'>
                            <Form.Item label="卡类型" >
                                <Select
                                    style={{ width: '30%' }}
                                    value=""
                                    onChange={(value, options) => {


                                    }}
                                >
                                    <Option value={null}>请选择...</Option>
                                    <Option value=""></Option>


                                </Select>

                            </Form.Item>

                            <Form.Item label="卡标签" >
                                <Select
                                    style={{ width: '30%' }}
                                    value=""
                                    onChange={(value, options) => {


                                    }}
                                >
                                    <Option value={null}>请选择...</Option>
                                    <Option value=""></Option>


                                </Select>

                            </Form.Item>

                            <Form.Item label="是否激活" >
                                <Checkbox checked={dataInfo.isActivation} onChange={(e) => { dataInfo.isActivation = e.target.checked }} />
                            </Form.Item>

                            <Form.Item label="是否批量" >
                                <div>
                                    <Checkbox checked={dataInfo.isPl} onChange={(e) => { dataInfo.isPl = e.target.checked }}/> 
                                </div>
                            </Form.Item>
                            {dataInfo.isPl &&
                                <Form.Item label="批量数" >
                                    <Input style={{ width: '30%' }} name="title" value={dataInfo.num} onChange={e => dataInfo.num = e.target.value} />
                                </Form.Item>
                            }

                            <div className='save-btnbox flex-center'>
                                <Button className='btn btn-primary' onClick={() => this.save()}>保存</Button>
                                <Button className='btn btn-primary' onClick={() => this.publish()}>保存并发布</Button>
                                <Button className='btn btn-customize' onClick={() => {
                                    this.props.history.push('../card')
                                }}>取消</Button>
                            </div>
                        </Form>
                    </Spin>
                </div>
            </>
        )
    }
}
import React, { Component } from 'react';
import { Form, Button, Input, Row, Col, Menu, Radio, Popconfirm, Select, Card, Modal, Divider, message, TreeSelect, Descriptions } from 'antd';
import { AntTable, AntDescriptions } from '../../../components'
import { Element } from 'react-scroll'
import { SearchOutlined, SaveOutlined } from '@ant-design/icons';
import { observable, toJS } from 'mobx';
import { observer } from "mobx-react"
import moment from 'moment'
import constants from '../../../utils/constants'
import ExportJsonExcel from "js-export-excel"

import memberStore from '../../../stores/memberStore';
const { Option } = Select;

const { TreeNode } = TreeSelect
export default @observer class ActivityExhibitorsPartial extends Component {
    store = new memberStore();

    @observable edit = {
        visible: false,
        item:null
    };
    constructor(props) {
        super(props)
        console.log("props", this.props);
        this.store.filter.activityId = props.store.dataInfo.id;

        this.store.filter.buyId = props.buyId;
        this.state = {
            categorys: [
                {
                    id: 4,
                    title: '旅行社/主题旅行服务商',
                    data: [
                        "（轻）探险旅游",
                        "文化交流/修学旅游",
                        "入境游",
                        "出境游",
                        "商务旅游",
                        "邮轮度假",
                        "城市观光/步行观光",
                        "赛事旅游",
                        "高端度假",
                        "养生产品/旅游",
                        "亲子/家庭度假",
                        "医疗旅游",
                        "海岛旅游",
                        "定制旅游",
                        "老年旅游",
                        "蜜月旅游/婚庆旅游",
                        "美食旅游",
                        "旅游批发商"
                    ]
                },
                {
                    id: 1,
                    title: '旅游机构/协会',
                    data: [
                        "旅游局",
                        "旅游机构/协会",
                        "旅游代表机构",
                        "会议与观光局"
                    ]
                }, {
                    id: 2,
                    title: '在线旅游企业',
                    data: [
                        "平台",
                        "OTA",
                        "社区/攻略/点评",
                        "垂直搜索网站",
                        "短租平台",
                        "目的地预订平台"
                    ]
                },
                {
                    id: 3,
                    title: '酒店/住宿',
                    data: [
                        "酒店",
                        "度假公寓/民宿",
                        "度假村",
                        "青年旅社",
                        "酒店代表处",
                        "服务式公寓",
                        "会议酒店",
                        "度假租赁",
                        "共享住宿/短租"
                    ]
                },

                {
                    id: 5,
                    title: '交通出行服务',
                    data: [
                        "航空公司",
                        "租车公司",
                        "铁路公司",
                        "邮轮公司",
                        "游轮/游艇",
                        "包车及专车服务",
                        "包机运营商/航空服务",
                        "导航服务",
                        "大巴公司",
                        "机场",
                        "机场综合服务",
                        "机票代理人"
                    ]
                },
                {
                    id: 8,
                    title: '商务差旅/会奖旅游',
                    data: [
                        "商务旅行管理公司",
                        "企业商旅卡",
                        "展览中心",
                        "会议设施",
                        "展会主办方",
                        "奖励旅游",
                        "专业会议主办方",
                        "目的地管理公司",
                        "会展/活动策划"
                    ]
                },
                {
                    id: 6,
                    title: '目的地细分领域（当地游玩/餐饮购物等）',
                    data: [
                        "歌舞/综艺表演",
                        "赌场",
                        "主题乐园",
                        "综合性文旅企业",
                        "餐饮服务",
                        "博物馆",
                        "音乐剧/音乐节",
                        "剧院/歌剧院",
                        "自然风景区",
                        "名胜古迹",
                        "一体化度假村",
                        "目的地金融服务",
                        "目的地商场/零售店/免税店",
                        "其他"
                    ]
                },
                {
                    id: 7,
                    title: '数字营销',
                    data: [
                        "程序化创意",
                        "营销自动化",
                        "小程序",
                        "HTML 5",
                        "邮件营销",
                        "网站管理",
                        "SEO",
                        "品牌内容营销/媒体策划"
                    ]
                }

            ]
        }

    }

    async componentDidMount() {
        await this.store.fetch_merchant();

        
    }

    handleTableChange = async (pagination) => {
        try {
            await this.store.fetch_merchant(pagination)

        }
        catch (e) {
            message.error(e.message)
        }
    }

    handleDel = async (id) => {
        var res = await this.store.del_merchant(id);
        console.log("res", res);
        if (res.code == 0) {
            message.success("删除成功");
            await this.store.fetch_merchant();
        } else {
            message.error("删除失败")

        }

    }
    handleSearch = async () => {


        try {
            await this.store.fetch_merchant()
        }
        catch (e) {
            message.error(e.message)
        }
    }

    handleExcel = () => {
        let option = {};

        if (this.store.datas.length == 0) {
            message.error("没有相关数据，导出数据失败"); return;
        }

        option.fileName = "参展商信息" + moment().format('YYYYMMDDHHmmss'); 
        let names = [], vals = [];
        var items = this.store.datas.map((d,ds) => {
            let cate = JSON.parse(d.categoryJson)
            console.log("cate", toJS(cate));

            let dsf = {}, dss = {};

           
         
            for (var i = 0; i < cate.length; i++) {
                console.log("i", i);
                if (cate[i].key != 'sorce') {
                    dss['t' + i] = cate[i].items.map((ct) => {
                        return ct.text || ct.name
                    }).join(',')

                    if (ds == 0) {
                        names.push(cate[i].name);
                        vals.push('t' + i)
                    }
                }
            
            }

            let sorces = cate.filter(c => c.key == 'sorce');
            if (sorces.length > 0) {
                console.log("sorces", sorces);
                for (var i = 0; i < sorces[0].items.length; i++) {
                    dsf['s' + i] = sorces[0].items[i].sorces.map((ct) => {
                        return ct.text + (ct.s + '分')
                    }).join(',')
                    if (ds == 0) {
                        names.push(sorces[0].items[i].title);
                        vals.push('s' + i)
                    }
                }

            }
            console.log("names", names);
            return {
                ...d,
                ...dss,
                ...dsf
            }
        });

       

        console.log("items", toJS(items));
       


        option.datas = [
            {
                sheetData: items,
                sheetName: 'Sheet1',
                sheetFilter: ['name', 'companyName', 'companyEName', 'address', 'loginPhone', 'email', 'city', 'position', 'channel', 'createTime', ...vals],
                sheetHeader: ['姓名','公司名称', '公司英文名称', '公司地址', '手机', '邮箱','所在城市','职位','推荐渠道','添加时间',...names],
            }
        ]
        let toExcel = new ExportJsonExcel(option);  //生成excel文件
        toExcel.saveExcel();  //下载excel文件
    }

    render() {
        const { filter, loading, datas, pagination } = this.store
        const { categorys } = this.state;

        console.log("datas", datas);
        return datas && (
            <>

                <div className='filter-box filter-box-title-110'>

                    <AntDescriptions>

                        <Descriptions.Item label="公司名称" span={1}>
                            <Input defaultValue={filter.companyName} onChange={e => filter.companyName = e.target.value} allowClear />
                        </Descriptions.Item>

                        <Descriptions.Item label="姓名/邮箱/电话" span={1}>
                            <Input defaultValue={filter.phone} onChange={e => filter.phone = e.target.value} allowClear />
                        </Descriptions.Item>

                        <Descriptions.Item label="产品类别" span={1}>
                            <TreeSelect style={{ width: 250 }}
                                showSearch
                                allowClear
                            >
                                {categorys.map((x) => {
                                    return (
                                        <TreeNode
                                            key={x.id}
                                            value={x.id}
                                            title={x.title}
                                            dropdownStyle={{ minWidth: 800, overflow: 'auto' }}
                                        >
                                            {x.data.map((d) => {
                                                return (
                                                    <TreeNode
                                                        key={d}
                                                        value={d}
                                                        title={d}
                                                        onChange={(e) => {
                                                            filter.cate = d;
                                                        }}
                                                        dropdownStyle={{ minWidth: 800, overflow: 'auto' }}
                                                    >

                                                    </TreeNode>
                                                )
                                            })}
                                        </TreeNode>
                                    )
                                })}
                            </TreeSelect>
                        </Descriptions.Item>
                    </AntDescriptions>

                    <Row>
                        <Col>
                            <Button type='primary' onClick={() => {
                                this.handleExcel();
                            }}>导出信息</Button>
                        </Col>
                        <Col flex={1} />
                        <Col>
                            <Button type='primary' onClick={this.handleSearch} icon={<SearchOutlined />} loading={this.loading}>搜索</Button>
                        </Col>
                    </Row>
                </div>
                {this.props.buyId == 2 
                    ? 
                    <Element name="scrollElement">
                        <AntTable
                            dataSource={datas}
                            loading={loading}
                            rowKey={record => record.id}
                            autoHeight="true"
                            pagination={{
                                ...pagination,
                                showSizeChanger: true
                            }}
                            onChange={this.handleTableChange}
                            columns={[
                                {
                                    title: '姓名',
                                    width: 150,
                                    ellipsis: true,
                                    dataIndex: 'name',

                                },

                                {
                                    title: '公司',
                                    width: 100,
                                    ellipsis: true,
                                    dataIndex: 'companyName',
                                },
                      
                                {
                                    title: '职位',
                                    width: 80,
                                    ellipsis: true,
                                    dataIndex: 'legalPerson',
                                },
                                {
                                    title: '手机|邮箱',
                                    width: 80,
                                    ellipsis: true,
                                    render: (text, record) => (
                                        <>{record.buyPhone || record.loginPhone}|{record.email}</>
                                    )
                                },
                                {
                                    title: '添加时间',

                                    width: 150,
                                    render: (text, record) => (
                                        moment(record.createTime).format(constants.DEFAULT_DATETIME_FORMAT)
                                    ),
                                },

                                {
                                    title: '操作',
                                    width: 80,

                                    render: (text, record) => (
                                        <>

                                            <Button type='link' onClick={() => {
                                                this.edit = {
                                                    item: record,
                                                    visible: true

                                                }
                                                //this.props.history.push(`/activity/activity-editor/modify?id=${record.id}`)
                                            }}>详情</Button>
                                            <Divider type="vertical" />
                                            <Popconfirm title="确定要删除吗？" disabled={record.isPublish} onConfirm={() => { this.handleDel(record.id) }}>
                                                <Button type='link' disabled={record.isPublish}>删除</Button>
                                            </Popconfirm>


                                        </>
                                    ),
                                },
                            ]}
                        />
                    </Element>
                    : <Element name="scrollElement">
                        <AntTable
                            dataSource={datas}
                            loading={loading}
                            rowKey={record => record.id}
                            autoHeight="true"
                            pagination={{
                                ...pagination,
                                showSizeChanger: true
                            }}
                            onChange={this.handleTableChange}
                            columns={[
                                {
                                    title: '公司名称',
                                    width: 150,
                                    ellipsis: true,
                                    dataIndex: 'gsmc',

                                },

                                {
                                    title: '公司地址',
                                    width: 100,
                                    ellipsis: true,
                                    dataIndex: 'address',
                                },
                                {
                                    title: '联系人姓名|邮箱|电话',
                                    width: 130,
                                    ellipsis: true,
                                    render: (text, record) => (
                                        <>{record.xm}|{record.email}|{record.buyPhone || record.dh}</>
                                    )
                                },
                                {
                                    title: '职位',
                                    width: 80,
                                    ellipsis: true,
                                    dataIndex: 'zw',
                                },
                                {
                                    title: '部门',
                                    width: 80,
                                    ellipsis: true,
                                    dataIndex: 'bm',
                                },
                                {
                                    title: '添加时间',

                                    width: 150,
                                    render: (text, record) => (
                                        moment(record.createTime).format(constants.DEFAULT_DATETIME_FORMAT)
                                    ),
                                },

                                {
                                    title: '操作',
                                    width: 80,

                                    render: (text, record) => (
                                        <>

                                            <Button type='link' onClick={() => {
                                                this.edit = {
                                                    item: record,
                                                    visible: true

                                                }
                                                //this.props.history.push(`/activity/activity-editor/modify?id=${record.id}`)
                                            }}>详情</Button>
                                            <Divider type="vertical" />
                                            <Popconfirm title="确定要删除吗？" disabled={record.isPublish} onConfirm={() => { this.handleDel(record.id) }}>
                                                <Button type='link' disabled={record.isPublish}>删除</Button>
                                            </Popconfirm>


                                        </>
                                    ),
                                },
                            ]}
                        />
                    </Element> }

        
                {this.edit.item &&
                    <Modal width='50%'
                        title={this.props.store.dataInfo.name}
                        visible={this.edit.visible}
                        onCancel={() => { this.edit.visible = false }}
                        onOk={() => { this.save() }}>


                        <div>


                            <Card title={this.props.store.dataInfo.id == "eca9b4ee-9b3e-412b-a0fd-0b5dc7c21f71" ? "申请资料" : "注册报名"}>
                                <div style={{ marginLeft: "15px" }}>
                                    <p>姓名: {this.edit.item.name || this.edit.item.categoryJson && JSON.parse(this.edit.item.categoryJson).xm}</p>
                                    <p>公司名称: {this.edit.item.companyName || this.edit.item.categoryJson &&JSON.parse(this.edit.item.categoryJson).GSMC}</p>
                                    <p>公司英文名称: {this.edit.item.companyEName}</p>
                                <p>联系方式: {this.edit.item.buyPhone || this.edit.item.loginPhone}</p>
                                    <p>邮箱: {this.edit.item.email || this.edit.item.categoryJson &&JSON.parse(this.edit.item.categoryJson).YX}</p>
                                    <p>法人姓名: {this.edit.item.legalPerson}</p>
                                    <p>推荐渠道: {this.edit.item.channel}</p>
                                    <p>固定电话: {this.edit.item.mobile}</p>
                                    <p>所在城市: {this.edit.item.city}</p>
                                    <p>职位: {this.edit.item.position}</p>
                                </div>
                            </Card>
                            {this.edit.item.categorys && this.edit.item.categorys.length>0 ?
                                <Card title="所在行业" style={{ marginTop: "10px" }}>
                                    <div style={{ marginLeft: "15px" }}>
                                        {this.edit.item.categorys && this.edit.item.categorys.map((t) => {
                                            return (
                                                <div>{t.cate}</div>
                                            )
                                        })}
                                    </div>
                                </Card> :
                                <Card title="企业信息" style={{ marginTop: "10px" }}>
                                    <div style={{ marginLeft: "15px" }}>
                                        {this.edit.item.categoryJson && JSON.parse(this.edit.item.categoryJson).map((t) => {
                                            return (
                                                <>
                                                    <div>{t.name}</div>
                                                    <div>{t.items.map((m) => {
                                                        return (
                                                            <>{m.text}{m.name}</>
                                                        )
                                                    })}</div>
                                                </>
                                            )
                                        })}
                                    </div>
                            </Card>

                            
                        }
                        {
                                this.edit.item.categoryJson && JSON.parse(this.edit.item.categoryJson).sorce &&  JSON.parse(this.edit.item.categoryJson).some(c => c.key == 'sorce') &&

                            <Card title="评分调查" style={{ marginTop: "10px" }}>
                                <div style={{ marginLeft: "15px" }}>
                                    {JSON.parse(this.edit.item.categoryJson).filter(c => c.key == 'sorce').map((t) => {
                                        return (
                                            <>
                                                {t.items.map((tm) => {
                                                    return (
                                                        <>
                                                            <div>{tm.title}</div>
                                                            {tm.sorces.map((ts) => {
                                                                return (
                                                                    <div>
                                                                        {ts.text} {ts.s}分
                                                                    </div>
                                                                )
                                                            })}
                                                        </>
                                                    )
                                                })
                                                }

                                            </>
                                        )
                                    })
                                    }
                                </div>
                            </Card>
                        }
                       
                        </div>
                    </Modal>
                }
            </>
        )

    }
}
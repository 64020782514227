/// <reference path="../video-category.js" />
import React, { Component } from 'react';
import { Form, Button, Input, Row, Col, Menu, Radio, Popconfirm, Select, PageHeader, Image, Modal, Divider, message, TreeSelect, Descriptions } from 'antd';
import { AntTable, AntDescriptions, PicturesWall, VideosWall } from '../../../components'

import { Element } from 'react-scroll'
import { SearchOutlined, SaveOutlined } from '@ant-design/icons';
import { observable, toJS } from 'mobx';
import { observer } from "mobx-react"
import moment from 'moment'
import constants from '../../../utils/constants'
import stores from '../../../stores'
import ActivityStore from '../../../stores/activityStore';
import utils from '../../../utils';
const { Option } = Select;

const { TreeNode } = TreeSelect
export default @observer class ActivitImageListPartial extends Component {
    

    @observable labels = [];
    @observable menus = [];

    @observable loadingEnd = false;

    @observable filter = {
        sort:'all'
    };
    @observable modalData = {
        visible: false,
        imageType: 'add',
        items: null
    };
    constructor(props) {
        super(props)
        this.store = props.store;
        console.log(" this.store", this.store);
        this.state = {
         
        }

    }

    async componentDidMount() {
        await this.store.fetch_img(this.filter);

        this.loadLabel();

    }

    handleSearch = async () => {
        try {
            await this.store.fetch_img()


        }
        catch (e) {
            message.error(e.message)
        }
    }

    handleSave = async () => {
        let { imgInfo, dataInfo } = this.store;
        
        console.log(imgInfo);
        if (imgInfo.imgPath.length == 0) {
            message.error("请选择上传的图片"); return;
        }


      
        imgInfo.activityId = dataInfo.id;
        imgInfo.activityName = dataInfo.name;
        const user = stores.session.user
        imgInfo.accountNo = user.accountNo;
        imgInfo.fullName = user.fullName;
        let res = await this.store.save_img();
        console.log("res", res);
        if (res.code == 0) {
            message.success("操作成功");
            this.modalData.visible = false;
            await this.store.fetch_img();
        } else {
            message.error(res.msg);
        }
    }


 

    getImageFileList = (images) => {

        let array = [];
        if (!images) {
            return array;
        }
        for (let i = 0; i < images.length; i++) {
            let item = images[i];
            if (item.action != 3) {
                array.push({ uid: i, id: (i + 1), status: 'done', url: item.fileName });
            }

        }



        return array;

    }

    loadLabel = async () => {
        let res = await this.store.get_video_lable(2);

        this.labels = [];
        this.menus = [{ label: "所有", value: "all" }];
        for (var i = 0; i < res.length; i++) {
            this.labels.push({ label: res[i].name, value: res[i].name });

            this.menus.push({
                label: res[i].name,
                value: res[i].name
            });
        }
        this.menus.push({ label: "最新上传", value: "time" });

        this.loadingEnd = true;
      


    }

    handleEditClick = async (tp) => {
        this.modalData = {
            visible: true,
            videoType: tp
        };
        this.loadLabel();
       
    }


    render() {
        const { filter, loading, imgList, pagination, imgInfo, dataInfo } = this.store
;
        return (
            <>

                <div className='filter-box filter-box-title-110'>

                 

                    <Row>
                        <Col>
                            <Button type='primary' onClick={() => {
                                this.handleEditClick('add');
                            }}>上传图片</Button>
                        </Col>
                        <Col flex={1} />
                        <Col>
                          
                        </Col>
                    </Row>
                </div>

                <div className='filter-box filter-box-title-110'>
                    {this.loadingEnd &&
                        <Radio.Group
                        options={this.menus}
                        onChange={async (v, o) => {
                                console.log("v", v);
                            this.filter.sort = v.target.value;
                            await this.store.fetch_img(this.filter);
                            }}
                            value={this.filter.sort}
                            optionType="button"
                            buttonStyle="solid"
                        />
                    }
                </div>

                <div className='filter-box filter-box-title-110'>
                    <Row>
                        {imgList.map((m) => {
                            return(
                            <div>
                                {m.imgPath.map((mp) => {
                                    return (
                                        <Col span={4} lg={6} xs={24}>
                                            <Image
                                                width={100}
                                                src={constants.IMAGE_MDD_DOMAIN + mp.fileName}
                                                placeholder={
                                                    <Image
                                                        preview={false}
                                                        src={constants.IMAGE_MDD_DOMAIN + mp.fileName}
                                                        width={100}
                                                    />
                                                }
                                            />
                                        </Col>
                                    )
                                })}
                          

                                </div>
                                )
                        })}
                      
                    </Row>
                 </div>
           

                {this.modalData.visible && (
                    <Modal
                        title={this.modalData.videoType == 'add' ? "上传视频" : "编辑视频"}
                        centered
                        width={800}
                        visible={true}
                        onCancel={() => this.modalData.visible = false}
                        okText='保存'
                        onOk={() => { this.handleSave() }}
                        okButtonProps={{ icon: <SaveOutlined />, htmlType: 'submit' }}
                    >
                        <Form.Provider>

                            <Form.Item label="所属活动">
                                {dataInfo.name}
                            </Form.Item>
                          

                            <Form.Item label="图片标签">
                                <Form.Item>
                                    <Input value={imgInfo.lable} onChange={e => imgInfo.lable = e.target.value} />
                                </Form.Item>
                            </Form.Item>

                            <Form.Item label="已有标签">
                                <Form.Item>
                                    {this.labels.length > 0 &&
                                        <Radio.Group
                                            options={this.labels
                                            }
                                            onChange={(v, o) => {
                                                console.log("v", v);
                                                imgInfo.lable = v.target.value;
                                            }}
                                        value={imgInfo.lable}
                                            optionType="button"
                                            buttonStyle="solid"
                                        />
                                    }
                                </Form.Item>
                            </Form.Item>

                            <Form.Item label="上传图片" >
                                <Form.Item>
                                    <PicturesWall
                                        style={{ width: '60%', height: '100' }}
                                        fileList={this.getImageFileList(imgInfo.imgPath)}
                                        defaultDomain={constants.IMAGE_MDD_DOMAIN}
                                        showPosterIcon={true}
                                        bucketName={constants.IMAGE_MDD_BUCKETNAME}
                                        onChange={(images) => {
                                            imgInfo.imgPath = [];
                                            images.forEach(function (img) {
                                                imgInfo.imgPath.push({
                                                    fileName: img.fileName
                                                });
                                            })

                                        }}
                                    />
                              
                                </Form.Item>
                            </Form.Item>

                            
                        </Form.Provider>
                    </Modal>
                )}
            </>
        )

    }
}
import React, { Component } from 'react';
import { observer } from "mobx-react"
import { observable } from 'mobx';
import BraftEditor from 'braft-editor'
import constants from '../utils/constants'

export default @observer class RichEditor extends Component {
    //@observable coverBraftEditor = {};
    //赋值示例：productDetail.intro = BraftEditor.createEditorState(productDetail.intro);
    //获取内容示例：productDetail.intro.toHTML()
    constructor(props) {
        super(props);
        this.coverBraftEditor = {
            controls: [
                'undo', 'redo', 'separator',
                'font-size', 'line-height', 'letter-spacing', 'separator',
                'text-color', 'bold', 'italic', 'underline', 'strike-through', 'separator',
                'superscript', 'subscript', 'remove-styles', 'emoji', 'separator', 'text-indent', 'text-align', 'separator',
                'headings', 'list-ul', 'list-ol', 'blockquote', 'code', 'separator',
                'link', 'separator', 'hr', 'separator',
                'media',
                'separator',
                'clear'
            ],
            media: {
                externals:
                {
                    video: false, audio: false, embed: false
                },
                uploadFn: (param) => {
                    console.log("param", param);
                    const serverURL = '/Api/Default/UploadImage'
                    const xhr = new XMLHttpRequest
                    const fd = new FormData()
                    const successFn = (response) => {
                        var result = JSON.parse(xhr.responseText);
                        if (result.code == 0) {
                            // 假设服务端直接返回文件上传后的地址
                            // 上传成功后调用param.success并传入上传后的文件地址
                            param.success({
                                url: constants.IMAGE_DEFAULT_DOMAIN + result.path,
                                meta: {
                                    id: '',
                                    title: '',
                                    alt: result.path,
                                    //loop: true, // 指定音视频是否循环播放
                                    //autoPlay: true, // 指定音视频是否自动播放
                                    //controls: true, // 指定音视频是否显示控制栏
                                    //poster: 'http://xxx/xx.png', // 指定视频播放器的封面
                                }
                            })
                        }
                        else {
                            param.error({
                                msg: result.msg
                            })
                        }
                    }
                    const progressFn = (event) => {
                        // 上传进度发生变化时调用param.progress
                        param.progress(event.loaded / event.total * 100)
                    }
                    const errorFn = (response) => {
                        // 上传发生错误时调用param.error
                        param.error({
                            msg: 'unable to upload.'
                        })
                    }
                    xhr.upload.addEventListener("progress", progressFn, false)
                    xhr.addEventListener("load", successFn, false)
                    xhr.addEventListener("error", errorFn, false)
                    xhr.addEventListener("abort", errorFn, false)
                    fd.append('file', param.file)
                    fd.append('bucketName', constants.IMAGE_GROUP_BUCKETNAME)
                    xhr.open('POST', serverURL, true)
                    xhr.send(fd)
                }
            }
        };
    }
    render() {
        return (
            <BraftEditor
                {...this.coverBraftEditor}
                placeholder="请输入"
                contentStyle={{ height: 200, boxShadow: 'inset 0 1px 3px rgba(0,0,0,.1)' }}
                {...this.props}
            />
        )
    }
}
import config from '../config'
import constants from '../utils/constants'
import { createPostfix } from 'typescript'

let requestAuthenticationFn: (headers) => Promise
let sesstionTimeoutFn: () => void

async function post(url, data, allowAnonymous) {

  
    let body = null
    if (data != null) {
        body = JSON.stringify(data)
    }

    let headers = {
        'Content-Type': 'application/json'
    }

   

    if (!allowAnonymous && requestAuthenticationFn) {
        console.debug('requestAuthentication')
        await requestAuthenticationFn(headers)
    }

    let response = null
    try {
        response = await fetch(url, {
            method: 'post',
            body: body,
            headers: headers,
            credentials: 'omit',
            mode: 'same-origin'
        })
    }
    catch (e) {
        console.error(e)
        throw new Error('无法连接到服务器')
    }

    if (response.status == 401) {
        typeof sesstionTimeoutFn == 'function' && sesstionTimeoutFn()
        throw new Error('会话超时，请重新登录')
    }

    if (response.status == 500) {
        throw new Error(await response.text())
    }

    if (response.status == 404) {
        throw new Error('404: ' + url)
    }

    if (!response.ok) {
        throw new Error(response.statusText)
    }

    if (response.status == 204) {
        return null
    }

    // 注意这里不能用 response.json()，因为后台输出的数据有可能为空
    let responseBody = await response.text()
    if (responseBody) {
        return JSON.parse(responseBody)
    }

    return null
}

function postFactory(url, allowAnonymous) {
    return (data) => post(config.apiRoot + url, data || {}, allowAnonymous)
}

const api = {
    events: {
        onRequestAuthentication: (fn: (headers) => Promise) => {
            requestAuthenticationFn = fn
        },
        onSesstionTimeout: (fn: () => void) => {
            sesstionTimeoutFn = fn
        }
    },

    default: {
        upyunSign: postFactory('Default/UpyunSign', true),
    },

    account: {
        getToken: postFactory('Account/GetToken', true),
        refreshToken: postFactory('Account/RefreshToken', true),
        profile: postFactory('Account/Profile'),
        loginMongoDb: postFactory('Account/LoginMongoDb', true),

        getStaffs: postFactory('Account/GetListAccounts', true),
        editStaff: postFactory('Account/EditAccount', true),
        getStaffById: postFactory('Account/StaffById', true),
        delAccount: postFactory('Account/DelAccount', true),

        getCompanys: postFactory('Account/GetCompanys', true),
        companyEdit: postFactory('Account/CompanyEdit', true),
        getDepartments: postFactory('Account/GetDepartments', true),
        departmentEdit: postFactory('Account/DepartmentEdit', true),
        departmentDel: postFactory('Account/DepartmentDel', true),
        companyDel: postFactory('Account/CompanyDel', true)

    },
    news: {
        getNewsById: postFactory('News/NewsById', true),
        newsEdit: postFactory('News/NewsEdit', true),
        getNews: postFactory('News/GetNews', true),
        newsDel: postFactory('News/NewsDel', true),

        newsAdvertiById: postFactory('News/NewsAdvertiById', true),
        getNewsAdverti: postFactory('News/GetNewsAdverti', true),
        newsAdvertiEdit: postFactory('News/NewsAdvertiEdit', true),
        newsAdvertiDel: postFactory('News/NewsAdvertiDel', true),

        getCategorys: postFactory('News/GetCategorys', true),
        categoryDel: postFactory('News/CategoryDel', true),
        categoryEdit: postFactory('News/CategoryEdit', true),

        getBriefings: postFactory('News/GetBriefings', true),
        briefingDel: postFactory('News/BriefingDel', true),
        briefingEdit: postFactory('News/BriefingEdit', true),
        briefingById: postFactory('News/BriefingById', true)
    },
    purview: {
        getRoles: postFactory('Purview/GetRoles', true),
        roleById: postFactory('Purview/RoleById', true),
        roleEdit: postFactory('Purview/RoleEdit', true),

        getResource: postFactory('Purview/GetResource', true),
        resourceEdit: postFactory('Purview/ResourceEdit', true),
        resourceDel: postFactory('Purview/ResourceDel', true)
    },
    survey: {
        saveCard: postFactory('Survey/CardEdit', true),
        getCards: postFactory('Survey/GetCards', true),
        cardDel: postFactory('Survey/CardDel', true),
        geDataSheet: postFactory('Survey/GeDataSheet', true)

    },
    activity: {
        getActivity: postFactory('News/GetActivity', true),
        categoryDel: postFactory('News/ActivityDel', true),
        categoryEdit: postFactory('News/ActivityEdit', true),
        getImagess: postFactory('Activity/GetImagess'),
        imageEdit: postFactory('Activity/ImageEdit'),
        getActivityType: postFactory('Activity/GetActivityType'),
        activityTableEdit: postFactory('Activity/ActivityTableEdit'),
        getTable: postFactory('Activity/GetActivityTable'),
        getCompanyDemand: postFactory('Activity/GetCompanyDemand'),
        getActivityBuyList: postFactory('Activity/GetActivityBuyList'),
        activityBuyDel: postFactory('Activity/ActivityBuyDel')

    },
    video: {
        getVodSignature: postFactory('Activity/GetVodSignature'),
        convertVodFile: postFactory('Activity/ConvertVodFile'),
        getVodFile: postFactory('Activity/GetVodFile'),

        gets: postFactory('Activity/GetVideos'),
        get: postFactory('Activity/VideosById'),
        del: postFactory('Activity/VideosDel'),
        edit: postFactory('Activity/VideosEdit'),

        getsCategory: postFactory('Activity/GetVideosCategory'),
        delCategory: postFactory('Activity/VideosCategoryDel'),
        editCategory: postFactory('Activity/VideosCategoryEdit'),
        getVideoLable: postFactory('Activity/GetVideoLable')
    },

    activityCategory: {
        gets: postFactory('Activity/GetActivityCategory'),
        get: postFactory('Activity/ActivityCategoryById'),
        edit: postFactory('Activity/ActivityCategoryEdit')
      
    },
    register: {
        gets: postFactory('Activity/GetRegisterList'),
        get: postFactory('Activity/RegisterById'),
        del: postFactory('Activity/RegisterDel'),
        edit: postFactory('Activity/RegisterEdit'),
        getsGnk: postFactory('Activity/GetGnkList')
    },
    service: {
        gets: postFactory('Activity/GetServiceList'),
        edit: postFactory('Activity/ServiceEdit'),
        del: postFactory('Activity/ServiceDel'),
        get: postFactory('Activity/ServiceById'),
        getsDemand: postFactory('Activity/GetDemands'),
        getPurchaseById: postFactory('Activity/DemandPurchaseById'),
        getQuote: postFactory('Activity/QuotedPriceGets'),
        needGet: postFactory('Activity/DemandById'),
        getMessageList: postFactory('Activity/GetMessageList'),
        getMyUserActivity: postFactory('Activity/GetMyUserActivity')
    },
    drill: {
        gets: postFactory('Drill/GetList'),
        edit: postFactory('Drill/Edit'),
        del: postFactory('Drill/Del'),
        get: postFactory('Drill/Get'),
    }
}


export default api

import React, { Component, useContext, useState, useEffect, useRef } from 'react';
import { Table, Form, Input } from 'antd';
import { TableProps } from 'antd/lib/table'
import { omit, merge } from 'lodash';
import classnames from 'classnames'
import { animateScroll, Events as ScrollEvents } from 'react-scroll'
import utils from '../utils'
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

interface AntTableProps extends TableProps {
    onUpdateRecord: (record) => void;
    onDragEnd: (sourceIndex: number, destinationIndex: number) => void;
    // 是否可编辑，默认 false
    editable: Boolean;
    // 是否可拖拽，默认 false
    dragable: Boolean;
    // 自动高度，默认 true
    autoHeight: Boolean;
    allowSelected: Boolean;
}

const type = 'DragableBodyRow';

const DragableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
    const ref = React.useRef();
    const [{ isOver, dropClassName }, drop] = useDrop({
        accept: type,
        collect: monitor => {
            const { index: dragIndex } = monitor.getItem() || {};
            if (dragIndex === index) {
                return {};
            }
            return {
                isOver: monitor.isOver(),
                dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
            };
        },
        drop: item => {
            moveRow(item.index, index);
        },
    });
    const [, drag] = useDrag({
        item: { type, index },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });
    drop(drag(ref));

    return (
        <tr
            ref={ref}
            className={`${className}${isOver ? dropClassName : ''}`}
            style={{ cursor: 'move', ...style }}
            {...restProps}
        />
    );
};

const EditableContext = React.createContext();

const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();

    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const EditableCell = ({
    title,
    editable,
    required,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef();
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };

    const save = async e => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            typeof handleSave == 'function' && handleSave({ ...record, ...values });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                }}
                name={dataIndex}
                rules={[
                    {
                        required: required,
                        message: `请输入${title}`,
                        whitespace: true,
                    },
                ]}
            >
                <Input ref={inputRef} onPressEnter={save} onBlur={save} />
            </Form.Item>
        ) : (
                <div
                    className="editable-cell-value-wrap"
                    style={{
                        paddingRight: 24,
                        height: 32,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}
                    onClick={toggleEdit}
                >
                    {children}
                </div>
            );
    }

    return <td {...restProps}>{childNode}</td>;
};

export default class AntTable extends Component<AntTableProps> {
    static defaultProps = {
        autoHeight: true,
        editable: false
    }

    constructor(props) {
        super(props)

        this.refId = 'table-' + utils.generateKey()
        this.scrollToRow = this.scrollToRow.bind(this)
    }

    scrollToRow(key, flashRow?: Boolean) {
        let element = document.querySelector(`#${this.refId} tr[data-row-key='${key}']`)
        if (flashRow) {
            this.currentScrollRow = element
        }

        if (element) {
            let container = document.querySelector(`#${this.refId} .ant-table-body`)
            if (container) {
                animateScroll.scrollTo(element.offsetTop - container.clientHeight + element.clientHeight, {
                    duration: 400,
                    delay: 50,
                    smooth: 'easeInOutQuint',
                    container,
                })
            }
        }
    }

    UNSAFE_componentWillMount() {
        ScrollEvents.scrollEvent.register('end', (to, element) => {
            if (this.currentScrollRow) {
                // animation
                // ...
                this.currentScrollRow = null
            }
        })
    }

    componentWillUnmount() {
        ScrollEvents.scrollEvent.remove('end')
    }

    render() {
        const { pagination, columns, onUpdateRecord, components, editable, dragable, autoHeight, className, onRow, onDragEnd } = this.props
        const props = omit(this.props, ['pagination', 'columns', 'onUpdateRecord', 'components', 'editable', 'dragable', 'autoHeight', 'className', 'onRow', 'onDragEnd'])
      
        let newComponents = components
        let newOnRow = onRow

        if (editable) {
            newComponents = merge({}, {
                body: {
                    row: EditableRow,
                    cell: EditableCell,
                },
            }, newComponents)
        }
        else if (dragable) {
            newComponents = merge({}, {
                body: {
                    row: DragableBodyRow,
                },
            }, newComponents)

            newOnRow = (record, index) => ({
                index,
                moveRow: (dragIndex, hoverIndex) => typeof onDragEnd == 'function' && onDragEnd(dragIndex, hoverIndex),
            })
        }

        const newColumns = editable ? columns.map(col => {
            if (!col.editable) {
                return col;
            }

            return {
                ...col,
                onCell: record => ({
                    record,
                    editable: col.editable,
                    required: col.required,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: onUpdateRecord,
                }),
            };
        }) : columns;

        const table = (
            <Table
                id={this.refId}
                tableLayout='fixed'
                scroll={{
                    x: autoHeight == true ? 'max-content' : null,
                    y: autoHeight == true ? null : 'auto',
                }}
                className={classnames(className, { 'ant-table-autofit': autoHeight == false })}
                pagination={{
                    showTotal: total => `共 ${total} 条记录`,
                    showSizeChanger: true,
                    ...pagination
                }}
                components={newComponents}
                onRow={newOnRow}
                columns={newColumns}
                rowClassName={() => classnames({ 'editable-row': editable })}
                {...props}
            />
        )

        if (!dragable) {
            return table
        }

        return (
            <DndProvider backend={HTML5Backend}>
                {table}
            </DndProvider>
        )
    }
}
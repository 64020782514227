import React, { Component } from 'react';
import { Form, Button, Input, Row, Col, Checkbox, InputNumber , PageHeader, Descriptions, Select, Image, Menu, Card } from 'antd';
import { AntTable, AntDescriptions } from '../../components'
import { observable, toJS } from 'mobx';
import { observer } from "mobx-react"
import staticData from '../../utils/staticData.json'
import moment from 'moment'
import constants from '../../utils/constants'
import ServicePurchaseListPartial from './layouts/service-purchase-list.partial'
import ServiceQuoteListPartial from './layouts/service-quote-list.partial'
import serviceStore from '../../stores/serviceStore';
import qs from 'qs'
const { Option } = Select;

export default @observer class DetailCompany extends Component {

    store = new serviceStore()

    @observable menusKey = 0;
    @observable query=null
    constructor(props) {
        super(props)
        console.log("1111", props);
        this.query = qs.parse(this.props.location.search.substr(1))

    
        this.state = {
            menus: this.query.buyId == 2 ? ["服务详情", "采购报价"] : ["企业详情", "企业采购"]
        }

        
    }
    async componentDidMount() {

       
        await this.store.get(this.query.id);
       
        if (this.query.key) {
            this.menusKey = this.query.key
        }
    }

  


    render() {
        const { dataInfo } = this.store
        return (
            <>
                <PageHeader title={dataInfo.companyName} style={{ paddingBottom: 0 }} onBack={() => {
                    if (this.query.buyId == 2) {
                        this.props.history.push(`index`)
                    } else {
                        this.props.history.push(`company`)
                    }
                }}/>

                <div className='filter-box filter-box-title-110'>

                    <Menu onClick={this.handleClick} selectedKeys={[this.state.menus[this.menusKey]]} mode="horizontal">
                        {this.state.menus.map((m,i) => {
                            return (
                                <Menu.Item key={m} onClick={() => {
                                    this.menusKey = i;
                                }}>
                                    {m}
                                </Menu.Item>
                            )
                        })}


                    </Menu>
                </div>
                <div className='product-single' >
                    {this.menusKey == 0 && this.renderBasic()}
                    {this.menusKey == 1 && <ServicePurchaseListPartial props={this.props} query={this.query} />}
                    {this.menusKey == 2 && <ServiceQuoteListPartial props={this.props} query={this.query} />}
                  
                </div>
            </>
        )
    }

    renderBasic() {
        const { dataInfo}=this.store
        return (
            <>
                <Card title="基础资料">
                    <div style={{ marginLeft: "15px" }}>
                        <p>公司名称: {dataInfo.companyName}</p>
                        <p>公司英文名称: {dataInfo.companyEnName}</p>
                        <p>联系人姓名: {dataInfo.name}</p>
                        <p>联系人手机（用于登录）: {dataInfo.phone}</p>
                        <p>联系人邮箱: {dataInfo.email}</p>
                        <p>联系人职能: {dataInfo.position}</p>
                        <p>提供服务: {dataInfo.service}</p>
                        <p>公司简介: {dataInfo.intro}</p>
                        {dataInfo.logoImgs && dataInfo.logoImgs.length > 0 &&
                            <p>公司logo:<Image style={{ width: 100, height:100 }} src={"https://img10.yiqifei.com" + dataInfo.logoImgs[0]} /></p>
                        }
                    </div>
                </Card>
                {dataInfo.categorys && dataInfo.categorys.length > 0 &&
                    <Card title={this.query.buyId == 2 ?"产品类别":"行业类别"} style={{ marginTop: "10px" }}>
                        {dataInfo.categorys.map((c) => {
                            return (
                                <>
                                    {this.query.buyId == 3 ?
                                        <p>{staticData.company.some(d => d.id == c.id) && staticData.company.find(d => d.id == c.id).title}: {c.cate}</p>
                                        :
                                        <p>{staticData.service.some(d => d.id == c.id) && staticData.service.find(d => d.id == c.id).title}: {c.cate}</p>
                                    }
                                </>

                            )
                        })}

                    </Card>
                }
                </>
            )
    }
}
import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from "mobx-react"
import { Select } from 'antd';
import StaffCompanyStore from '../../stores/staffCompanyStore';
const { Option } = Select;
export default @observer class CompanyCmpt extends Component {
    store = new StaffCompanyStore()

    @observable filter = null;
    static defaultProps = {
        companyCode:null
    }

    constructor(props) {
        super(props)

    }

    async UNSAFE_componentWillReceiveProps(props) {
     
    }

    async componentDidMount() {
        await this.store.fetch();
    }

    render() {
        const { dataList } = this.store
        return (
            <>
                <Select
                    style={{ width: '100%' }}
                    value={this.filter}
                    onChange={(value, options) => {
                        this.filter = value;
                        this.props.onChange(options)
                    }}
                >
                    <Option value={null}>请选择...</Option>
                    {dataList.map((item) => {
                        return (
                            <Option value={item.companyCode}>{item.companyName}</Option>
                            )
                    })}
                </Select> 

            </>
        )
    }

}
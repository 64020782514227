import React, { Component } from 'react';
import { Form, Button, Input, Row, Col, Checkbox, Descriptions, PageHeader, Popconfirm, Divider, message, Spin, Select, Modal } from 'antd';
import { AntTable, AntDescriptions } from '../../components'
import { observable } from 'mobx';
import { observer } from "mobx-react"
import { Element } from 'react-scroll'
import { SaveOutlined } from '@ant-design/icons';
import utils from '../../utils';
import { toJS } from 'mobx';
import qs from 'qs'
import stores, { staff } from '../../stores'
import moment from 'moment'
import constants from '../../utils/constants'
import PurviewPermissionStore from '../../stores/purviewPermissionStore';
const { Option } = Select;
export default @observer class PurviewPermission extends Component {

    store: PurviewPermissionStore

    constructor(props) {
        super(props)

        this.query = qs.parse(props.location.search.substr(1))
        this.store = new PurviewPermissionStore(this.query.role_id)
    }

    async componentDidMount() {
        await this.store.fetch();
        console.log("", toJS(this.store.dataList));
        
    }


    handleSave = async () => {

        var res = await this.store.save();
        if (res.code == 0) {
            message.success("操作成功");
            this.props.history.push('../role');
        } else {
            message.error(res.msg);
        }
    }

    handleMenuChecked = (record, checked) => {
   

        if (record.level == 0 || record.level ==null) {
            this.store.dataList.find(c => c.id == record.id).checked = checked;
            if (this.store.dataList.find(c => c.id == record.id).children != null) {
                this.store.dataList.find(c => c.id == record.id).children.forEach(function (chi) {
                    chi.checked = checked;
                })
            }
        } else {
            var data = this.store.dataList.filter(c => c.children.some(d => d.id == record.id));
           
            data[0].children.find(c => c.id == record.id).checked = checked;
        }
    
    }

    handleAllChecked = (checked) => {
        this.store.dataList.forEach(function (it) {
            it.checked = checked;
            it.children.forEach(function (t) {
                t.checked = checked;
            })
        })
    }
   

    render() {
        const { loading, dataList, roleInfo,saving } = this.store
        return (
            <>
                <PageHeader
                    title="权限设置"
                    onBack={() => this.props.history.push('../../purview/role')}
                />
         
                <div className='filter-box filter-box-title-110'>
                    {roleInfo != null &&
                        <Spin size='large' spinning={loading}>
                            <div style={{ padding: '0 24px 24px' }}>
                                <AntDescriptions className='role-info' title='角色信息' style={{ paddingBottom: 24 }}>
                                    <Descriptions.Item label='角色名称'>
                                        {roleInfo.roleName}
                                    </Descriptions.Item>
                                    <Descriptions.Item label='所属公司'>
                                        {roleInfo.companyName}
                                    </Descriptions.Item>
                                </AntDescriptions>


                            </div>
                        </Spin>
                    }


                </div>


                <div className='filter-box filter-box-title-110'>
                    <div>
                    <span>菜单权限</span>
                    <Divider type='vertical' />
                    <Checkbox
                        style={{ fontWeight: 'normal' }}
                            onChange={(e) => this.handleAllChecked(e.target.checked)}
                        >全选</Checkbox>
                    </div>

                    {dataList.length > 0 &&
                        <AntTable
                            style={{ marginTop: '20px' }}
                            bordered
                            dataSource={toJS(dataList)}
                            size='small'
                            expandable={{defaultExpandAllRows: true }}
                            showHeader={false}

                            rowKey='id'
                            pagination={{
                                defaultPageSize: 9999,
                                hideOnSinglePage: true
                            }}
                            columns={[
                                {
                                    title: '名称',
                                    render: record => (
                                        <Checkbox
                                            checked={record.checked}
                                            onChange={e => this.handleMenuChecked(record, e.target.checked)}
                                        >
                                            {record.name}
                                        </Checkbox>
                                    )
                                }
                            ]}
                        />
                    }

                </div>          

                <div style={{ paddingTop: 24 }}>
                    <Button loading={saving} icon={<SaveOutlined />} type='primary' onClick={() => this.handleSave()}>保存</Button>
                </div>

     
            </>
        )
    }
}
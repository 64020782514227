import React, { Component } from 'react';
import { Form, Button, Input, Row, Col, Menu, Radio, Popconfirm, Select, PageHeader, Image, Modal, Divider, message, TreeSelect, Descriptions } from 'antd';
import { AntTable, AntDescriptions, PicturesWall, VideosWall } from '../../../components'

import { Element } from 'react-scroll'
import { SearchOutlined, SaveOutlined } from '@ant-design/icons';
import { observable, toJS } from 'mobx';
import { observer } from "mobx-react"
import moment from 'moment'
import constants from '../../../utils/constants'
import stores from '../../../stores'
import ActivityStore from '../../../stores/activityStore';
import utils from '../../../utils';
const { Option } = Select;

const { TreeNode } = TreeSelect
export default @observer class ActivityVideoListPartial extends Component {
    store = new ActivityStore();

    @observable labels = [];
    @observable modalData = {
        visible: false,
        videoType: 'add',
        items: null
    };
    constructor(props) {
        super(props)
        this.store = props.store;
        console.log(" this.store", this.store);

    }

    async componentDidMount() {
        await this.store.fetch_video();

        let res = await this.store.get_video_lable(1);
        console.log("resres", res);
        for (var i = 0; i < res.length; i++) {
            this.labels.push({ label: res[i].name, value: res[i].name});
        }
      
    }

    handleEditClick = async (tp) => {
        this.modalData = {
            visible: true,
            videoType: tp
        };

        let res = await this.store.get_video_lable(1);
        this.labels = [];
        for (var i = 0; i < res.length; i++) {
            this.labels.push({ label: res[i].name, value: res[i].name });
        }
    }


    handleSearch = async () => {
        try {
            await this.store.fetch_video()

            
        }
        catch (e) {
            message.error(e.message)
        }
    }

    handleDel = async (id) => {
        var res = await this.store.del_video(id);
        console.log("res", res);
        if (res.code == 0) {
            message.success("删除成功");
            await this.store.fetch_video();
        } else {
            message.error("删除失败")

        }
    }

    handleSave = async () => {
        let { videoInfo, dataInfo } = this.store;
        //this.store.videoInfo.path = JSON.stringify({
        //    action: 1,
        //    fileId: "387702295824479057",
        //    imageUrl: "",
        //    status: "done",
        //    uid: "f0",
        //    videoUrl: "http://1251989072.vod2.myqcloud.com/a006b80bvodgzp1251989072/14acd31e387702295824479057/N9DOaQPzsfoA.mp4"
        //})
        if (utils.isNullOrEmpty(videoInfo.title)) {
            message.error("请输入视频标题"); return;
        }

        if (utils.isNullOrEmpty(videoInfo.path)) {
            message.error("请输入选择视频上传"); return;
        }


     //   videoInfo.imgPath = "/20220210/2c5d5d2c345844acbb2f40ccec798464.jpg"
        videoInfo.activityId = dataInfo.id;
        videoInfo.activityName = dataInfo.name;
        const user = stores.session.user
        videoInfo.accountNo = user.accountNo;
        videoInfo.fullName = user.fullName;
        let res = await this.store.save_video();
        console.log("res", res);
        if (res.code == 0) {
            message.success("操作成功");
            this.modalData.visible = false;
            await this.store.fetch_video();
        } else {
            message.error(res.msg);
        }
    }


    getProductVideos = () => {
        let fileList = [];
        const { videoInfo } = this.store;

        if (videoInfo.path && videoInfo.path.length > 0) {
            fileList.push(
                JSON.parse(videoInfo.path)
            )
        }
        console.log("fileList", fileList);
        return fileList;
    }

    setProductVideos = (fileList) => {
        console.log("fileList", fileList);
        fileList = [{
            action: 1,
            fileId: "387702295824479057",
            imageUrl: "",
            status: "done",
            uid: "f0",
            videoUrl: "http://1251989072.vod2.myqcloud.com/a006b80bvodgzp1251989072/14acd31e387702295824479057/N9DOaQPzsfoA.mp4"
        }];
        this.store.videoInfo.path = JSON.stringify(fileList[0])
    };


    render() {
        const { filter, loading, videoList, pagination, videoInfo,dataInfo } = this.store
       
        return (
            <>

                <div className='filter-box filter-box-title-110'>

                    <AntDescriptions>

                        <Descriptions.Item label="活动标题" span={1}>
                            <Input defaultValue={filter.name} onChange={e => filter.name = e.target.value} allowClear />
                        </Descriptions.Item>
                        <Descriptions.Item  span={1}>
                         
                        </Descriptions.Item>
                        <Descriptions.Item span={1}>
                          
                        </Descriptions.Item>
                    </AntDescriptions>

                    <Row>
                        <Col>
                            <Button type='primary' onClick={() => {

                                this.handleEditClick('add');
                           
                            }}>上传视频</Button>
                        </Col>
                        <Col flex={1} />
                        <Col>
                            <Button type='primary' onClick={this.handleSearch} icon={<SearchOutlined />} loading={this.loading}>搜索</Button>
                        </Col>
                    </Row>
                </div>


                <Element name="scrollElement">
                    <AntTable
                        dataSource={videoList}
                        loading={loading}
                        rowKey={record => record.id}
                        pagination={{
                            ...pagination,
                            showSizeChanger: true
                        }}
                        onChange={this.handleTableChange}
                        columns={[
                            {
                                title: '',
                                width: 100,
                                align: 'center',
                                dataIndex: 'companyName',
                                render: (text, record) => (
                                    <Image src={constants.IMAGE_MDD_DOMAIN + record.imgPath}/>
                                    
                                    )

                            },

                            {
                                title: '标题',
                                width: 100,
                                align: 'center',
                                dataIndex: 'title',
                            },
                            {
                                title: '标签',
                                width: 100,
                                align: 'center',
                                dataIndex: 'lable'
                              
                            },
                            {
                                title: '创建人',
                                width: 100,
                                align: 'center',
                                dataIndex: 'fullName',
                            },
                         
                            {
                                title: '上传时间',
                                align: 'center',
                                width: 100,
                                render: (text, record) => (
                                    moment(record.createTime).format(constants.DEFAULT_DATETIME_FORMAT)
                                ),
                            },

                            {
                                title: '操作',
                                width: 110,
                                align: 'center',
                                render: (text, record) => (
                                    <>

                                        <Button type='link' onClick={() => {

                                            this.handleEditClick('edit');
                                           
                                            this.store.videoInfo = record
                                        }}>编辑</Button>
                                        <Divider type="vertical" />
                                        <Popconfirm title="确定要删除吗？"onConfirm={() => { this.handleDel(record.id) }}>
                                            <Button type='link'>删除</Button>
                                        </Popconfirm>

                                    </>
                                ),
                            },
                        ]}
                    />
                </Element>

                {this.modalData.visible && (
                    <Modal
                        title={this.modalData.videoType == 'add' ? "上传视频" : "编辑视频"}
                        centered
                        width={800}
                        visible={true}
                        onCancel={() => this.modalData.visible = false}
                        okText='保存'
                        onOk={() => { this.handleSave() }}
                        okButtonProps={{ icon: <SaveOutlined />, htmlType: 'submit' }}
                    >
                        <Form.Provider>

                            <Form.Item label="所属活动">
                                {dataInfo.name}
                            </Form.Item>
                            <Form.Item label="视频标题">
                                <Form.Item>
                                    <Input value={videoInfo.title} onChange={e => videoInfo.title = e.target.value} />
                                </Form.Item>
                            </Form.Item>

                            <Form.Item label="视频标签">
                                <Form.Item>
                                    <Input value={videoInfo.lable} onChange={e => videoInfo.lable = e.target.value}/>
                                </Form.Item>
                            </Form.Item>

                            <Form.Item label="已有标签">
                                <Form.Item>
                                    {this.labels.length > 0 &&
                                        <Radio.Group
                                        options={this.labels
                                            }
                                            onChange={(v, o) => {
                                                console.log("v", v);
                                                videoInfo.lable = v.target.value;
                                            }}
                                            value={videoInfo.lable}
                                            optionType="button"
                                            buttonStyle="solid"
                                        />
                                    }
                                </Form.Item>
                            </Form.Item>

                            <Form.Item label="上传封面" >
                                <Form.Item>
                                <PicturesWall
                                    style={{ width: '60%', height: '100' }}
                                    qty={1}
                                        fileList={videoInfo.imgPath && videoInfo.imgPath.length > 0 && [{ uid: 0, id: 1, status: 'done', url: videoInfo.imgPath }]}
                                    defaultDomain={constants.IMAGE_MDD_DOMAIN}
                                    showPosterIcon={true}
                                    bucketName={constants.IMAGE_MDD_BUCKETNAME}
                                    onChange={(images) => {
                                        console.log("images", images);
                                        images.forEach(function (img) {
                                            videoInfo.imgPath = img.fileName

                                        })

                                    }}
                                    />
                                </Form.Item>
                            </Form.Item>

                            <Form.Item label="上传视频" >
                                <Form.Item>
                                <VideosWall
                                    qty={1}
                                    fileList={this.getProductVideos()}
                                    onChange={(fileList) => { this.setProductVideos(fileList); }}
                                    />
                                </Form.Item>
                            </Form.Item>
                        </Form.Provider>
                    </Modal>
                )}
            </>
        )

    }
}
import { observable, action } from "mobx"
import api from "../utils/api"
import { remove } from "lodash"

export default class PurviewResourceStore {

    @observable loading = false
    @observable menuInfo = {}
    @observable menuList = []

    @action.bound
    async fetch() {
        try {
            this.loading = true;
            var res = await api.purview.getResource();
          
            this.menuList = res.items
        } catch{

        } finally {
            this.loading = false;
        }
        
    }

    @action.bound
    async save() {
        this.loading = true;
        var res = await api.purview.resourceEdit(this.menuInfo);
        console.log("res", this.menuInfo);
        return res;

    }

    @action.bound
    async gets() {
       
      
    }

    @action.bound
    async del() {
        var res = await api.purview.resourceDel(this.menuInfo);
        return res;
    }
}
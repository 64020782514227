import React, { Component } from 'react';
import { Form, Button, Input, Row, Col, Checkbox, Spin, PageHeader, Collapse, Select, Divider, Menu, Badge } from 'antd';
import { AntTable, AntDescriptions } from '../../components'
import { observable } from 'mobx';
import { observer } from "mobx-react"
import { Element } from 'react-scroll'
import { CaretRightOutlined  } from '@ant-design/icons';
import moment from 'moment'
import constants from '../../utils/constants'
import serviceStore from '../../stores/serviceStore';
import qs from 'qs'
import staticData from '../../utils/staticData.json'
const { Option } = Select;
const { Panel } = Collapse;
export default @observer class Quote extends Component {

    store = new serviceStore()
    @observable query = null;
    @observable datas = null
    constructor(props) {
        super(props)

        this.query = qs.parse(this.props.location.search.substr(1))
        console.log(" this.query ", this.query );
    }
    async componentDidMount() {
      
        let res = await this.store.fetch_quote({
            itemID: this.query.id,
            pageCount: 1,
            pageSize: 20
        });

        this.datas = res.items

        
    }




   
    render() {
        const { filter, pagination, dataList, loading } = this.store
        return (
            <>
                <PageHeader
                    title="报价详情"
                    onBack={() => {

                        if (this.query.back == 'purchase') {
                            this.props.history.push(`purchase?id=${this.query.id}&companyID=${this.query.companyID}&service=${this.query.service}&buyId=${this.query.buyId}`)
                        } else {
                            this.props.history.push(`detail?id=${this.query.companyID}&service=${this.query.service}&buyId=${this.query.buyId}`)
                        }
                    }
                    }
                />
                <Spin spinning={loading} size='large'>
                <div className='product-single' >
                    <Menu mode="horizontal">
                        <Menu.Item onClick={() => {

                        }}>
                            报价详情
                    </Menu.Item>


                    </Menu>


                    <Collapse
                        bordered={false}
                    //    defaultActiveKey={['1']}
                        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                        className="site-collapse-custom-collapse"
                    >
                        {this.datas && this.datas.map((d,i) => {
                            return (
                                <Panel header={`${moment(d.createTime).format("YYYY-MM-DD HH:mm")} 报价`} key={i} className="site-collapse-custom-panel">
                                    <div style={{ color: 'grey', fontSize:10 }}>
                                        报价人公司
                                    </div>
                                    <p>
                                        {d.companyName}
                                    </p>
                                    <div style={{ color: 'grey', fontSize: 10 }}>
                                        报价人
                                    </div>
                                    <p>
                                        {d.name}
                                    </p>
                                    <div style={{ color: 'grey', fontSize: 10 }}>
                                        报价人联系方式
                                    </div>
                                    <p>
                                        {d.phone}
                                    </p>
                                    <div style={{ color: 'grey', fontSize: 10 }}>
                                        报价
                                    </div>
                                    <p style={{ color:'red' }}>
                                        {d.price}
                                    </p>
                                    <div style={{ color: 'grey', fontSize: 10 }}>
                                        方案亮点
                                    </div>
                                    <p>
                                        {d.advantage}
                                    </p>
                                    <div style={{ color: 'grey', fontSize: 10 }}>
                                        方案说明
                                    </div>
                                    <p>
                                        {d.intro}
                                    </p>

                                </Panel>
                                )
                        })}

                        </Collapse>

                        <div style={{ color: 'red', marginTop:15 }}>
                            注：所有报价以最新报价结果为主
                </div>
                    </div>
                </Spin>

              
            </>
        )
    }
}
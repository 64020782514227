import React, { Component } from 'react';
import { Tooltip, Button, Input, Row, Col, Divider, PageHeader, Descriptions, Select, Popconfirm, message, Modal, Form } from 'antd';
import { AntTable, AntDescriptions } from '../../components'
import { observable } from 'mobx';
import { observer } from "mobx-react"
import { Element } from 'react-scroll'
import { SearchOutlined, SaveOutlined } from '@ant-design/icons';
import moment from 'moment'
import utils from '../../utils';
import constants from '../../utils/constants'
import serviceStore from '../../stores/serviceStore';
import ExportJsonExcel from "js-export-excel"
import staticData from '../../utils/staticData.json'
const { Option } = Select;
export default @observer class ServiceCompany extends Component {

    store = new serviceStore()
    @observable account = {
        visible: false,
        item: {}
    }
    async componentDidMount() {
        console.log("staticData", staticData);

        this.store.filter.buyId = 3;
        await this.store.fetch();
    }

    handleTableChange = async (pagination) => {
        try {
            await this.store.fetch(pagination)

        }
        catch (e) {
            message.error(e.message)
        }
    }

    handleSearch = async () => {
        const pagination = {
            ...this.store.pagination,
            current: 1,
        }

        try {
            await this.store.fetch(pagination)
        }
        catch (e) {
            message.error(e.message)
        }
    }


    handleDel = async (id) => {
        var res = await this.store.del(id);
        console.log("res", res);
        if (res.code == 0) {
            message.success("删除成功");
            await this.store.fetch();
        } else {
            message.error("删除失败")

        }

    }
    handleIsPublish = async (data) => {
        this.store.dataInfo = data;
        this.store.dataInfo.isPublish = !data.isPublish;
        await this.store.save();
        await this.store.fetch();
    }



    handleExcel = () => {
        let option = {};

        if (this.store.dataList.length == 0) {
            message.error("没有相关数据，导出数据失败"); return;
        }

        option.fileName = "服务商信息" + moment().format('YYYYMMDDHHmmss');
        var items = this.store.dataList.map((d) => {
            return {
                ...d,
                cate: d.categorys.map((t, i) => t.cate + (d.categorys.length > i + 1 ? '' : ''))
            }
        })
        option.datas = [
            {
                sheetData: items,
                sheetName: 'Sheet1',
                sheetFilter: ['companyName', 'companyEName', 'name', 'phone', 'email', 'service', 'cate', , 'createTime'],
                sheetHeader: ['公司名称', '公司英文名称', '姓名', '手机', '邮箱', '提供服务', '产品类别', '添加时间'],
            }
        ]
        let toExcel = new ExportJsonExcel(option);  //生成excel文件
        toExcel.saveExcel();  //下载excel文件
    }
    saveAccount = async () => {
        const { account, passWord, passWord1 } = this.account.item;
        if (utils.isNullOrEmpty(account)) {
            message.error("请输入账户"); return;
        }

        if (utils.isNullOrEmpty(passWord)) {
            message.error("请输入密码"); return;
        }

        if (passWord != passWord1) {
            message.error("两次密码不相等"); return;
        }

        var res = await this.store.save_service(this.account.item);
        console.log("res", res);

        if (res.code == 0) {
            this.account.visible = false

            this.handleSearch();
        } else {
            message.error(res.msg); return;
        }
    }

    render() {
        const { filter, pagination, dataList, loading } = this.store
        const { props } = this.props;
        return (
            <>
                <PageHeader title='企业管理' style={{ paddingBottom: 0 }} />

                <div className='filter-box filter-box-title-110'>
                    <AntDescriptions>

                        <Descriptions.Item label="公司名称">
                            <Input defaultValue={filter.companyName} onChange={e => filter.companyName = e.target.value} allowClear />
                        </Descriptions.Item>
                        <Descriptions.Item label="姓名|电话|邮箱" >
                            <Input defaultValue={filter.title} onChange={e => filter.title = e.target.value} allowClear />
                        </Descriptions.Item>
                        <Descriptions.Item label="采购需求" >
                            <Select
                                style={{ width: '100%' }}
                                value={filter.serviceType}
                                onChange={(value, options) => {
                                    filter.serviceType = value;
                                }}
                            >
                                <Option value={null}>请选择...</Option>
                                {staticData.provideServices.map((d) => {
                                    return (
                                        <Option value={d}>{d}</Option>
                                    )
                                })}

                            </Select>
                        </Descriptions.Item>
                        <Descriptions.Item label="行业类别" >
                            <Select
                                style={{ width: '100%' }}

                                onChange={(value, options) => {
                                    console.log("value", value);
                                    if (value != null) {
                                        filter.cate = staticData.company.filter(c => c.title == value).map((a) => {
                                            return {
                                                ID: a.id,
                                                Cate: a.title
                                            }
                                        })[0];
                                    } else {
                                        filter.cate = null;
                                    }
                                }}
                            >
                                <Option value={null}>请选择...</Option>
                                {staticData.company.map((d) => {
                                    return (
                                        <Option value={d.title}>{d.title}</Option>
                                    )
                                })}
                            </Select>
                        </Descriptions.Item>
                    </AntDescriptions>



                    <Row>
                        <Col>
                            <Button type='primary' onClick={() => this.handleExcel()}>导出当前企业信息</Button><Divider type="vertical" />
                        </Col>
                        <Button type='primary' onClick={() => {
                            this.props.history.push(`../company/edit?buyId=${filter.buyId}`)
                        }}>新增企业</Button> 
                        <Col flex={1} />
                        <Col>
                            <Button type='primary' onClick={this.handleSearch} icon={<SearchOutlined />} loading={this.loading}>查询</Button>
                        </Col>
                    </Row>
                </div>
                <Element name="scrollElement">
                    <AntTable
                        dataSource={dataList}
                        loading={loading}
                       
                        rowKey={record => record.id}
                        pagination={{
                            ...pagination,
                            showSizeChanger: true
                        }}
                        onChange={this.handleTableChange}
                        columns={[


                            {
                                title: '公司名称',
                                width: 100,
                                ellipsis: true,
                                dataIndex: 'companyName',
                                render: (text, record) => (
                                    <Tooltip placement="topLeft" title={record.companyName}>
                                        {record.companyName}
                                    </Tooltip>
                                ),

                            },
                            {
                                title: '公司英文名称',
                                width: 100,
                                ellipsis: true,
                                dataIndex: 'companyEnName',
                                render: (text, record) => (
                                    <Tooltip placement="topLeft" title={record.companyEnName}>
                                        {record.companyEnName}
                                    </Tooltip>
                                ),
                            },
                            {
                                title: '姓名|电话|邮箱',
                                width: 120,
                                ellipsis: true,
                                render: (text, record) => (
                                    <Tooltip placement="topLeft" title={`${record.name}|${record.phone}|${record.email && record.email}`}>
                                        {record.name}<>|</>{record.phone}<>|</>{record.email}
                                    </Tooltip>
                                ),
                            },
                            {
                                title: '采购需求',
                                width: 80,
                                ellipsis: true,
                                dataIndex: 'service',
                                render: (text, record) => (
                                    <Tooltip placement="topLeft" title={record.service}>
                                        {record.service}
                                    </Tooltip>
                                ),
                            },
                            {
                                title: '行业分类',
                                width: 120,
                                ellipsis: true,
                                render: (text, record) => (

                                    <Tooltip placement="topLeft" title={record.categorys && record.categorys.map((t, i) => t.cate + (record.categorys.length > i + 1 ? ',' : ''))}>
                                        {record.categorys && record.categorys.map((t, i) => t.cate + (record.categorys.length > i + 1 ? ',' : ''))}
                                    </Tooltip>

                                )
                            },
                            {
                                title: '操作',
                                width: 120,
                                align: 'center',

                                render: (text, record) => (
                                    <>
                                        <Button type='link' onClick={() => this.props.history.push(`/service/detailCompany?id=${record.id}&service=${record.service}&buyId=3`)

                                        }>详情</Button>
                                        <Divider type="vertical" />
                                        <Button type='link' onClick={() => this.props.history.push(`/company/edit?id=${record.id}&buyId=${record.buyId}`)

                                        }>编辑</Button>
                                      
                                      
                                        {/**   
                                        <Divider type="vertical" />
                                        <Button disabled={record.account} type='link' onClick={() => {
                                            this.account = {
                                                visible: true,
                                                item: record
                                            }
                                        }}>开通账户</Button>**/}
                                        <Divider type="vertical" />
                                        <Popconfirm title="确定要删除吗？" onConfirm={() => { this.handleDel(record.id) }}>
                                            <Button type='link' >删除</Button>
                                        </Popconfirm>
                                    </>
                                ),
                            },
                        ]}
                    />
                </Element>

                {this.renderAccount()}
            </>
        )
    }

    renderAccount() {

        return (
            <Modal
                title="账户编辑"
                centered
                autoComplete="off"
                visible={this.account.visible}
                onCancel={() => this.account.visible = false}
                okText='保存'
                onOk={() => { this.saveAccount() }}
                okButtonProps={{ icon: <SaveOutlined />, htmlType: 'submit' }}
            >

                <Form.Provider>

                    <Form.Item label="账户名称" name="username" rules={[{ required: true }]}>
                        <Input style={{ width: '300px' }} value={this.account.item.account} onChange={(e) => { this.account.item.account = e.target.value }} />

                    </Form.Item>

                    <Form.Item label="账户密码" name="passWord" rules={[{ required: true }]}>
                        <Input.Password style={{ width: '300px' }} value={this.account.item.passWord} onChange={(e) => { this.account.item.passWord = e.target.value }} />

                    </Form.Item>

                    <Form.Item label="再次输入" name="passWord1" rules={[{ required: true }]}>
                        <Input.Password style={{ width: '300px' }} value={this.account.item.passWord1} onChange={(e) => { this.account.item.passWord1 = e.target.value }} />
                    </Form.Item>

                </Form.Provider>

            </Modal>
        )
    }
}
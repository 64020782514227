import React, { Component } from 'react';
import { Upload, Modal, message } from 'antd';
import { PlusOutlined, FundOutlined, EyeOutlined, DeleteOutlined, PictureOutlined, FileImageOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import loading from '../../assets/image/serch_loading.gif'
import api from "../../utils/api"
import upyun from 'yqf-upyun-upload'
import classNames from 'classnames';
import constants from '../../utils/constants'
import './index.scss'


interface PicturesWallProps {
    visible: Boolean;

}

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}
/*传入数据实例 
 [{
    action:1,
    uid: '-1',
    fileName: 'xxx.png',
    status: 'done',
    url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'  ,
    isLogo:true,//showLogoIcon时要有这个参数
    isPoster:true//showPosterIcon时要有这个参数
  }]
 */
export default @observer class PicturesWall extends Component<PicturesWallProps> {
    static defaultProps = {
        fileList: [],
        defaultDomain: "",
        bucketName: "",
        showPreviewIcon: true,
        showRemoveIcon: true,
        showLogoIcon: false,
        showPosterIcon: false,
        isEdit: true,
        placeholder:''
    }
    constructor(props) {
        super(props)
        
        this.state = {
            previewVisible: false,
            previewImage: '',
            previewTitle: '',
            fileList: props.fileList,
            fileInfo: props.fileList,
            imageTypeID: props.imageTypeID,
        }
    }

    async componentDidMount() {
  
    }

    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url ? (file.url.indexOf("http") >= 0 ? file.url : (this.props.defaultDomain ? this.props.defaultDomain : constants.IMAGE_DEFAULT_DOMAIN) + file.url) : file.preview,
            previewVisible: true,
            previewTitle: file.fileName || file.url.substring(file.url.lastIndexOf('/') + 1),
        });
    };

    handleChange = ({ file, fileList }) => {
        for (let i = 0; i < fileList.length; i++) {
            let item = fileList[i];
            if (item.response) {
                if (item.response.code == 0) {
                    item.url = item.response.path;
                    item.action = 1;
                    item.fileName = item.response.path;
                    item.imageTypeID = item.imageTypeID ? item.imageTypeID:this.props.imageTypeID;
                }
            }
        }
        this.setState({
            fileList
        });
        if (file.status == "done") {
            let onChange = this.props.onChange
            onChange && onChange(fileList)
        }
    };

    handleRemove = (i, item) => {
        const { fileList } = this.state;
        if (item.action == 2) {
            item.action = 3;
        }
        else {
            fileList.splice(i, 1);
        }
        this.setState({
            fileList
        });
        let onChange = this.props.onChange
        onChange && onChange(fileList)
    }

    //获取签名
    getHeaderSign = async (bucket, method, mimeType) => {
      
        const res = await api.default.upyunSign({
            bucket,
            method,
            mimeType,
        })
        console.log('签名返回的对象如下：', res);
        if (res == null) {
            message.error("请确认图片格式，只支持png,jpeg,gif");
        } else {
           
            return res;
        }
    };

    propsImageUpload = {
        //data: { 'bucketName': this.props.bucketName ? this.props.bucketName : constants.IMAGE_GROUP_BUCKETNAME },
        //className: 'product-single-upload',
        //action: "/Api/Default/UploadImage",
      
        //accept: 'image/*'
        className: 'product-single-upload',
        //action: this.props.imageAction ? this.props.imageAction : "/Api/Default/UploadImage",
       
        accept: 'image/*',
        multiple: this.props.multiple,
        customRequest: async (opts) => {
            let { file, onSuccess, onProgress, onError } = opts, { uid, name } = file;
            console.log("file", file);
            let bucketName = this.props.bucketName ? this.props.bucketName : "sitegroup";
            console.log("this.getHeaderSign", this.getHeaderSign);
            let image = await upyun.upload(bucketName, file, this.getHeaderSign);

            console.log("image", image);
            let resFile = {
                uid,
                name,
                path: image.path,
                code: 0
            }
            onSuccess(resFile)
        }
    }
    render() {
      
        const { onChange, showPreviewIcon, showRemoveIcon, showLogoIcon, showPosterIcon, isEdit, qty } = this.props;
        const { previewVisible, previewImage, fileList, previewTitle } = this.state;
     
        const uploadButton = (
            <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
            </div>
        );
        //图片
        return (
            <>
                {/*可编辑*/}
                {isEdit && (
                    <div className='upload-imagebox'>
                        {(fileList || []).map((val, i) => {
                            let rootClass = classNames(
                                'picture-list',
                                {
                                    'picture-list-done': val.status == 'done'
                                },
                                {
                                    'picture-list-uploading': val.status == 'uploading'
                                },
                                {
                                    'picture-list-error': val.status == 'error'
                                }
                            );
                            return  (
                                <div className={rootClass} key={i}>
                                    <div className='picture-list-inner'>
                                        {val.status == 'done' && (
                                            <>
                                                <div className='picture-list-img-signbox'>
                                                    {val.isPoster && (<div className='picture-list-img-signbox_item'>海报图</div>)}
                                                    {val.isLogo && (<div className='picture-list-img-signbox_item'>标志图</div>)}
                                                </div>
                                                <img className='picture-list-img' src={val.url ? (val.url.indexOf("http") >= 0 ? val.url : (this.props.defaultDomain ? this.props.defaultDomain : constants.IMAGE_DEFAULT_DOMAIN) + val.url) : val.thumbUrl} />
                                            </>
                                        )}
                                        {val.status == 'uploading' && (
                                            <>
                                                <div>上传中</div>
                                                <img className="loading" src={loading} />
                                            </>
                                        )}
                                        {val.status == 'error' && (
                                            <>
                                                <FundOutlined style={{ marginBottom: 5, fontSize: 30 }} />
                                                <div>上传错误</div>
                                            </>
                                        )}
                                        <div className='picture-list-actions'>
                                            {val.status == 'done' && (
                                                <>
                                                    {showPreviewIcon && (
                                                        <EyeOutlined className='picture-list-actions-icon' title='预览图片' onClick={() => {
                                                            this.handlePreview(val);
                                                        }} />
                                                    )}
                                                    {showLogoIcon && (
                                                        <PictureOutlined className='picture-list-actions-icon' title={val.isLogo ? '取消标志图' : '设为标志图'} onClick={() => {
                                                            let isLogo = val.isLogo;
                                                            if (!isLogo) {
                                                                fileList.map(item => { item.isLogo = false; });
                                                            }
                                                            val.isLogo = !isLogo;
                                                            this.setState({ fileList: fileList });
                                                            onChange && onChange(fileList);
                                                        }} />
                                                    )}
                                                    {showPosterIcon && (
                                                        <FileImageOutlined className='picture-list-actions-icon' title={val.isPoster ? '取消海报图' : '设为海报图'} onClick={() => {
                                                            let isPoster = val.isPoster;
                                                            if (!isPoster) {
                                                                fileList.map(item => { item.isPoster = false; });
                                                            }
                                                            val.isPoster = !isPoster;
                                                            this.setState({ fileList: fileList });
                                                            onChange && onChange(fileList);
                                                        }} />
                                                    )}
                                                </>
                                            )}
                                            {showRemoveIcon && (
                                                <DeleteOutlined className='picture-list-actions-icon' title='移除图片' onClick={() => {
                                                    this.handleRemove(i, val);
                                                }} />
                                            )}
                                        </div>


                                    </div>
                                </div>
                            )
                        })}
                        {fileList.length != qty &&
                            <Upload
                                {...this.propsImageUpload}
                                fileList={fileList}
                                onPreview={this.handlePreview}
                                onChange={this.handleChange}
                            >
                                {uploadButton}
                            </Upload>
                        }

                    </div>
                )}
                {/*不可编辑*/}
                {!isEdit && (
                    <div className='upload-imagebox'>
                        {(fileList || []).map((val, i) => {
                            return (
                                <div className='picture-list' key={i}>
                                    <div className='picture-list-inner'>
                                        <div className='picture-list-img-signbox'>
                                            {val.isPoster && (<div className='picture-list-img-signbox_item'>海报图</div>)}
                                            {val.isLogo && (<div className='picture-list-img-signbox_item'>标志图</div>)}
                                        </div> 
                                        <img className='picture-list-img' src={val.url ? (val.url.indexOf("http") >= 0 ? val.url : (this.props.defaultDomain ? this.props.defaultDomain : constants.IMAGE_DEFAULT_DOMAIN) + val.url) : val.thumbUrl} />
                                        <div className='picture-list-actions'>
                                            {showPreviewIcon && (
                                                <EyeOutlined className='picture-list-actions-icon' title='预览图片' onClick={() => {
                                                    this.handlePreview(val);
                                                }} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )}
                <Modal
                    className='previewImage'
                    visible={previewVisible}
                    title='预览图片'
                    footer={null}
                    onCancel={this.handleCancel}
                >
                    <img alt="example" className='preview' src={previewImage} />
                </Modal>
            </>
        )

    }
}
import React, { Component } from 'react';
import { Form, Button, Input, Row, Col, Checkbox, PageHeader, Descriptions, message, Spin, Select } from 'antd';
import { AntTable, PicturesWall, AntDescriptions } from '../../components'

import { observable, toJS } from 'mobx';
import { observer } from "mobx-react"
import { Element } from 'react-scroll'
import { SearchOutlined } from '@ant-design/icons';
import stores, { news } from '../../stores'
import constants from '../../utils/constants'
import BraftEditor from 'braft-editor'
import AdvertiStore from '../../stores/advertiStore'
import '../base-editor.scss'
import qs from 'qs'
import 'braft-editor/dist/index.css'

import NewsListCmpt from '../components/newsList.cmpt'
import utils from '../../utils';
import { CloseCircleFilled } from '@ant-design/icons';
const { TextArea } = Input;
const { Option } = Select;
export default @observer class AdvertiEditor extends Component {

    store = new AdvertiStore()
    @observable newsVisible = false

    constructor(props) {
        super(props)
        this.query = qs.parse(props.location.search.substr(1))

    }

    async componentDidMount() {

        await this.store.gets(this.query.id);

        if (utils.isNullOrEmpty(this.store.dataInfo.logoImages)) {
            this.store.dataInfo.logoImages = [];
        }

        console.log(" this.store.dataInfo", this.store.dataInfo);
    }



    publish = () => {
        this.store.dataInfo.isPublish = true;
        this.save();
    }

    save = async () => {

        let { dataInfo } = this.store;

        if (utils.isNullOrEmpty(dataInfo.title)) {
            message.error("请输入标题"); return;
        }

        if (dataInfo.logoImages.length == 0) {
            message.error("请选择封面图"); return;
        }

        if (utils.isNullOrEmpty(dataInfo.newsId)) {
            message.error("请选择新闻详情"); return;
        }

        console.log("dataInfo", dataInfo);
        var res = await this.store.save();
        if (res.code == 0) {
            message.success("操作成功");
            this.props.history.push(`/news/adverti`);
           
        } else {
            message.error(res.msg);
        }




    }

    getImageFileList = (images) => {

        let array = [];
        if (!images) {
            return array;
        }
        for (let i = 0; i < images.length; i++) {
            let item = images[i];
            if (item.action != 3) {
                array.push({ uid: i, id: (i + 1), status: 'done', url: item.fileName });
            }

        }



        return array;

    }

    render() {
        const { editorType } = this.props;
        const { filter, loading, dataInfo } = this.store

      
        return (
            <>
                <PageHeader
                    title={editorType == constants.EDITOR_TYPE_ADD ? '添加广告' : '修改广告'}
                    onBack={() => this.props.history.push('../adverti')}
                />


                <div className='product-single' >
                    <Spin spinning={loading} size='large'>
                        <Form className='product-single-form'>
                            <Form.Item style={{ marginBottom: 0 }} label="广告标题" name="title">
                                <Form.Item>
                                    <Input style={{ width: '60%' }} name="title" value={dataInfo.title} onChange={e => dataInfo.title = e.target.value} />
                                </Form.Item>
                            </Form.Item>
                            <Form.Item label="是否主图" >
                                <Form.Item>
                                    <Select
                                        style={{ width: '60%' }}
                                        value={dataInfo.isMain}
                                        onChange={(value, options) => {
                                            dataInfo.isMain = value;
                                        }}
                                    >
                                        <Option value={null}>请选择...</Option>
                                        <Option value={true}>是</Option>
                                        <Option value={false}>否</Option>
                                    </Select> 
                                    {dataInfo.isMain ? <div style={{ color: "red" }}>封面图建议大小：630：377</div> : <div style={{ color: "red" }}>封面图建议大小：281：181</div>}
                                   
                                </Form.Item>
                            </Form.Item>
                            <Form.Item label="选择新闻">
                                <div className='flex-start'>
                                    <Input placeholder='请选择新闻' value={dataInfo.newsTitle} />
                                    <button type="button" className='btn btn-primary ml10' onClick={() => {
                                        this.newsVisible = true;
                                    }}>选择新闻</button>
                                </div>
                                
                            </Form.Item>

                        
                            <Form.Item style={{ marginBottom: 0 }} label="外链" name="title">
                                <Form.Item>
                                    <Input style={{ width: '60%' }} name="title" value={dataInfo.url} onChange={e => dataInfo.url = e.target.value} />
                                </Form.Item>
                            </Form.Item>

                            <Form.Item label="封面图" >
                                {dataInfo.logoImages &&
                                    <PicturesWall
                                        style={{ width: '20%', height: '100' }}
                                        fileList={this.getImageFileList(dataInfo.logoImages)}
                                        defaultDomain={constants.IMAGE_MDD_DOMAIN}
                                        showPosterIcon={true}
                                        bucketName={constants.IMAGE_MDD_BUCKETNAME}
                                         onChange={(images) => {
                                        dataInfo.logoImages = [];
                                        images.forEach(function (img) {
                                            dataInfo.logoImages.push({
                                                fileName: img.fileName
                                            });
                                        })

                                    }}
                                    />
                                }
                                

                            </Form.Item>

  
                            <div className='save-btnbox flex-center'>
                                <Button className='btn btn-primary' onClick={() => this.save()}>保存</Button>
                                <Button className='btn btn-primary' onClick={() => this.publish()}>保存并发布</Button>
                                <Button className='btn btn-customize' onClick={() => {
                                    this.props.history.push('../adverti')
                                }}>取消</Button>
                            </div>

                        </Form>
                    </Spin>
                </div>


                <NewsListCmpt
                    visible={this.newsVisible}
                    onClose={() => {
                        this.newsVisible = false;
                    }}
                    onOk={(val) => {
                        this.newsVisible = false;
                        dataInfo.newsTitle = val.title;
                        dataInfo.newsId = val.id;
                        console.log(val);
                    }}
                />
            </>
        )
    }
}
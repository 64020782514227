import { observable, action, toJS } from "mobx"
import api from "../utils/api"
import constants from "../utils/constants"
import utils from '../utils'

export default class StaffDepartmentStore {

    @observable dataList = []
    @observable dataInfo = {}
    @observable loading = false


    @observable filter = {
        departmentName: '',
        departmentCode: ''
    }

    @action.bound
    async fetch(pagination) {

        try {
            this.loading = true;
            var res = await api.account.getDepartments();
            console.log(res);
            this.dataList = res.items
        } catch{

        } finally {
            this.loading = false;
        }
       
    }


    @action.bound
    async save() {
        var res = await api.account.departmentEdit(this.dataInfo);
        console.log("res", res);
        return res;
    }

    @action.bound
    async del() {
        var res = await api.account.departmentDel(this.dataInfo);
        return res;
    }





}
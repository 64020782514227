import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import 'antd/dist/antd.css'
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import 'moment/locale/zh-cn';
import './app.scss';
import constants from './utils/constants'
import Login from './pages/account/login'
import MainLayout from './layouts/mainLayout'
import Page404 from './pages/page404'
import api from './utils/api';
import utils from './utils';

if (process.env.NODE_ENV == 'production') {
    console.debug = () => { };
}

var routes = []
function recursiveRoutes(modules) {
    for (const m of modules) {
        if (!m.key) {
            m.key = utils.generateKey()
        }

        const path = typeof m.path == 'function' ? m.path() : m.path
        if (path && (m.component || m.render)) {
            routes.push(<Route key={m.key} exact path={path} component={m.component} render={m.render} />)
        }

        if (m.children) {
            recursiveRoutes(m.children)
        }
    }
}
recursiveRoutes(constants.SYSTEM_MODULES)

function App() {
   

    return (
        <ConfigProvider locale={zhCN} input={{ autoComplete: 'off' }}>
            <BrowserRouter>
                <Switch>
                    <Route exact path='/' render={() => <Redirect to='/dashboard' />} />
                    <Route exact path='/account/login' component={Login} />
                    <MainLayout>
                        <Switch>
                            {routes}
                            <Page404 />
                        </Switch>
                    </MainLayout>
                </Switch>
            </BrowserRouter>
        </ConfigProvider>
    );
}

export default App;

import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from "mobx-react"
import { Select } from 'antd';
import NewsStore from '../../stores/newsStore'
const { Option } = Select;
export default @observer class ActivityCmpt extends Component {
    store = new NewsStore()

    @observable filter = null;
    static defaultProps = {
        companyCode: null
    }

    constructor(props) {
        super(props)

    }


    async componentDidMount() {
        await this.store.getActivity();
    }

    render() {
        const { activityList } = this.store
        return (
            <>
                <Select
                    style={{ width: '20%' }}
                    value={this.filter}
                    onChange={(value, options) => {
                        this.filter = value;
                        this.props.onChange(options)
                    }}
                >
                    <Option value={null}>请选择...</Option>
                    {activityList.map((item) => {
                        return (
                            <Option value={item.activityCode}>{item.activityName}</Option>
                        )
                    })}
                </Select>

            </>
        )
    }

}
import React, { Component } from 'react';
import { Form, Button, Input, Row, Col, PageHeader,  Divider, message, Select, Modal, Popconfirm } from 'antd';
import { AntTable, AntDescriptions } from '../../components'
import { observable } from 'mobx';
import { observer } from "mobx-react"
import { Element } from 'react-scroll'
import { SaveOutlined } from '@ant-design/icons';
import stores from '../../stores'
import moment from 'moment'
import utils from '../../utils';
import constants from '../../utils/constants'
import StaffDepartmentStore from '../../stores/staffDepartmentStore';
const { Option } = Select;
export default @observer class Department extends Component {

    store = new StaffDepartmentStore()

    @observable modalVisible = false;
    @observable cardType = 'add';

    async componentDidMount() {
        await this.store.fetch();
    }


    handleSearch = async () => {

    }

    handleAdd = () => {
        this.modalVisible = true;
        this.cardType = 'add';
        this.store.dataInfo = {
            departmentCode: '',
            departmentName: ''
        }
    }

    handleSave = async () => {
        const { dataInfo } = this.store;
        if (utils.isNullOrEmpty(dataInfo.departmentCode)) {
            message.error("请输入部门编码"); return;
        }
        if (utils.isNullOrEmpty(dataInfo.departmentName)) {
            message.error("请输入部门名称"); return;
        }

        var res = await this.store.save();
        if (res.code == 0) {
            this.modalVisible = false;
            message.success("保存成功");
            await this.store.fetch();
        } else {
            message.error(res.message);
        }

    }

    handleDel = async (item) => {
        this.store.dataInfo = item;
        var res = await this.store.del();
        if (res.code == 0) {
            this.modalVisible = false;
            message.success("删除成功");
            await this.store.fetch();
        } else {
            message.error(res.message);
        }
    }

    render() {
        const { dataList, loading,dataInfo } = this.store
        return (
            <>
                <PageHeader title='部门管理' style={{ paddingBottom: 0 }} />
                <div className='filter-box filter-box-title-110'>
                    <Row>
                        <Col>
                            <Button type='primary' onClick={() => this.handleAdd()}>添加部门</Button>
                        </Col>
                    </Row>
                </div>

                <Element name="scrollElement">
                    <AntTable
                        dataSource={dataList}
                        loading={loading}
                        columns={[
                            {
                                title: '部门编码',
                                width: 150,
                                align: 'right',
                                dataIndex: 'departmentCode',
                            },
                           
                            {
                                title: '部门名称',
                                width: 300,
                                dataIndex: 'departmentName',
                            },
                            {},
                            {
                                title: '操作',
                                width: 260,
                                align: 'center',
                                fixed: 'right',
                                render: (text, record) => (
                                    <>
                                        <Popconfirm title="确定要删除吗？" onConfirm={() => this.handleDel(record)}>
                                            <Button type='link'>删除</Button>
                                        </Popconfirm>
                                      
                                        <Divider type="vertical" />
                                        <Button
                                            type='link'
                                            onClick={() => {
                                                console.log("record", record);
                                                this.modalVisible = true;
                                                this.store.dataInfo = record
                                            }}
                                        >编辑</Button>
                                      
                                      
                                    </>
                                ),
                            },
                        ]}
                    />
                </Element>



                {this.modalVisible && (
                    <Modal
                        title={this.cardType == 'add' ? "部门添加" : "部门编辑"}
                        centered
                      
                        visible={true}
                        onCancel={() => this.modalVisible= false}
                        okText='保存'
                        onOk={() => { this.handleSave() }}
                        okButtonProps={{ icon: <SaveOutlined />, htmlType: 'submit' }}
                    >
                        <Form.Provider>
                            
                            <Form.Item label="部门编码">
                                <Input value={dataInfo.departmentCode} onChange={e => dataInfo.departmentCode = e.target.value} style={{ width: '300px' }} />
                               
                            </Form.Item>
                        
                            <Form.Item label="部门名称">
                                <Input value={dataInfo.departmentName} onChange={e => dataInfo.departmentName = e.target.value} style={{ width: '300px' }} />

                            </Form.Item>
                         
                        </Form.Provider>
                    </Modal>
                )}
            </>
        )
    }
}
import React, { Component } from 'react';
import { Upload, Modal, message  } from 'antd';
import { PlusOutlined, FundOutlined, EyeOutlined, DeleteOutlined, PictureOutlined, FileImageOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import loading from '../../assets/image/serch_loading.gif'
import nopic from '../../assets/image/nopic.png'

import classNames from 'classnames';
import constants from '../../utils/constants'
import './index.scss'
import TcVod from 'vod-js-sdk-v6'
import api from '../../utils/api'


interface VideosWallProps {
    visible: Boolean;

}

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}
/*传入数据实例 
 [{
    uid: '-1',
    fileId:'1223456',
    //fileName: 'ccc.mp4',
    status: 'done',
    videoUrl:'http://....ccc.mp4',
    imageUrl: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
  }]
 */
export default @observer class VideosWall extends Component<VideosWallProps> {
    static defaultProps = {
        fileList: []
    }
    @observable tcVod = null;
    constructor(props) {
        super(props)
        this.tcVod = new TcVod({
            getSignature: this.getSignature
        });
        this.state = {
            previewVisible: false,
            previewImage: '',
            previewVideo: '',
            previewTitle: '',
            fileList: props.fileList
        }
    }

    getSignature = () => {
        return api.video.getVodSignature().then((result) => { return result.signature; });
    }

    async componentDidMount() {

    }

    convertVodFile = (file) => {
        let that = this;
        api.video.convertVodFile({ fileID: file.fileId }).then((result) => {
            if (result.code == 0) {
                setTimeout(function () { that.getVodFile(file); }, 1000);
            }
        });
    }

    getVodFile = (file) => {
        let that = this;
        api.video.getVodFile({ fileID: file.fileId }).then((result) => {
            if (result.code == 0) {
                if (result.imageUrl) {
                    file.imageUrl = result.imageUrl;
                    file.videoUrl = result.videoUrl;
                    this.setFileList(file);
                    //let onChange = that.props.onChange;
                    //onChange && onChange(file);
                }
                else {
                    setTimeout(function () { that.getVodFile(file); }, 1000);
                }
            }
        });
    }

    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async file => {
        //if (!file.videoUrl && !file.preview) {
        //    file.preview = await getBase64(file.originFileObj);
        //}

        this.setState({
            previewImage: file.imageUrl || nopic,
            previewVideo: file.videoUrl || null,
            previewVisible: true,
            previewTitle: file.fileName || file.videoUrl.substring(file.videoUrl.lastIndexOf('/') + 1),
        });
    };

    handleRemove = (i, item) => {
        const { fileList } = this.state;
        const { onChange } = this.props;
        if (item.action == 2) {
            item.action = 3;
        }
        else {
            fileList.splice(i, 1);
        }
        this.setState({
            fileList
        });
        onChange && onChange(fileList)
    }

    handleChange = async ({ fileList }) => {
        let info = fileList[fileList.length-1];
        const isLt1G = info.size < 1 * 1024 * 1024 * 1024;
        if (!isLt1G) {
            message.error('文件大小必须小于 1G!');
            return false;
        }
        const uploader = this.tcVod.upload({
            mediaFile: info.originFileObj, // 媒体文件（视频或音频或图片），类型为 File
        });
        let result = await uploader.done();
        let fileInfo = { fileId: result.fileId, imageUrl: "", videoUrl: result.video.url, status: "done", uid: "f"+this.state.fileList.length };
        let that = this;
        setTimeout(function () { that.convertVodFile(fileInfo); }, 1000);
        this.setFileList(fileInfo);       
    };

    setFileList = (fileInfo) => {
        const { fileList } = this.state;
        let file = fileList.find(e => e.fileId == fileInfo.fileId);
        if (file) {
            file.imageUrl = fileInfo.imageUrl;
        }
        else {
            fileInfo.action = 1;
            fileList.push(fileInfo);
        }
        this.setState({ fileList: fileList});
        let onChange = this.props.onChange;
        onChange && onChange(fileList);
    }

    uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );
    render() {
        const { previewVisible, previewImage, previewVideo, fileList, previewTitle} = this.state;
        const propsVideoUpload = {
            data: { 'bucketName': constants.IMAGE_GROUP_BUCKETNAME },
            className: 'product-single-upload',
            action: "",
            
            name: 'file',
            accept: 'video/*',
            beforeUpload: () => false
        }
        return (
            <>
                <div className='upload-imagebox'>
                    {(fileList || []).map((val, i) => {
                        let rootClass = classNames(
                            'picture-list',
                            {
                                'picture-list-done': val.status == 'done'
                            },
                            {
                                'picture-list-uploading': val.status == 'uploading'
                            },
                            {
                                'picture-list-error': val.status == 'error'
                            }
                        );
                        return val.action != 3 && (
                            <div className={rootClass} key={i}>
                                <div className='picture-list-inner'>
                                    {val.status == 'done' && (
                                        <>
                                            <div className='picture-list-img-signbox'>
                                                {!val.imageUrl && (<div className='picture-list-img-signbox_item'>正在生成封面...</div>)}
                                            </div>
                                            <img className='picture-list-img' src={val.imageUrl || nopic} />
                                        </>
                                    )}
                                    {val.status == 'uploading' && (
                                        <>
                                            <div>上传中</div>
                                            <img className="loading" src={loading} />
                                        </>
                                    )}
                                    {val.status == 'error' && (
                                        <>
                                            <FundOutlined style={{ marginBottom: 5, fontSize: 30 }} />
                                            <div>上传错误</div>
                                        </>
                                    )}
                                    <div className='picture-list-actions'>
                                        {val.status == 'done' && (
                                            <>
                                                <EyeOutlined className='picture-list-actions-icon' title='预览视频' onClick={() => {
                                                    this.handlePreview(val);
                                                }} />
                                            </>
                                        )}
                                        <DeleteOutlined className='picture-list-actions-icon' title='移除视频' onClick={() => {
                                            this.handleRemove(i, val);
                                        }} />
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    {fileList.length != this.props.qty &&
                        <Upload
                            {...propsVideoUpload}
                            showUploadList={{
                                showPreviewIcon: false,
                            }}
                            fileList={fileList}
                            onChange={this.handleChange}
                            onRemove={this.handleRemove}
                        >
                            {this.uploadButton}
                        </Upload>
                    }
                </div>
                <Modal
                    className='previewImage'
                    visible={previewVisible}
                    title='预览视频'
                    footer={null}
                    onCancel={this.handleCancel}
                >
                    {previewVideo ? (
                        <video className='preview preview-video' src={previewVideo} controls="controls">
                            您的浏览器不支持 video 标签。
                        </video>
                    ) : (
                            <img alt="example" className='preview' src={previewImage} />
                            )}
                    
                </Modal>
            </>
        )
    }
}
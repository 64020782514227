import React, { Component } from 'react';
import { Form, Button, Input, Row, Col, Steps, Modal, message, List, Popover, Radio, Popconfirm , Select,Collapse  } from 'antd';
import { RichEditor, PicturesWall, AntDescriptions } from '../../../components'
import { DeleteOutlined, PlusSquareOutlined, VerticalAlignBottomOutlined, CaretDownOutlined } from '@ant-design/icons';
import { observable, toJS } from 'mobx';
import { observer } from "mobx-react"
import constants from '../../../utils/constants'
import BraftEditor from 'braft-editor'
import utils from '../../../utils'
import stores from '../../../stores'
import moment from 'moment'
import ActivityStore from '../../../stores/activityStore';
import staticData from '../../../utils/staticData.json'
import qs from 'qs'
const { Panel } = Collapse;
const { Option } = Select
const { TextArea } = Input;
const { Step } = Steps;
export default @observer class ActivityConfigPartial extends Component {

    @observable label = {
        visible: false,
        type:'add',
        idx: 0,
        data: {
            title: '',
            style: '',
            items: null,
            checkbox: false
        }
    }

    @observable buyId = 0;

    @observable step = {
        visible: false,
        type: '',
        item: {

        }
    };

    @observable dataInfo = null;

    @observable datas = [
       
    ];

    @observable editData = {
        required: false,
        open: false
    }

    @observable categorys = []

    
    @observable list = [
        {
            title: '联系人姓名',
            key: 'name',
            dec: '请输入联系人姓名',
            style:'input',
            required: true,
            edit: false,
            val:''
        },
        {
            title: '职位',
            key: 'position',
            style: 'input',
            dec: '请输入职位',
            required: true,
            edit: false,
            val: ''
        },
        {
            title: '固定电话',
            key: 'mobile',
            style: 'input',
            dec: '请输入固定电话',
            required: false,
            edit: false,
            val: ''
        },
        {
            title: '公司名称',
            key: 'companyName',
            style: 'input',
            dec: '请输入公司名称',
            required: true,
            edit: false,
            val: ''
        },
        {
            title: '企业邮箱',
            key: 'email',
            style: 'input',
            dec: '请输入企业邮箱',
            required: false,
            edit: false,
            val: ''
        },
        {
            title: '手机号码',
            key: 'buyPhone',
            style: 'input',
            dec: '请输入手机号码',
            required: true,
            edit: false,
            val: ''
        },
        {
            title: '推荐渠道',
            key: 'channel',
            style: 'input',
            dec: '请输入推荐渠道',
            required: false,
            edit: false,
            val: ''
        }
    ]
    constructor(props) {
        super(props)
        this.store = !utils.isNullOrEmpty(props.store) ? props.store : new ActivityStore();

    }

    async componentDidMount() {
        this.search();
        
    }

    async search() {
      
        var res = await this.store.getTable({
            id: this.store.dataInfo.id,
            buyId: parseInt(this.buyId)
        });
       
        res.items.forEach((d) => {
            d.items = JSON.parse(d.items)
        })
        if (res.items.length > 0) {
            this.dataInfo = res.items[0];
            this.datas = res.items[0].items;
        } else {
            this.dataInfo = null;
            this.datas = [];
        }
    }

    publish = () => {
        this.store.dataInfo.isPublish = true;
        this.save();
    }

    save = async () => {
        const { props } = this.props;
        let { dataInfo } = this.store;


        //if (this.datas.length == 0) {
        //    message.error("请选择步骤"); return;
        //}
        var params = {
            activityID: dataInfo.id,
            buyId: parseInt(this.buyId),
            id: this.dataInfo == null ? null : this.dataInfo.id,
            items: JSON.stringify(this.datas)
        };
        console.log("params", toJS(params));

        let res = await this.store.save_table(params);
        if (res.code == 0) {
            message.success("保存成功");
            props.history.push('../activity/index')
          
        }
        else {
            message.error(res.msg);
        }
    }

    getImageFileList = (images) => {

        let array = [];
        if (!images) {
            return array;
        }
        for (let i = 0; i < images.length; i++) {
            let item = images[i];
            if (item.action != 3) {
                array.push({ uid: i, id: (i + 1), status: 'done', url: item.fileName });
            }

        }


        return array;

    }

    saveInput = (item) => {
        if (utils.isNullOrEmpty(item.title_0)) {
            message.error(item.dec); return;
        }
        item.title = item.title_0
        item.edit = false
    }

    importDefault = (idx,i) => {
        this.datas[idx].data = this.list
    }

    importBuy = (idx,i) => {
        var item = JSON.parse(staticData.CanZhanTemplate)
        console.log(idx);
        
        this.datas[idx].data = item[i].data

        console.log(" this.datas", toJS(this.datas));
       
    }

    saveEdit = () => {
        if (utils.isNullOrEmpty(this.editData.title)) {
            message.error("请输入名称"); return;
        }
      
        if (this.editData.style != 'input' && this.editData.style != 'g_city' && utils.isNullOrEmpty(this.editData.items)) {
            message.error("请输入内容"); return;
        }

        //if (this.editData.key == 'add' && this.editData.key && this.datas[this.label.idx].data.some(c => c.key == this.editData.key)) {
        //    message.error("标识已存在"); return;
        //}

        console.log("this.editData", this.editData);
     
        if (this.editData.style == 'select') {
            this.editData.items = this.editData.items.split(',')
            this.editData.checkbox = this.editData.open;
        }

    
       

        if (this.label.type == "add") {
            this.datas[this.label.idx].data.push({
                ...this.editData,
                edit: false
            })
            
        } else {
            const { title, items, style, required, open, key, checkbox ,dec} = this.editData;
            this.label.data.title = title
            this.label.data.items = items
            this.label.data.style = style
            this.label.data.required = required
            this.label.data.open = open
            this.label.data.key = key
            this.label.data.checkbox = checkbox
            this.label.data.dec = dec
            
        }

  
        this.editData = {};
        this.label.visible = false;
       
    }

    onImport = () => {
        if (this.buyId == 0) {
            this.datas = JSON.parse(staticData.CanZhanTemplate)
        } else if (this.buyId == 1) {
            this.datas = JSON.parse(staticData.BuyTemplate)
        } else {
            this.datas = JSON.parse(staticData.GzTemplate)
        }

     
    }


    render() {
        const { props } = this.props;

        const { loading, dataInfo } = this.store

        const { datas } = this;
       //  this.importDefault(key);
        console.log("list", toJS(this.list));
        console.log("datas ", toJS(this.datas));
        console.log("this.editData.open", this.editData.open)
        const genExtra = (key,d) => (
            <>
                <Popover
                    title="请选择要导入的表单？"
                    content={<><div onClick={() => {
                        this.importDefault(key,0);
                    }}><a>基础表单</a></div><div onClick={() => {
                            this.importBuy(key,1);
                        }}><a>所在行业</a></div></>}
                >
                    <VerticalAlignBottomOutlined
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                        style={{ marginRight: "15px" }} />
                </Popover>
                <PlusSquareOutlined
                    onClick={(event) => {
                        this.label.visible = true;
                        this.label.idx = key;
                        this.label.type="add"
                        event.stopPropagation();
                    }}
                    style={{ marginRight: "15px" }} />
                <Popconfirm
                    title="你确定要删除吗？"
                    onConfirm={(event) => {
                        event.stopPropagation();
                        this.datas.remove(d);
                        
                    }}
                    onCancel={(event) => {
                        event.stopPropagation();
                    }}
                >
                    <DeleteOutlined
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    />
                </Popconfirm>
            </>
        );

      
        return (
            <div className='product-single' >
                <div className='filter-box filter-box-title-110'>
                    <AntDescriptions>
                      
                        <Row>
                            <Col>
                                <Select defaultValue="0" style={{ width: 120}} onChange={(e) => {
                                    this.buyId = e;
                                    this.search();
                                }}>
                                    <Option value="0">参展商</Option>
                                    <Option value="1">买家</Option>
                                    <Option value="7">观众</Option>
                                </Select>
                                   
                                <Button type='primary' style={{ marginLeft: "20px" }}  onClick={() => {
                                    this.step = {
                                        visible: true,
                                        type: 'add',
                                        val: '',
                                        item: {
                                            
                                        }
                                    }
                                }}>添加步骤</Button>

                                <Button type='primary' style={{ marginLeft: "20px" }} onClick={() => {
                                    this.onImport();
                                }}>直接导入</Button>
                            </Col>
                          
                        </Row>
                    </AntDescriptions>
                </div>

                <div className='product-single' >

                    <Steps>
                        {this.datas.map((d,i) => {
                            return (
                                <Step title={d.title} onClick={() => {
                                    this.step = {
                                        visible: true,
                                        type: 'edit',
                                        val: d.title,
                                        item: {
                                            maxIndex: d.maxIndex,
                                            required: utils.isNullOrEmpty(d.maxIndex) ? '否' : '是',
                                            requiredError: d.requiredError,

                                        },
                                        idx: i
                                    }
                                }} />
                                )
                        })}
                    </Steps>

                    <Collapse defaultActiveKey={['1']} style={{ marginTop: "20px" }}>
                        {this.datas.map((d, i) => {
                            return (
                                <Panel header={d.title} key={i} extra={genExtra(i,d)}>
                                    {this.listRender(d.data,i)}
                                </Panel>
                                )
                        })}
 
                    </Collapse>
                </div>


                <Modal visible={this.label.visible} title={`表单${this.label.type == 'add' ? '添加' : '编辑'}`} onOk={() => {
                    this.saveEdit()
                  
                }} onCancel={() => {
                    this.label.visible = false;
                }}>
                    <Form className='product-single-form'>
                        <Form.Item style={{ marginBottom: 0 }} label="名称" name="title">
                            <Form.Item>
                                <Input placeholder="请输入名称" style={{ width: 200 }} value={this.editData.title} onChange={(e) => {
                                    //this.label.val = e.target.value;
                                    this.editData.title = e.target.value;
                             
                                }} />
                            </Form.Item>
                        </Form.Item>

                        <Form.Item style={{ marginBottom: 0 }} label="标识" name="title">
                            <Form.Item>
                                <Input placeholder="请输入英文字母标识" style={{ width: 200 }} value={this.editData.key} onChange={(e) => {
                              
                                    this.editData.key = e.target.value;

                                }} />
                            </Form.Item>
                        </Form.Item>

                        <Form.Item style={{ marginBottom: 0 }} label="说明" name="title">
                            <Form.Item>
                                <Input placeholder="请输入说明提示" style={{ width: 200 }} value={this.editData.dec} onChange={(e) => {

                                    this.editData.dec = e.target.value;

                                }} />
                            </Form.Item>
                        </Form.Item>
                     
                        <Form.Item label="类型" name="title">
                            <Form.Item>
                                <Select
                                    value={this.editData.style}
                                    style={{ width: 200 }}
                                    onChange={(value, options) => {
                                        console.log(value);
                                        this.editData.style = value;

                                        if (this.editData.style == 'g_city') {
                                            this.editData.key = "g_city_address";
                                        }
                                    }}
                                >
                                    <Option value={"input"}>输入框</Option>
                                    <Option value={"select"}>多选框</Option>
                                    
                                    <Option value={"radio"}>单选框</Option>
                                    <Option value={"g_city"}>国家城市地址</Option>

                                </Select>
                            </Form.Item>
                        </Form.Item>
                        <Form.Item label="必填" name="title">
                            <Form.Item>
                                <Radio.Group onChange={(e) => {
                                    console.log("e",e);
                                    this.editData.required = e.target.value;
                                }} value={this.editData.required}>

                                    <Radio value={true}>是</Radio>
                                    <Radio value={false}>否</Radio>

                                </Radio.Group>


                            </Form.Item>
                        </Form.Item>
                        {this.editData.style != 'input' &&

                            <>

                            <Form.Item label="展开" name="title">
                                <Form.Item>
                                <Radio.Group onChange={(e) => {
                                    this.editData.open = e.target.value;
                                }} value={this.editData.open}>

                                    <Radio value={true}>是</Radio>
                                    <Radio value={false}>否</Radio>

                                    </Radio.Group>
                                </Form.Item>
                            </Form.Item>
                            <Form.Item label="内容" name="title">
                                <Form.Item>
                                    <TextArea placeholder="下拉框的值可直接复制用,号隔开" value={this.editData.items} onChange={(e) => {
                                        this.editData.items = e.target.value
                                    }} style={{ width: 300, height: 100 }} />
                                </Form.Item>
                            </Form.Item>
                            </>
                        }
                    </Form>
                 
                  
                    </Modal>


                <Modal visible={this.step.visible} title={`步骤${this.step.type == 'add' ? '添加' : '编辑'}`} onOk={() => {

                    if (utils.isNullOrEmpty(this.step.val)) {
                        message.error("请输入步骤名称"); return;
                    }
                    const { val, item, idx } = this.step;

                   
                    if (this.step.type == 'add') {
                        this.datas.push({
                            title: val,
                            maxIndex: item.maxIndex || "",
                            requiredError: item.requiredError || "",
                            required: item.required == '是',
                            data: []
                        });
                    } else {
                        this.datas[idx].title = val;
                        this.datas[idx].maxIndex = item.maxIndex || "";
                        this.datas[idx].requiredError = item.requiredError || "";
                        this.datas[idx].required = item.required == '是';

                    }
                    this.step.visible = false;

                    console.log(" this.datas", toJS(this.datas));
                }} onCancel={() => {
                    this.step.visible = false;
                }}>
                    <p>名称：
                        <Input placeholder="请输入名称" style={{ width: 350 }} value={this.step.val} onChange={(e) => {
                            this.step.val = e.target.value
                
                        }} /></p>

                    <p>约束：
                        <Select
                            style={{ width: 200 }}
                            value={this.step.item.required}
                            onChange={(value, options) => {
                                this.step.item.required = value;

                            }}
                        >
                            {['否','是'].map((it) => {
                                return (
                                    <Option value={it}>{it}</Option>
                                )
                            })}


                        </Select></p>

                    {this.step.item.required == '是' &&
                        <>
                            <p>
                                数量：
                                <Input placeholder="" style={{ width: 50 }} value={this.step.item.maxIndex} onChange={(e) => {
                                    this.step.item.maxIndex = e.target.value

                                }} />
                            </p>
                            <p>
                                提示：
                                <Input placeholder="请输入选择数量不够时的提示" style={{ width: 350 }} value={this.step.item.requiredError} onChange={(e) => {
                                    this.step.item.requiredError = e.target.value

                                }} />
                            </p>
                        </>
                    }
                </Modal>


                <div className='save-btnbox flex-center'>
                    <Button className='btn btn-primary' onClick={() => this.save()}>保存</Button>
                  {/*  <Button className='btn btn-primary' onClick={() => this.publish()}>保存并发布</Button>*/}
                    <Button className='btn btn-customize' onClick={() => {
                        props.history.push('../activity/index')
                    }}>取消</Button>
                </div>

            </div>
        )

    }

    listRender(data,i) {
        return (
            <List
                className="demo-loadmore-list"
                dataSource={data}
                renderItem={item => (
                    <List.Item
                        actions={[
                            <a key="list-loadmore-edit" onClick={() => {
                                console.log("item", toJS(item));
                              
                                this.editData = { ...item };
                                if (this.editData.style == 'select')
                                    this.editData.items = this.editData.items.join(',');
                                this.label = {
                                    visible: true,
                                    type: 'edit',
                                    data: item,
                                    idx:i
                                }

                               
                            }}>编辑1</a>,
                            <Popconfirm
                                title="你确定要删除吗？"
                                onConfirm={(event) => {
                                    event.stopPropagation();
                                    data.remove(item);
                                    
                                 
                                }}
                                onCancel={(event) => {
                                    event.stopPropagation();
                                }}
                            >

                                <a key="list-loadmore-more">删除</a>
                            </Popconfirm>
                        ]}>

                        <List.Item.Meta
                            title={this.listRender_edit(item)}
                            description={this.renderColStyle(item)}
                        />
                    </List.Item>
                )}
            />
            )
    }

    listRender_edit(item) {

        console.log("item", toJS(item));
        return (
            <>
                {item.edit == false ?
                    <a onClick={() => {
                        item.edit = !item.edit

                    }}>{item.title}</a> : <>

                        {item.style == 'input' &&
                            <Input placeholder={item.title} style={{ width: 200 }} onChange={(e) => {

                                item.title_0 = e.target.value

                            }} />
                        }

                      
                            <a onClick={() => {
                            this.saveInput(item);
                        }}>保存</a> <a onClick={() => {
                            item.edit = false

                        }}>取消</a>
                    </>
                }
            </>
        )
    }


    renderColStyle(item) {
        return (
            <>
                {item.style == 'input' &&
                    <Input style={{ width: 200 }} />
                }

                {item.style == 'select' &&
                    <Select
                        style={{ width: 200 }}
                      
                        onChange={(value, options) => {
                            console.log(value);
              
                        }}
                >
                    {item.items.map((it) => {
                        return (
                            <Option value={it}>{it}</Option>
                        )
                    })}
                  

                    </Select>
                }

                {item.style == 'radio' &&
                    <Radio.Group onChange={() => { }} >
                        {item.items && item.items.split(',').map((d, i) => {

                            return (
                                <Radio value={i}>{d}</Radio>
                            )

                        })}
                    </Radio.Group>
                }
                </>
            )
    }

}
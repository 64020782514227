import constants from './constants';

const uuidv4 = require('uuid/v4');

function generateKey() {
    return uuidv4()
}
function getTreeChildren(item, childrenFn: (item) => Array) {
    if (typeof childrenFn == 'function') {
        return childrenFn(item)
    }

    if (childrenFn == null) {
        return item.children
    }

    return item[childrenFn]
}

function treeFind(array: Array, conditionFn: (item) => Boolean, childrenFn?: (item) => Array) {

   
    if (Array.isArray(array)) {
        for (let item of array) {
            if (conditionFn(item)) {
                return item
            }

            let children = getTreeChildren(item, childrenFn)

            let find = treeFind(children, conditionFn, childrenFn)
           
            if (find) {
                return find
            }
        }
    }

    return null
}



function isNullOrEmpty(s) {
    return s == null || s == '' || s == undefined || s == 'null' || s == ' ';
}

function getWeek (day) {
    return ["周日", "周一", "周二", "周三", "周四", "周五", "周六"][day];
}

function guid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

const utils = {
    treeFind,
    isNullOrEmpty,
    generateKey,
    getWeek,
    guid
    
}

export default utils
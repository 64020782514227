import React from 'react';

import {
    DashboardOutlined,
    FileExcelOutlined,
    SolutionOutlined,
  
    PlaySquareOutlined,
    UsergroupAddOutlined,
   
    ZoomInOutlined,
    UserSwitchOutlined
} from '@ant-design/icons';

import Dashboard from '../pages/dashboard'
import News from '../pages/news/index'
import NewsEditor from '../pages/news/news-editor'
import Briefing from '../pages/news/briefing'
import Briefing2 from '../pages/news/briefing2'
import Category from '../pages/news/category'
import Adverti from '../pages/news/adverti'
import AdvertiEditor from '../pages/news/adverti-editor'
import Staff from '../pages/staff/index'
import StaffEditor from '../pages/staff/staff-editor'
import Department from '../pages/staff/department'
import Company from '../pages/staff/company'
import Role from '../pages/purview/role'
import RoleEditor from '../pages/purview/role-editor'
import Resource from '../pages/purview/resource'
import PurviewPermission from '../pages/purview/permission'
import Card from '../pages/survey/card'
import CardEditor from '../pages/survey/card-editor'
import ActivityEditor from '../pages/activity/activity-editor'
import ActivityList from '../pages/activity/activity-list'



import SurveyData from '../pages/survey/data.js'

import Activity from '../pages/activity/index'

import Member from '../pages/activity/member'
import Account from '../pages/activity/account'
import Service from '../pages/service/index'
import ServiceCompany from '../pages/service/company'
import ServiceDetail from '../pages/service/detail'
import ServicePurchase from '../pages/service/detailPurchase'
import ServiceEdit from '../pages/service/edit' 
import DetailCompany from '../pages/service/detailCompany'
import Quote from '../pages/service/quote'
import Purchase from '../pages/service/purchase'
import ActivityAdverti from '../pages/activity/activity-adverti'
import AdvertiActivityEditor from '../pages/activity/adverti-editor'
import ActivityDemand from '../pages/activity/activity-demand'

import DrillList from '../pages/drill/index'
import DrillEditor from '../pages/drill/edit'
const EDITOR_TYPE_ADD = 'add'
const EDITOR_TYPE_MODIFY = 'modify'

const constants = {
    APPLICATION_TITLE: '资讯管理系统',
    // 各种存储键

    STORAGE_KEY_ACCOUNT:'account',
    STORAGE_KEY_ROLES:'roles',

    STORAGE_KEY_ACCESSTOKEN: 'accessToken',
    STORAGE_KEY_REFRESHTOKEN: 'refreshToken',
    STORAGE_KEY_USERNAME: 'userName',
    STORAGE_KEY_ISPERSISTENT: 'isPersistent',


    EVENT_REQUEST_AUTHENTICATION: 'requestAuthentication',
    DEFAULT_TIME_FORMAT: 'HH:mm',
    DEFAULT_DATE_MD_FORMAT: 'MM.DD',
    DEFAULT_DATE_FORMAT: 'YYYY-MM-DD',
    DEFAULT_DATETIME_FORMAT: 'YYYY-MM-DD HH:mm',

    //图片上传默认域名最后不包含 / 号
    IMAGE_DEFAULT_DOMAIN: 'https://image.gkbts.com',
    IMAGE_GROUP_BUCKETNAME: "image-gkbts",

    IMAGE_MDD_DOMAIN: 'https://image.gkbts.com',
    IMAGE_MDD_BUCKETNAME: "image-gkbts",

    EDITOR_TYPE_ADD,
    EDITOR_TYPE_MODIFY,

    SYSTEM_MODULES: [
        {
            title: '首页',
            path: '/dashboard',
            component: Dashboard,
            icon: <DashboardOutlined />,
        },
        {
            title: '航旅旅讯',
            key:'News',
            icon: <FileExcelOutlined />,
            children: [
                {
                    title: '资讯管理',
                    key: 'NewsManage',
                    path: '/news/index',
                    component: News,
                    children: [
                     
                        {
                            title: '添加',
                            path: '/news/news-editor/add',
                            render: (props) => (<NewsEditor editorType={EDITOR_TYPE_ADD} {...props} />),
                        },
                        {
                            title: '编辑',
                            path: '/news/news-editor/modify',
                            render: (props) => (<NewsEditor editorType={EDITOR_TYPE_MODIFY} {...props} />),
                        },
                        
                    ]
                },
                {
                    title: '简报管理',
                    key: 'Briefing',
                    path: '/news/briefing2',
                    component: Briefing,
                },
             
                {
                    title: '栏目管理',
                    key: 'Category',
                    path: '/news/category',
                    component: Category,
                },
                {
                    title: '广告管理',
                    key: 'Adverti',
                    path: '/news/adverti',
                    component: Adverti,
                    children: [
                        {
                            title: '添加',
                            path: '/news/adverti-editor/add',
                            render: (props) => (<AdvertiEditor editorType={EDITOR_TYPE_ADD} {...props} />),
                        },
                        {
                            title: '编辑',
                            path: '/news/adverti-editor/modify',
                            render: (props) => (<AdvertiEditor editorType={EDITOR_TYPE_MODIFY} {...props} />),
                        }
                    ]
                },
                
            ]
        },
        {
            title: '架构管理',
            key: 'Staff',
            icon: <UsergroupAddOutlined />,
            children: [
            {
                title: '员工信息',
                key: 'StaffMsg',
                path: '/staff/index',
                component: Staff,
                children: [
                    {
                        title: '添加',
                        path: '/staff/staff-editor/add',
                        render: (props) => (<StaffEditor editorType={EDITOR_TYPE_ADD} {...props} />),
                    },
                    {
                        title: '编辑',
                        path: '/staff/staff-editor/modify',
                        render: (props) => (<StaffEditor editorType={EDITOR_TYPE_MODIFY} {...props} />),
                    }
                ]
            },
                {
                    title: '公司管理',
                    key: 'Department',
                    path: '/staff/company',
                    component: Company,
                },
                {
                    title: '部门管理',
                    key: 'Company',
                    path: '/staff/department',
                  
                    component: Department,
                }


            ]
        },
        {
            title: '旅讯活动',
            key: 'Activity',
            icon: <PlaySquareOutlined />,
            children: [
                {
                    title: '活动管理',
                    key: 'ActivityMsg',
                    path: '/activity/index',
                    component: Activity,
                    children: [
                        {
                            title: '添加',
                            path: '/activity/activity-editor/add',
                            render: (props) => (<ActivityEditor editorType={EDITOR_TYPE_ADD} {...props} />),
                        },
                        {
                            title: '编辑',
                            path: '/activity/activity-editor/modify',
                            render: (props) => (<ActivityEditor editorType={EDITOR_TYPE_MODIFY} {...props} />),
                        },
                        {
                            title: '详情',
                            path: '/activity/activity-list',
                            render: (props) => (<ActivityList editorType={EDITOR_TYPE_MODIFY} {...props} />),
                        }
                    ]
                },
              
                {
                    title: '会员管理',
                    key: 'Member',
                    path: '/activity/account',
                    component: Account,
                    children: [
                        {
                            title: '详情',
                            path: '/activity/member/list',
                            render: (props) => (<Member editorType={EDITOR_TYPE_MODIFY} {...props} />),
                        }
                    ]


                }, {
                    title: '活动广告',
                    key: 'ActivityAdverti',
                    path: '/activity/activity-adverti',
                    component: ActivityAdverti,
                    children: [
                        {
                            title: '详情',
                            path: '/activity/adverti-editor/modify',
                            render: (props) => (<AdvertiActivityEditor editorType={EDITOR_TYPE_MODIFY} {...props} />),
                        },
                        {
                            title: '添加',
                            path: '/activity/adverti-editor/add',
                            render: (props) => (<AdvertiActivityEditor editorType={EDITOR_TYPE_ADD} {...props} />),
                        }
                    ]
                },
                {
                    title: '企业需求',
                    key: 'ActivityDemand',
                    path: '/activity/activity-demand',
                    component: ActivityDemand
                
                },
            ]

        },
        {
            title: '调查管理',
            key: 'Survey',
            icon: <ZoomInOutlined />,
            children: [
                {
                    title: '卡号管理',
                    key: 'card',
                    path: '/survey/card',
                    component: Card,
                    children: [
                        {
                            title: '添加',
                            path: '/survey/card-editor/add',
                            render: (props) => (<CardEditor editorType={EDITOR_TYPE_ADD} {...props} />),
                        },
                        {
                            title: '编辑',
                            path: '/survey/card-editor/modify',
                            render: (props) => (<CardEditor editorType={EDITOR_TYPE_MODIFY} {...props} />),
                        }
                    ]
                },
                {
                    title: '问卷数据',
                    key: 'SurveyData',
                    path: '/survey/data',
                    component: SurveyData,
                }
            ]
        },
        {
            title: '商旅管理',
            key: 'Service',
            icon: <UserSwitchOutlined />,
            children: [
                {
                    title: '服务商管理',
                    key: 'ServiceIndex',
                    path: '/service/index',
                    component: Service,
                    children: [
                        {
                            title: '详情',
                            path: '/service/detail',
                            render: (props) => (<ServiceDetail editorType={EDITOR_TYPE_ADD} {...props} />),
                        },
                        {
                            title: '报价',
                            path: '/service/quote',
                            render: (props) => (<Quote editorType={EDITOR_TYPE_ADD} {...props} />),
                        },
                        {
                            title: '采购详情',
                            path: '/service/purchase',
                            render: (props) => (<Purchase editorType={EDITOR_TYPE_ADD} {...props} />),
                        },
                        {
                            title: '企业采购详情',
                            path: '/service/detailPurchase',
                            render: (props) => (<ServicePurchase editorType={EDITOR_TYPE_ADD} {...props} />),
                        },
                        {
                            title: '添加服务商',
                            path: '/service/edit',
                            render: (props) => (<ServiceEdit editorType={EDITOR_TYPE_ADD} {...props} />)
                        }
                    ]
                },
                {
                    title: '企业管理',
                    key: 'ServiceCompany',
                    path: '/service/company',
                    component: ServiceCompany,
                    children: [
                        {
                            title: '详情',
                            path: '/service/detailCompany',
                            render: (props) => (<DetailCompany editorType={EDITOR_TYPE_ADD} {...props} />),
                        },
                        {
                            title: '企业采购详情',
                            path: '/service/detailPurchase',
                            render: (props) => (<ServicePurchase editorType={EDITOR_TYPE_ADD} {...props} />),
                        },
                        {
                            title: '添加企业',
                            path: '/company/edit',
                            render: (props) => (<ServiceEdit editorType={EDITOR_TYPE_ADD} {...props} />)
                        }
                    ]
                }
            ]

        },
        {
            title: '企业培训',
            key: 'cultivate',
            icon: <SolutionOutlined />,
            children: [
                {
                    title: '培训列表',
                    key: 'drill',
                    path: '/drill/index',
                    component: DrillList,
                    children: [
                        {
                            title: '添加',
                            path: '/drill/editor/add',
                            render: (props) => (<DrillEditor editorType={EDITOR_TYPE_ADD} {...props} />),
                        },
                        {
                            title: '编辑',
                            path: '/drill/editor/modify',
                            render: (props) => (<DrillEditor editorType={EDITOR_TYPE_MODIFY} {...props} />),
                        },
                    ]
                }
            ]
        },
        {
            title: '权限管理',
            key: 'Purview',
            icon: <SolutionOutlined />,
            children: [
                {
                    title: '角色管理',
                    key: 'Role',
                    path: '/purview/role',
                    component: Role,
                    children: [
                        {
                            title: '添加',
                            path: '/purview/role-editor/add',
                            render: (props) => (<RoleEditor editorType={EDITOR_TYPE_ADD} {...props} />),
                        },
                        {
                            title: '编辑',
                            path: '/purview/role-editor/modify',
                            render: (props) => (<RoleEditor editorType={EDITOR_TYPE_MODIFY} {...props} />),
                        },
                        {
                            title: '设置权限',
                            path: '/purview/role/permission',
                            component: PurviewPermission,
                        },
                    ]
                },
                {
                    title: '菜单配置',
                    key: 'Resource',
                    path: '/purview/resource',
                    component: Resource
                }
            ]
        }

    ]

}


export default constants
import React, { Component } from 'react';
import { observable, toJS } from 'mobx';
import { observer } from "mobx-react"
import { Select, Menu, Dropdown } from 'antd';
import CategoryStore from '../../stores/categoryStore';
import { DownOutlined } from '@ant-design/icons';
const { SubMenu } = Menu;
export default @observer class CategoryCmpt extends Component {
    store = new CategoryStore()
  
    static defaultProps = {
        categoryName:'请选择栏目'
    }

    constructor(props) {
        super(props)

    }

    async UNSAFE_componentWillReceiveProps(props) {
     
    }

    async componentDidMount() {
        await this.store.fetch();
    }

    categoryOnclick = (item) => {
     
        this.props.onClick(item);
    }

    renderMenu() {
        const { categoryList } = this.store; 
        return (
            <Menu>

                {categoryList.map((cate, i) => {
                    return (
                        <>
                            {cate.children != null ? this.renderSubMenu(cate.children) : <Menu.Item onClick={() => {
                            
                            }} onClick={() => { this.categoryOnclick(cate) }}>{cate.categoryName}</Menu.Item>}
                            </>
                        )
                })}
                
            </Menu>
            )
    }

    renderSubMenu(array) {
        return (
            <>
                {array.map((item, i) => {
                    return (
                        <>
                            {
                                item.children != null ? <SubMenu  onTitleClick={() => { this.categoryOnclick(item) }} title={item.categoryName}>
                                    {this.renderSubMenu(item.children)}
                                </SubMenu> : <Menu.Item onClick={() => { this.categoryOnclick(item) }}>{item.categoryName}</Menu.Item>
                            }
                            </>
                        )
                })}

            </>

        )
    }

    render() {
        const { categoryList } = this.store;
        const { categoryName } = this.props;
        return (
            <>
                <Dropdown overlay={this.renderMenu()}>
                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                        {categoryName} <DownOutlined />
                    </a>
                </Dropdown>
            </>
            )
    }
}
import React, { Component } from 'react';
import { Form, Button, Input, Row, Col, Checkbox, InputNumber , PageHeader, Descriptions, Select, Image, Menu, Card } from 'antd';
import { AntTable, AntDescriptions } from '../../components'
import { observable, toJS } from 'mobx';
import { observer } from "mobx-react"
import staticData from '../../utils/staticData.json'
import moment from 'moment'
import constants from '../../utils/constants'
import ServiceQuoteListPartial from './layouts/service-quote-list.partial'
import serviceStore from '../../stores/serviceStore';
import qs from 'qs'
const { Option } = Select;

export default @observer class ServicePurchase extends Component {

    store = new serviceStore()

    @observable menusKey = 0;
    @observable query = null

    @observable dataInfo = null;
    constructor(props) {
        super(props)
        console.log("props", props);
        this.query = qs.parse(this.props.location.search.substr(1))

    
        this.state = {
            menus: this.query.buyId == 2 ? ["服务详情", "采购报价"] : ["企业详情", "企业采购"]
        }
    }
    async componentDidMount() {

       
        var res = await this.store.fetch_purchare_detail(this.query.id);
        this.dataInfo = res.item;
        console.log("res", res);
    }

  


    render() {
       
        return this.dataInfo &&(
            <>
                <PageHeader title={this.dataInfo.companyName} style={{ paddingBottom: 0 }} onBack={() => {
                    if (this.query.buyId == 2) {
                        this.props.history.push(`index`)
                    } else {
                        this.props.history.push(`detailCompany?id=${this.dataInfo.companyID}&service=${this.query.service}&buyId=${this.query.buyId}&key=${this.query.key}`)
                    }
                }}/>

                <div className='filter-box filter-box-title-110'>

                  
                </div>
                <div className='product-single' >
                    <>
                        <Card title="基础资料">
                            {this.renderItem(this.dataInfo)}
                        </Card>
                       
                    </>
                </div>
            </>
        )
    }

    renderItem(item) {
        if (item.serviceType == "企业服务") {
            return (
                <div style={{ marginLeft: "15px" }}>
                    <p>类型: {item.serviceType}</p>
                    <p>公司名称: {item.companyName}</p>
                    <p>名称: {item.name}</p>
                    <p>预算: {item.amount}</p>
                    <p>城市: {item.city}</p>
                    <p>需要几家供应商: {item.pass}</p>
                    <p>定制需求: {item.intro}</p>
                    <p>其他需求: {item.remark}</p>
                    <p>添加时间: {moment(item.createTime).format('YYYY-MM-DD HH:mm')}</p>
                </div>
            )
        } else if (item.serviceType == "企业包机") {
            return (
                <div style={{ marginLeft: "15px" }}>
                    <p>类型: {item.serviceType}</p>
                    <p>企业/协会/组织名称: {item.enterprise.xhName}</p>
                    <p>企业/协会/组织行业: {item.enterprise.xhType}</p>
                    <p>目的地国家: {item.enterprise.country}</p>
                    <p>目的地城市: {item.enterprise.city}</p>
                    <p>包机: {item.enterprise.bj == 0 ? '单程' : '往返程'}</p>
                    <p>出行日期: {item.enterprise.bj == 0 ? moment(item.enterprise.targetDate).format('YYYY-MM-DD') : (moment(item.enterprise.sdDate).format('YYYY-MM-DD') + '-' + moment(item.enterprise.edDate).format('YYYY-MM-DD'))}</p>
                    <p>出行人数: {item.enterprise.qty}</p>
                    <p>联系电话: {item.enterprise.phone}</p>
                    <p>包机需求: {item.enterprise.remark}</p>
                </div>
                )
        } else {
            return (
                <div style={{ marginLeft: "15px" }}>
                    <p>类型: {item.serviceType}</p>
                    <p>可选时段: {item.enterprise.sdDate}</p>
                    <p>目标日期: {item.enterprise.targetDate && moment(item.enterprise.targetDate).format('YYYY-MM-DD')}</p>
                    <p>大致预算: {item.enterprise.budget}</p>
                    <p>联系手机: {item.enterprise.phone}</p>
                    <p>活动性质: {item.enterprise.nature}</p>
                    <p>活动场地: {item.enterprise.field}</p>
                    <p>参加人数: {item.enterprise.qty}</p>
                    <p>用车需求: {item.enterprise.demand ? '是' : '否'}</p>
                    <p>乘车人数: {item.enterprise.demandQty}</p>
                    <p>用餐需求: {this.renderYc(item.enterprise)}</p>
                    <p>酒店房型: {this.renderHotelType(item.enterprise)}</p>
                    <p>场地需求: {item.enterprise.area}</p>
                    <p>摆放形式: {item.enterprise.form}</p>
                    <p>所需设备: {item.enterprise.equipment}</p>
                    <p>发票需求: {item.enterprise.invoice}</p>
                    <p>其他需求: {item.enterprise.remark}</p>
                </div>
            )
        }
        
    }


    renderYc(item) {
        return (
            <>
                {item.zzmeal && <div>早餐:{item.zzmeal}</div>}
                {item.zmeal && <div>中餐:{item.zmeal}</div>}
                {item.wmeal && <div>晚餐:{item.wmeal}</div>}
            </>
            )
    }

    renderHotelType(item) {
        return (
            <>
                {item.hotelType == 0 && <div>单人</div>}
                {item.hotelType == 1 && <div>双人</div>}
                {item.hotelType == 2 && <div>不需要酒店</div>}
            </>
            )
    }
   
}
import { observable, action, toJS } from "mobx"
import api from "../utils/api"


export default class ActivityCategoryStore {
    @observable dataList = []
    @observable dataInfo = {}
    @observable loading = false

    @observable pagination = {
        current: 1,
        pageSize: 15,
        total: 0,
    }

    @observable filter = {
        title: '',
        createTime: null,
        author: '',
        context: ''
    }


    @action.bound
    async fetch() {


        try {
            this.loading = true;


            var result = await api.activityCategory.gets();

            this.dataList = result.items
            console.log("result", result);
        } catch (e) {
            throw e
        } finally {
            this.loading = false;
        }



    }


    @action.bound
    async gets(id) {
        try {
            this.loading = true;
            var res = await api.activityCategory.get({ id: id });
            console.log("res", toJS(res));
            this.dataInfo = res.info;

        } catch (e) {
            throw e
        } finally {
            this.loading = false;
        }
    }

  

  

    @action.bound
    async save() {

        console.log("res", toJS(this.dataInfo));
        var res = await api.activityCategory.edit(this.dataInfo);

        return res;
    }
}

import { Button, Divider, PageHeader, Popconfirm, message, Form, Input, Spin, Alert } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { toJS } from 'mobx';
import { observer } from "mobx-react";
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Element, scroller } from 'react-scroll';
import CompanyCmpt from '../components/company.cmpt'
import { AntTable } from '../../components';
import PurviewRoleStore from '../../stores/purviewRoleStore';
import qs from 'qs'
import constants from '../../utils/constants';
import utils from '../../utils';

export default @observer class PurviewRoleEditor extends Component {
    store = new PurviewRoleStore()

    constructor(props) {
        super(props)
        this.query = qs.parse(props.location.search.substr(1))
    }

    async componentDidMount() {
        try {
            await this.store.gets()
        }
        catch (e) {
            message.error(e.message)
        }
    }

    handleSave = async (values) => {
        this.store.roleInfo = values;
        if (utils.isNullOrEmpty(values.companys)) {
            this.store.roleInfo.companyCode = values.companys.value;
            this.store.roleInfo.companyName = values.companys.children;
        }
    
        try {
            var res=await this.store.save();
            if (res.code == 0) {
                message.success("操作成功");
                this.props.history.push('../role');
            } else {
                message.error(res.msg);
            }
        }
        catch (e) {
            message.error(e.message)
        }
    }

  

    render() {
        const { loading, saving, roleInfo } = this.store

        return (
            <>
                <PageHeader
                    title={this.props.editorType == constants.EDITOR_TYPE_ADD ? '添加角色' : '修改角色'}
                   
                    onBack={() => this.props.history.push('../role')}
                />
                <Spin spinning={loading} size='large'>
                    <div style={{ padding: '0 24px 24px 24px' }}>

                            <Form.Provider>
                                <Form
                                    layout='vertical'
                                    onFinish={values => this.handleSave(values)}
                                    fields={Object.keys(roleInfo).map(x => ({ name: [x], value: roleInfo[x] }))}
                                >
                                    <Form.Item
                                        name='roleName'
                                        label='角色名称'
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                            },
                                        ]}
                                >
                                    <Input maxLength={20}  />
                                    </Form.Item>
                                    <Form.Item
                                        name='roleNr'
                                        label='角色编号'
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                            },
                                        ]}
                                    >
                                        <Input maxLength={50} />
                                    </Form.Item>
                                    <Form.Item
                                        name='companys'
                                        label='所属公司'
                                    >
                                    <CompanyCmpt
                                     
                                        
                                        onChange={(options) => {
                                            console.log(options);
                                            }}
                                        />
                                    </Form.Item>
                                  
                                    <Form.Item
                                        name='remark'
                                        label='备注'
                                    >
                                    <Input maxLength={100} />
                                    </Form.Item>
                                <Form.Item style={{ marginBottom: 0 }}>
                                    <Button loading={saving} icon={<SaveOutlined />} type='primary' htmlType='submit'>保存</Button>
                                    </Form.Item>
                                </Form>
                            </Form.Provider>
                   
                    </div>
                </Spin>
            </>
        )
    }
}

import { Button, Divider, PageHeader, Popconfirm, message, Form, Input, InputNumber, Spin, Select } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { toJS } from 'mobx';
import { observer } from "mobx-react";
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Element, scroller } from 'react-scroll';
import CompanyCmpt from '../components/company.cmpt'
import stores from '../../stores'
import staticData from '../../utils/staticData.json'
import { RichEditor } from '../../components';
import DrillStore from '../../stores/drillStore';
import qs from 'qs'
import constants from '../../utils/constants';
import utils from '../../utils';
import BraftEditor from 'braft-editor'
const { TextArea } = Input; 

export default @observer class DrillEditor extends Component {
    store = new DrillStore()

    constructor(props) {
        super(props)
        this.query = qs.parse(props.location.search.substr(1))
    }

    async componentDidMount() {
       
        await this.store.get(this.query.id);

        if (!utils.isNullOrEmpty(this.store.dataInfo.content)) {
            this.store.dataInfo.content = BraftEditor.createEditorState(this.store.dataInfo.content);
        }

        console.log(toJS(this.store.dataInfo));
    }

    save = async () => {

        let { dataInfo } = this.store;
        const user = stores.session.user


        if (utils.isNullOrEmpty(this.store.dataInfo.title)) {
            message.error("请输入标题"); return;
        }
        dataInfo.accountNo = user.accountNo;
        dataInfo.fullName = user.fullName;
        if (!utils.isNullOrEmpty(dataInfo.content)) {
            dataInfo.content = dataInfo.content.toHTML();
        }

        

        console.log("dataInfo", toJS(dataInfo));
        var res = await this.store.save();
        if (res.code == 0) {
            message.success("操作成功");
            this.props.history.push('../index');
        } else {
            message.error(res.msg);
        }

    }

    publish = () => {
        this.store.dataInfo.isPublish = true;
        this.save();
    }


    render() {
        const { loading, saving, roleInfo } = this.store
        const { dataInfo } = this.store
        return (
            <>
                <PageHeader
                    title={this.props.editorType == constants.EDITOR_TYPE_ADD ? '添加培训' : '修改培训'}

                    onBack={() => this.props.history.push('../index')}
                />
                <div className='product-single' >
                    <Spin spinning={loading} size='large'>
                        <Form className='product-single-form'>
                            <Form.Item style={{ marginBottom: 0 }} label="标题" name="title">
                                <Form.Item>
                                    <Input style={{ width: '60%' }} name="title" value={dataInfo.title} onChange={e => dataInfo.title = e.target.value} />
                                </Form.Item>
                            </Form.Item>
                            <Form.Item label="类别" >
                                <Select
                                    options={staticData.DrillData}
                                    value={dataInfo.categoryName}
                                    onChange={(e,o) => {
                                        dataInfo.categoryName = o.label
                                        dataInfo.category = o.value
                                     
                                    }}
                                />
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label="金额" name="title">
                                <Form.Item>
                                    <InputNumber style={{ width: '10%' }} name="title" value={dataInfo.price} onChange={e => dataInfo.price = e} />
                                </Form.Item>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label="单位" name="title">
                                <Form.Item>
                                    <Input style={{ width: '10%' }} name="title" value={dataInfo.unit} onChange={e => dataInfo.unit = e.target.value} />
                                </Form.Item>
                            </Form.Item>
                            <Form.Item label="简介" >
                                <TextArea style={{ width: '60%' }} rows={2} value={dataInfo.intro} onChange={e => dataInfo.intro = e.target.value} />
                            </Form.Item>


                            <Form.Item label="排序权重" >
                                <InputNumber style={{ width: '60%' }} name="title" value={dataInfo.level} onChange={e => dataInfo.level = e} />

                            </Form.Item>
                            <Form.Item label="培训内容" >
                                <RichEditor placeholder="培训内容" value={dataInfo.content}
                                    onChange={(value) => {
                                        dataInfo.content = value;

                                    }}

                                    contentStyle={{ height: 800, boxShadow: 'inset 0 1px 3px rgba(0,0,0,.1)' }} />
                            </Form.Item>


                            <div className='save-btnbox flex-center'>
                                <Button className='btn btn-primary' onClick={() => this.save()}>保存</Button>
                                <Button className='btn btn-primary' onClick={() => this.publish()}>保存并发布</Button>
                                <Button className='btn btn-customize' onClick={() => {
                                    this.props.history.push('../index')
                                }}>取消</Button>
                            </div>

                        </Form>
                    </Spin>


                </div>
            </>
        )
    }
}
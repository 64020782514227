import React, { Component } from 'react';
import { Form, Button, Input, Row, Col, Checkbox, Popconfirm, PageHeader, Descriptions, DatePicker, Divider, message, Badge, Select } from 'antd';
import { AntTable, AntDescriptions } from '../../components'
import { observable } from 'mobx';
import { observer } from "mobx-react"
import { Element } from 'react-scroll'
import { SearchOutlined } from '@ant-design/icons';
import stores, { staff } from '../../stores'
import moment from 'moment'
import constants from '../../utils/constants'
import DepartmentCmpt from '../components/department.cmpt'
import CompanyCmpt from '../components/company.cmpt'
import StaffStore from '../../stores/staffStore';
const { Option } = Select;
export default @observer class Staff extends Component {

    store = new StaffStore()

    async componentDidMount() {
        await this.store.fetch();
    }


    handleSearch = async () => {
      
    }

    del = async (data) => {
        this.store.dataInfo = data;
        var res = await this.store.del();
        if (res.code == 0) {
            await this.store.fetch();
            message.success("删除成功");
          
        } else {
            message.error(res.msg);
        }
    }

    render() {
        const { filter, pagination, dataList, loading } = this.store
        return (
            <>
                <PageHeader title='员工管理' style={{ paddingBottom: 0 }} />

                <div className='filter-box filter-box-title-110'>
                    <AntDescriptions>

                        <Descriptions.Item label="名称" span={1}>
                            <Input defaultValue={filter.fullName} onChange={e => filter.fullName = e.target.value} allowClear />
                        </Descriptions.Item>
                        <Descriptions.Item label="公司" span={1}>

                            <CompanyCmpt
                                companyCode={filter.companyCode}
                                onChange={(options) => {
                                    filter.companyCode = options.value;
                                    filter.companyName = options.children;
                                }}
                            />
                        </Descriptions.Item>
                        <Descriptions.Item label="部门" span={1}>
                            <DepartmentCmpt
                                departmentCode={filter.departmentCode}
                                onChange={(options) => {
                                    filter.departmentCode = options.value;
                                    filter.departmentName = options.children;
                                }}
                            />
                        </Descriptions.Item>
                      
                    </AntDescriptions>



                    <Row>
                        <Col>
                            <Button type='primary' onClick={() => this.props.history.push('/staff/staff-editor/add')}>添加员工</Button>
                        </Col>
                        <Col flex={1} />
                        <Col>
                            <Button type='primary' onClick={this.handleSearch} icon={<SearchOutlined />} loading={this.loading}>查询</Button>
                        </Col>
                    </Row>
                </div>


                <Element name="scrollElement">
                    <AntTable
                        dataSource={dataList}
                        loading={loading}
                        rowKey={record => record.code}
                        pagination={{
                            ...pagination,
                            showSizeChanger: true
                        }}
                        onChange={this.handleTableChange}
                        columns={[
                            {
                                title: '登录名',
                                width: 100,
                                
                                dataIndex: 'accountNo',

                            },

                            {
                                title: '联系电话',
                                width: 100,
                                dataIndex: 'staffWorkPhone',
                            },
                            {
                                title: '部门名称',
                                width: 100,
                                dataIndex: 'departmentName',
                            },
                            {
                                title: '公司名称',
                                width: 100,
                                dataIndex: 'companyName',
                            },
                            {
                                title: '角色',
                                width: 100,
                                dataIndex: 'roleName',
                            },
                           
                            {
                                title: '操作',
                                width: 110,
                                align: 'center',
                                fixed: 'right',
                                render: (text, record) => (
                                    <>
                                        <Button type='link' onClick={() => this.props.history.push(`/staff/staff-editor/modify?id=${record.id}`)}>修改</Button>
                                        <Divider type="vertical" />

                                        <Popconfirm title="确定要删除吗？" onConfirm={() => this.del(record)}>
                                            <Button type='link'>删除</Button>
                                        </Popconfirm>
                                        
                                    </>
                                ),
                            },
                        ]}
                    />
                </Element>
            </>
        )
    }
}
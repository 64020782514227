import React, { Component } from 'react';
import { Form, Button, Input, Row, Col, Checkbox, Popconfirm, PageHeader, Descriptions, Tooltip, Divider, message, Badge } from 'antd';
import { AntTable, AntDescriptions } from '../../components'
import { observable } from 'mobx';
import { observer } from "mobx-react"
import { Element } from 'react-scroll'
import { SearchOutlined } from '@ant-design/icons';
import stores, { news } from '../../stores'
import moment from 'moment'
import constants from '../../utils/constants'
import CategorySelectCmpt from '../components/categorySelect.cmpt'
import NewsStore from '../../stores/newsStore';
export default @observer class News extends Component {

    store = new NewsStore()

    async componentDidMount() {
        await this.store.fetch();
    }

    handleTableChange = async (pagination) => {
        try {
            await this.store.fetch(pagination)

        }
        catch (e) {
            message.error(e.message)
        }
    }

    handleSearch = async () => {
        const pagination = {
            ...this.store.pagination,
            current: 1,
        }

        try {
            await this.store.fetch(pagination)
        }
        catch (e) {
            message.error(e.message)
        }
    }
    handleIsPublish = async (data) => {
        this.store.dataInfo = data;
        this.store.dataInfo.isPublish = !data.isPublish;
        await this.store.save();
        await this.store.fetch();
    }

    handleDel = async (id) => {
        var res = await this.store.newsDel(id);
        console.log("res", res);
        if (res.code == 0) {
            message.success("删除成功");
            await this.store.fetch();
        } else {
            message.error("删除失败")
      
        }
       
    }


    render() {
        const { filter, pagination, dataList, loading } = this.store
        return (
            <>
                <PageHeader title='资讯管理' style={{ paddingBottom: 0 }} />

                <div className='filter-box filter-box-title-110'>
                    <AntDescriptions>

                        <Descriptions.Item label="栏目" span={1}>
                            <CategorySelectCmpt style={{ width: '100%' }}  multiple={false} placeholder="请选择" value={filter.columnId} onChange={(val, name) => { filter.columnName = name[0] }} />
                        </Descriptions.Item>
                        <Descriptions.Item label="作者" span={1}>
                            <Input defaultValue={filter.author} onChange={e => filter.author = e.target.value} allowClear />
                        </Descriptions.Item>
                        <Descriptions.Item label="关键字" span={1}>
                            <Input defaultValue={filter.title} onChange={e => filter.title = e.target.value} allowClear />
                        </Descriptions.Item>
                    </AntDescriptions>

                
                   
                    <Row>
                        <Col>
                            <Button type='primary' onClick={() => this.props.history.push('/news/news-editor/add')}>添加资讯</Button>
                        </Col>
                        <Col flex={1} />
                        <Col>
                            <Button type='primary' onClick={this.handleSearch} icon={<SearchOutlined />} loading={this.loading}>查询</Button>
                        </Col>
                    </Row>
                </div>


                <Element name="scrollElement">
                    <AntTable
                        dataSource={dataList}
                        loading={loading}
                        rowKey={record => record.code}
                        pagination={{
                            ...pagination,
                            showSizeChanger: true
                        }}
                      
                        onChange={this.handleTableChange}
                        columns={[
                            {
                                title: '栏目',
                                width: 50,
                                dataIndex: 'columnName',

                            },

                            {
                                title: '标题',
                                width: 150,
                                dataIndex: 'title',
                                ellipsis: true,
          
                                render: (text, record) => (
                                    <Tooltip placement="topLeft" title={record.title}>
                                        {record.title}
                                    </Tooltip>
                                ),
                            },
                            //{
                            //    title: '标签',
                            //    width: 100,
                            //    render: (text, record) => (
                            //        record.label && record.label.toString()
                            //    ),
                            //},
                            {
                                title: '作者',
                                width: 80,
                                dataIndex: 'fullName',
                            },
                            {
                                title: '创建时间',
                                width: 100,
                                render: (text, record) => (
                                    moment(record.createTime).format(constants.DEFAULT_DATETIME_FORMAT)
                                ),
                            },
                            {
                                title: '状态',
                                width: 90,
                                align: 'center',
                                render: (text, record) => (
                                    <Badge
                                        color={record.isEditing ? 'yellow' : record.isPublish ? 'green' : '#d9d9d9'}
                                        text={record.isEditing ? '编辑中' : record.isPublish ? '已发布' : '未发布'}
                                    />
                                ),
                            },
                            {
                                title: '操作',
                                width: 110,
                                align: 'center',
                                fixed: 'right',
                                render: (text, record) => (
                                    <>
                                        <Button type='link' onClick={() => { this.handleIsPublish(record) }}>{record.isPublish ? "取消发布" : "发布"}</Button>
                                        <Divider type="vertical" />
                                        <Button type='link' onClick={() => this.props.history.push(`/news/news-editor/modify?id=${record.id}`)}>修改</Button>
                                        <Divider type="vertical" />
                                        <Popconfirm title="确定要删除吗？" disabled={record.isPublish} onConfirm={() => {  this.handleDel(record.id) }}>
                                            <Button type='link' disabled={record.isPublish}>删除</Button>
                                        </Popconfirm>
                                         
                                    </>
                                ),
                            },
                        ]}
                    />
                </Element>
            </>
        )
    }
}
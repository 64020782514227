import { observable, action, toJS } from "mobx"
import api from "../utils/api"


export default class ActivityStore {

    @observable videoInfo = {}
    @observable videoList = [];

    @observable imgInfo = {
        imgPath: []
    };
    @observable imgList = [];


    @observable dataList = []
    @observable dataInfo = {
        isActivity: true,
        permissions: [],
        intro: '',
        logoImageFlg:false
    }
    @observable loading = false

    @observable pagination = {
        current: 1,
        pageSize: 15,
        total: 0,
    }

    @observable filter = {
        title: '',
        createTime: null,
        author: '',
        context: ''
    }


    @action.bound
    async fetch(pagination) {

        pagination = {
            ...this.pagination,
            ...pagination,
        }
        try {


            this.loading = true;

            const { current, pageSize } = pagination;
            var result = await api.activityCategory.gets({
                pageCount: current,
                pageSize,
            });
            this.pagination = {
                current,
                pageSize,
                total: result.totalResults
            }
            this.dataList = result.items
            console.log("result", result);
        } catch (e) {
            throw e
        } finally {
            this.loading = false;
        }



    }

    @action.bound
    async fetch_company_demand(pagination) {

        pagination = {
            ...this.pagination,
            ...pagination,
        }
        try {


            this.loading = true;

            const { current, pageSize } = pagination;
            var result = await api.activity.getCompanyDemand({
                pageCount: current,
                pageSize,
                cate: this.filter.title
            });
            this.pagination = {
                current,
                pageSize,
                total: result.totalResults
            }
            this.dataList = result.items
            console.log("result", result);
        } catch (e) {
            throw e
        } finally {
            this.loading = false;
        }



    }


    @action.bound
    async gets(id) {
        try {
            this.loading = true;
            var res = await api.activityCategory.get({ id: id });
            console.log("res", toJS(res));
            this.dataInfo = res.info;

        } catch (e) {
            throw e
        } finally {
            this.loading = false;
        }
    }



    @action.bound
    async save() {

        console.log("res", toJS(this.dataInfo));
        var res = await api.activityCategory.edit(this.dataInfo);

        return res;
    }

    @action.bound
    async save_table(params) {
        var res = await api.activity.activityTableEdit(params);

        return res;
    }

    @action.bound
    async getTable(params) {
        try {
            this.loading = true;
            const { current, pageSize } = this.pagination;
            var res = await api.activity.getTable({
                ...params,
                pageCount: current,
                pageSize
            });
            console.log("res", toJS(res));
            return res;

        } catch (e) {
            throw e
        } finally {
            this.loading = false;
        }
    }


    @action.bound
    async save_video() {
        try {
            this.loading = true;
            console.log("res", toJS(this.videoInfo));
            var res = await api.video.edit(this.videoInfo);

            return res;
        } catch (e) {

        } finally {
            this.loading = false;
        }
    }

    @action.bound
    async del(id) {
        try {
            this.loading = true;
            var res = await api.video.del({ id: id })
            return res;

        } catch (e) {
            throw e
        } finally {
            this.loading = false;
        }
    }



    @action.bound
    async fetch_video(pagination) {
        pagination = {
            ...this.pagination,
            ...pagination,
        }

        try {
            this.loading = true;
            const { current, pageSize } = pagination;
            const {
                title,
                isPublish,
                createTime
            } = this.filter;

            var result = await api.video.gets({
                pageCount: current,
                pageSize,
                title,
                createTime,
                isPublish
            });


            this.pagination = {
                current,
                pageSize,
                total: result.totalResults
            }

            this.videoList = result.items
            console.log("result", result);
        } catch (e) {
            throw e
        } finally {
            this.loading = false;
        }



    }

    @action.bound
    async get_video_lable(lableType) {
        var res = await api.video.getVideoLable({ lableType: lableType})
        console.log("resres", res);
        return res.items;
    }

    @action.bound
    async del_video(id) {
        var res = await api.video.del({ id: id });
        return res;
    }

    @action.bound
    async fetch_img(filter) {
        try {
            this.loading = true;
            var res = await api.activity.getImagess(filter);
            this.imgList= res.items;

        } catch (ex) {

        } finally {
            this.loading = false;
        }
    }

    @action.bound
    async getType() {
        var res = await api.activity.getActivityType();
        return res;

    }

    @action.bound
    async save_img() {
        try {
            this.loading = true;
            console.log("res", toJS(this.imgInfo));
            var res = await api.activity.imageEdit(this.imgInfo);

            return res;
        } catch (e) {

        } finally {
            this.loading = false;
        }
    }

}
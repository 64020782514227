
import { Button, Divider, PageHeader, Popconfirm, message, Row, Col, Select, Form, Modal, Input } from 'antd';
import React, { Component } from 'react';
import { observable } from 'mobx';
import { toJS } from 'mobx';
import { observer } from "mobx-react"
import { AntTable } from '../../components';
import { Element } from 'react-scroll'
import qs from 'qs'
import CompanyCmpt from '../components/company.cmpt'
import utils from '../../utils';
import PurviewResourceStore from '../../stores/purviewResourceStore';
const { Option } = Select;
export default @observer class PurviewResource extends Component {
     menuListRef: AntTable
    store = new PurviewResourceStore()

    @observable menuListCreateFormParms = {
        type: '',
        visible: false,
        parentRecord: null,
        modifyRecord: null,
        displayOrder: 0,
    }

    constructor(props) {
        super(props)

    }

    async componentDidMount() {
        await this.store.fetch();
       
        //if (this.store.menuList.length > 0) {
        //    this.menuListCreateFormParms.displayOrder = this.store.menuList[this.store.menuList.length - 1].displayOrder + 1;
        //}

        console.log(toJS(this.store.menuList));
    }

    menuModalCreate(values) {
        const { parentRecord, type, modifyRecord } = this.menuListCreateFormParms
        if (type == 'add') {
            //顶级单添加
            if (utils.isNullOrEmpty(parentRecord)) {
                this.store.menuInfo = values;
            } else {
                if (utils.isNullOrEmpty(parentRecord.children)) {
                    parentRecord.children = [];
                }
                values.id = utils.guid();
                values.level = 1; 
                parentRecord.children.push({ ...values });
                this.store.menuInfo = parentRecord;
            }
        }
        else {
            var item = this.store.menuList.filter(c => c.children != null ? c.children.findIndex(d => d.key == modifyRecord.key && d.name == modifyRecord.name) > -1 : false);
            
            //顶级菜单修改
            if (item.length == 0) {
                modifyRecord.name = values.name;
                modifyRecord.key = values.key;

                this.store.menuInfo = modifyRecord;
               
            } else {
                item[0].children.forEach(function (m) {
                    if (m.name == modifyRecord.name && m.key == modifyRecord.key) {
                        m.name = values.name; m.key = values.key
                    }
                })
                this.store.menuInfo = item[0];
            }
          
        }
     
        this.menuListCreateFormParms.visible = false
        this.save();
    }


    save = async () => {
     //   this.store.menuInfo.displayOrder = this.menuListCreateFormParms.displayOrder;
        try {
            var res = await this.store.save();
            if (res.code == 0) {
                message.success("保存成功");
                this.store.menuInfo = {};
              
                await this.store.fetch();
            } else {
                message.error(res.msg);
            }
        }
        catch (e) {
            message.error(e.message)
        }
    }


    del = async (data) => {
        console.log(toJS(data));
        let res = null;
        if (this.store.menuList.some(c => c.id == data.id)) {
            this.store.menuInfo = data;
            res = await this.store.del();
        } else {
            var resource = this.store.menuList.find(c => c.children.some(c => c.id == data.id));
            resource.children = resource.children.filter(c => c.id != data.id);
            this.store.menuInfo = resource;
            res = await this.store.save();
        }

        if (res.code == 0) {
            message.success("保存成功");
            this.store.menuInfo = {};
            await this.store.fetch();
        } else {
            message.error(res.msg);
        }
    }

    render() {
        const { loading, menuList } = this.store
      
        return (
            <div className='filter-box filter-box-title-110'>
                <PageHeader
                    title='配置资源'
                    style={{ paddingBottom: 0 }}
                />

                <div className='filter-box filter-box-title-110'>
                    <Row>
                        <Col>
                            <Button type='primary' onClick={() => {
                                this.menuListCreateFormParms.visible = true
                                this.menuListCreateFormParms.type = "add"
                            }}>添加顶级菜单</Button>
                        </Col>
                     
                       
                    </Row>
                </div>
                <Element name="scrollElement">
                    {menuList.length > 0 &&
                        <AntTable
                            dataSource={toJS(menuList)}
                            size='small'
                            rowKey={record => record.id}
                         
                            loading={loading}
                            expandable={{ defaultExpandAllRows: true }}
                            pagination={{
                                defaultPageSize: 9999,
                                hideOnSinglePage: true,
                            }}
                            columns={[
                                {
                                    title: '名称',
                                    dataIndex: 'name',
                                    editable: true,
                                    required: true,
                                    width: 300,
                                    ellipsis: true,
                                    render: (text, record) => {
                                        return text || '(未命名)'
                                    },
                                },
                                {
                                    title: '关键字',
                                    dataIndex: 'key',
                                    editable: true,
                                    width: 300,
                                    ellipsis: true,
                                },
                                {
                                    title: '操作',
                                    width: 290,
                                    align: 'center',
                                    fixed: 'right',
                                    render: (text, record) => (
                                        <>
                                            <Button
                                                type='link'
                                                onClick={() => {
                                                    this.menuListCreateFormParms.type = 'add'
                                                    this.menuListCreateFormParms.parentRecord = record
                                                    this.menuListCreateFormParms.visible = true
                                                }}
                                                disabled={record.level > 0}
                                            >添加子菜单</Button>
                                            <Divider type="vertical" />
                                            <Button
                                                type='link'
                                                onClick={() => {
                                                    this.menuListCreateFormParms.type = 'modify'
                                                    this.menuListCreateFormParms.modifyRecord = record
                                                    this.menuListCreateFormParms.visible = true
                                                    console.log(toJS(this.menuListCreateFormParms));
                                                }}
                                            >修改</Button>
                                            <Divider type="vertical" />
                                            <Popconfirm title={record && `确定要删除“${record.name}”吗?`} disabled={record.children != null} onConfirm={() => { this.del(record); }}>
                                                <Button type='link' disabled={record.children!=null}>删除</Button>
                                            </Popconfirm>

                                        </>
                                    )
                                },
                            ]}
                        />
                    }
                </Element>

                {this.menuListCreateFormParms.visible  &&
                    <Modal
                        visible={this.menuListCreateFormParms.visible}
                        title={this.menuListCreateFormParms.type == 'add' ? '添加菜单' : '编辑菜单'}
                        mask
                        maskClosable={false}
                        onCancel={() => {
                            this.form.resetFields()
                            this.menuListCreateFormParms.visible = false;
                        }}
                        onOk={() => {
                            this.form
                                .validateFields()
                                .then(values => {
                                    this.form.resetFields()
                                    this.menuModalCreate(values);
                                })
                                .catch(info => {
                                    message.error("Form表单错误:" + info);
                                });
                        }}
                    >
                        <Form
                            layout="vertical"
                            initialValues={this.menuListCreateFormParms.type == 'add' ? null : this.menuListCreateFormParms.modifyRecord}
                            name="form_in_modal"
                            ref={ref => this.form = ref}
                        >
                            <Form.Item
                                name="name"
                                label="名称"
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                    },
                                ]}
                            >
                                <Input maxLength={20} />
                            </Form.Item>
                            <Form.Item
                                name="key"
                                label="关键字"
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                    },
                                ]}
                            >
                                <Input maxLength={50} />
                            </Form.Item>
                        </Form>
                    </Modal>
                }

            </div>
        )
    }

}
import React, { Component } from 'react';
import { Form, Button, Input, Row, Col, Checkbox, Popconfirm, PageHeader, DatePicker, Modal, Divider, message, Descriptions } from 'antd';
import { AntTable, AntDescriptions } from '../../components'
import { observable, toJS } from 'mobx';
import { observer } from "mobx-react"
import { Element } from 'react-scroll'
import { SearchOutlined, SaveOutlined } from '@ant-design/icons';
import utils from '../../utils';
import moment from 'moment'
import constants from '../../utils/constants'
import memberStore from '../../stores/memberStore';
const { TextArea } = Input;
export default @observer class Account extends Component {

    store = new memberStore()

    @observable modalVisible = false;
    constructor(props) {
        super(props)
        console.log("store", this.store);
    }
    async componentDidMount() {

        await this.store.fetch_gnk();
    }

    handleTableChange = async (pagination) => {
        try {
            await this.store.fetch_gnk(pagination)

        }
        catch (e) {
            message.error(e.message)
        }
    }

  
    handleSearch = async () => {
        const pagination = {
            ...this.store.pagination,
            current: 1,
        }

        try {
            await this.store.fetch_gnk(pagination)
       
        }
        catch (e) {
            message.error(e.message)
        }
    }

  
  



    render() {
        const { filter, pagination, datas, loading } = this.store
        return (
            <>
                <PageHeader title='会员管理' style={{ paddingBottom: 0 }} />

                <div className='filter-box filter-box-title-110'>
                    <AntDescriptions>

                        <Descriptions.Item label="昵称" span={1}>
                            <Input defaultValue={filter.author} onChange={e => filter.author = e.target.value} allowClear />
                        </Descriptions.Item>
                        <Descriptions.Item label="手机号码" span={1}>
                            <Input defaultValue={filter.author} onChange={e => filter.phone = e.target.value} allowClear />
                        </Descriptions.Item>
                        <Descriptions.Item label="公司" span={1}>
                            <Input defaultValue={filter.title} onChange={e => filter.title = e.target.value} allowClear />
                        </Descriptions.Item>
                        <Descriptions.Item label="职位" span={1}>
                            <Input defaultValue={filter.title} onChange={e => filter.title = e.target.value} allowClear />
                        </Descriptions.Item>
                    </AntDescriptions>



                    <Row>
                        <Col>
                           
                        </Col>
                        <Col flex={1} />
                        <Col>
                            <Button type='primary' onClick={this.handleSearch} icon={<SearchOutlined />} loading={this.loading}>查询</Button>
                        </Col>
                    </Row>
                </div>


                <Element name="scrollElement">
                    <AntTable
                        dataSource={datas}
                        loading={loading}
                      
                        rowKey={record => record.id}
                        pagination={{
                            ...pagination,
                            showSizeChanger: true
                        }}
                        onChange={this.handleTableChange}
                        columns={[
                            {
                                title: '昵称',
                                width: 80,
                                ellipsis: true,
                                dataIndex: 'actualName',

                            },

                            {
                                title: '手机号码',
                                width: 80,
                               
                                dataIndex: 'phone',

                            },

                            {
                                title: '真实姓名',
                                width: 80,
                                ellipsis: true,
                                dataIndex: 'name',

                            },
                            {
                                title: '公司',
                                width: 150,
                                ellipsis: true,
                                dataIndex: 'companyName',

                            },
                            {
                                title: '职位',
                                width: 100,
                                ellipsis: true,
                                dataIndex: 'position',

                            },
                            {
                                title: '个人简介',
                                width: 150,
                                ellipsis: true,
                                dataIndex: 'intro',

                            },
                            {
                                title: '添加时间',
                                ellipsis: true,
                                width: 100,
                                render: (text, record) => (
                                    moment(record.createTime).format(constants.DEFAULT_DATETIME_FORMAT)
                                ),
                            },

                            {
                                title: '操作',
                                width: 80,
                                align: 'center',
                                fixed: 'right',
                                render: (text, record) => (
                                    <>

                                        <Button type='link' onClick={() => this.props.history.push(`/activity/member/list?phone=${record.phone}`)}>详情</Button>

                                    </>
                                ),
                            },
                        ]}
                    />
                </Element>


              
            </>
        )
    }
}
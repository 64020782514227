import React, { Component } from 'react';
import { Form, Button, Input, Checkbox, Alert } from 'antd';
import { UserOutlined, LockOutlined, TabletOutlined, MailOutlined } from '@ant-design/icons';
import { observable } from 'mobx';
import { observer } from "mobx-react"
import api from '../../utils/api'
import stores from '../../stores'
export default @observer class Login extends Component {
    @observable error: Error = null
    @observable isLogging = false
    @observable isPersistent = false

    login = async (value) => {
        const { username, password } = value
        this.isLogging = true

        try {
            var res = await stores.session.login(username, password, this.isPersistent);
            
            console.log("res", res);

            if (res.code == -1) {
            
                this.error = { message: "登陆失败，用户名或密码错误" };
                return;
            }
            // 跳转到首页
            if (this.props.location.state && this.props.location.state.redirectUri) {
                this.props.history.replace(this.props.location.state.redirectUri)
            }
            else {
                this.props.history.replace('/dashboard')
            }
        }
        catch (e) {
            console.log("e", e);
            this.error = e
        }
        finally {
            this.isLogging = false
        }
    }
    render() {
        return (
            <div className='login-wrap'>
                <div className='wrap'>
                    <div className='inner'>
                        <img className="logo" src={require(`../../assets/logo.png`)} />
                        <div className='loginbox'>
                            <div className='title'>账号密码登录</div>
                            <Form onFinish={this.login}>
                                {this.error && <Alert message={this.error.message} type="error" showIcon />}
                                <Form.Item
                                    name="username"
                                    rules={[{ required: true, message: '请输入账号!' }]}
                                >
                                    <Input
                                        prefix={<UserOutlined className="site-form-item-icon" />}
                                        placeholder="账号"
                                        maxLength={30} />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    rules={[{ required: true, message: '请输入密码!' }]}
                                >
                                    <Input
                                        prefix={<LockOutlined className="site-form-item-icon" />}
                                        type="password"
                                        placeholder="密码"
                                        maxLength={20}
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Button className='loginbtn' type="primary" htmlType="submit" loading={this.isLogging}>登录</Button>
                                </Form.Item>
                             
                            </Form>

                        </div>
                        <div className='version'>Copyright © 2020 Yiqifei 版权所有</div>
                    </div>
                </div>
            </div>
        )
    }
}
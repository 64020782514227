import { observable, action, toJS } from "mobx"
import api from "../utils/api"
import constants from "../utils/constants"
import utils from '../utils'

export default class CategoryStore {
    @observable categoryList = []
    @observable categoryInfo = {}
    @observable loading = false

    @observable pagination = {
        current: 1,
        pageSize: 15,
        total: 0,
    }


    @observable filter = {
        title: '',
        className: '',
        author: '',
        columnName: '',

    }

    @action.bound
    async fetch(pagination) {
        try {
            this.loading = true;
            var res = await api.news.getCategorys();
            console.log("res", res);
            this.categoryList = res.items
        } catch{

        } finally {
            this.loading = false;
        }
    }

    @action.bound
    async save() {
        var res = await api.news.categoryEdit(this.categoryInfo);
        console.log("res", res);
        return res;
    }

    @action.bound
    async del() {
        var res = await api.news.categoryDel(this.categoryInfo);
        console.log("res", res);
        return res;
    }

}
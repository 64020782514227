import React, { Component, useState } from 'react';
import { Form, Button, Input, Row, Col, Select, Popconfirm, PageHeader, Radio, Modal, Divider, message, Descriptions, DatePicker, Popover } from 'antd';
import { AntTable, AntDescriptions } from '../../components'
import { observable, toJS } from 'mobx';
import { observer } from "mobx-react"
import { SearchOutlined, PlusOutlined, CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import BriefingStore from '../../stores/briefingStore';
import stores from '../../stores'
import moment from 'moment'
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';
import utils from '../../utils';
import constants from '../../utils/constants'
import { createFromIconfontCN } from '@ant-design/icons';
import GK from '../../assets/image/jianbao/g&k.png';
import Xing from '../../assets/image/xing.png';
import JinriJiYu from '../../assets/image/jianbao/todayLanguage.png';
import YingHao from '../../assets/image/yinhan.png';
import codeImg from "../../assets/image/jianbao/codeimg.png";
import globalText from "../../assets/image/jianbao/globalText.png";
import biaoqian from "../../assets/image/jianbao/biaoqian.png";
import tiaowen from "../../assets/image/jianbao/tiaowen.png";

const { TextArea } = Input;
const IconFont = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_620694_g09efi51bm.js',
});

const content = (
    <div style={{ width: "300px" }}>
        <p>#旅讯#</p>
        <p>*民航局：制定新一代智慧民航运输系统战略</p>

        <p>#旅行#</p>
        <p>*开头是内容日常维护</p>
        <p>+这里内容是寄语</p>

    </div>
);

const buttonWidth = 70;
export default @observer class Briefing extends Component {

    store = new BriefingStore();

    @observable viewVisible = {
        editViewVisible: false,
        longViewVisible: false,
        shortViewVisible: false,
        posterViewVisible: false,
        posterCopyVisible: false,
        posterVisible: false
    }

    @observable isPC = null;

    async componentDidMount() {
       
        await this.store.fetch();
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {//通过字符串内是否含有一些标识去判断是什么类型
            this.isPC = false
        } else if (/(Android)/i.test(navigator.userAgent)) {
            {
                this.isPC = false
            }
        }
    }


    handleOk = async () => {
        const { dataInfo } = this.store;
        const user = stores.session.user

        dataInfo.author = user.fullName;
        console.log("dataInfo", toJS(dataInfo));
        if (utils.isNullOrEmpty(dataInfo.children.find(c => c.idx == 0).title)) {
            message.error("请输入头部标题"); return;
        }
        var res = await this.store.save();
        if (res.code == 0) {
            this.viewVisible.editViewVisible = false;
            await this.store.fetch();
            message.success("操作成功");

        } else {
            message.error(res.msg);
        }

    }

    /*
    #旅讯#
    * 民航局：制定新一代智慧民航运输系统战略规划和科技攻关计划。
    * 民航局：制定新一代智慧民航运输系统战略规划和科技攻关计划
    * 民航局：制定新一代智慧民航运输系统战略规划和科技攻关计划
    # 旅行#
    * 开头是内容日常维护
        + 这里内容是寄语
    */

    handleComperCopyText = () => {
        const { context } = this.store.filter;
        const { dataInfo } = this.store;
        if (utils.isNullOrEmpty(context)) {
            message.error("请输入或者复制简报内容"); return;
        }
        var texts = "";
        let arrays = [];
        if (context.indexOf('+') > -1) {
            dataInfo.dayContent = context.substring(context.indexOf("\+") + 1, context.length);

            texts = context.substring(0, context.indexOf("\+"));
        }

        texts = texts.replace(/(\r\n|\n|\r)/gm, '').replace(/\s+/g, "").split('#');
        texts.filter(c => !utils.isNullOrEmpty(c)).forEach((item, i) => {
            if (!utils.isNullOrEmpty(item)) {
                if (item.indexOf('*') > -1) {
                    item.split('*').forEach(itf => {
                        if (!utils.isNullOrEmpty(itf)) {

                            arrays.find(c => c.idx == i - 1).items.push({
                                name: itf,
                                checked: arrays.find(c => c.idx == i - 1).items.length > 9 ? false : true
                            });
                        }
                    })
                } else {
                    arrays.push({
                        title: item,
                        idx: i,
                        items: []
                    })
                }

            }
        });
        dataInfo.children = arrays;
        dataInfo.briefingType = 1;
        this.viewVisible.posterCopyVisible = false;
        this.viewVisible.editViewVisible = true;
        console.log("arrays", arrays);

    }

    handleEditBriefing = async (id) => {
        this.viewVisible.editViewVisible = true;
        await this.store.gets(id);
        console.log("this.dataInfo", toJS(this.store.dataInfo));
    }

    handleAddItem = (idx, val) => {
        this.store.dataInfo.children.find(c => c.idx == idx).items.push({
            name: val,
            checked: false
        })

    }
    handleDel = async (id) => {
        var res = await this.store.del(id);
        console.log("res", res);
        if (res.code == 0) {
            message.success("删除成功");
            await this.store.fetch();
        } else {
            message.error("删除失败")

        }

    }

    handleSearch = async () => {
        const pagination = {
            ...this.store.pagination,
            current: 1,
        }

        try {
            await this.store.fetch(pagination)
        }
        catch (e) {
            message.error(e.message)
        }
    }



    handleSave = () => {
        var self = this;
        const node = document.getElementById("node");
        var hg = document.getElementById("node").offsetHeight;
        var wh = document.getElementById("node").offsetWidth;
        const option = {
            width: wh, // 高度宽度自行设定
            height: hg,
            scale: 4// 后面的字表示需要把一个像素转为几个像素
        }
        var dome = document.getElementById("pngImg");

        if (dome != null) {
            document.getElementById('export-img').removeChild(dome);
        }
        console.log("export-img", document.getElementById('export-img'));
        domtoimage.toPng(node, { ...option }).then((defaultUrl) => {
            const img = new Image();
            img.src = defaultUrl;

            img.setAttribute('id', 'pngImg');// 方便设置样式

            document.getElementById('export-img').appendChild(img);

            this.viewVisible.posterViewVisible = true;
            img.addEventListener('click', () => {
                var link = document.createElement('a');
                link.download = '海报.png';
                link.href = defaultUrl;
                link.click();
            })

            saveAs(defaultUrl, '海报.png');
        }).catch((e) => {
            console.log("error", e)
        })


    }

    handleCancel = () => {
        this.viewVisible.shortViewVisible = false;
        this.viewVisible.posterVisible = false;
        this.viewVisible.longViewVisible = false
        this.viewVisible.posterViewVisible = false;
        this.viewVisible.posterCopyVisible = false;
    }



    render() {
        const { dataList, filter } = this.store;

        return (
            <div className="manager-box">
                <Row>
                    <Col lg={24} xs={0}>
                        <PageHeader title='简报管理' style={{ paddingBottom: 0 }} />
                    </Col>
                </Row>
                {/*移动导航*/}
                <Row>
                    <Col lg={0} xs={24} className="news-manager">
                        <IconFont type="icon-tubiaozhizuomoban1" className="icon-managerMent" style={{ fontSize: "30px" }} />
                        <div>简报管理</div>
                    </Col>
                </Row>

                <div className='filter-box filter-box-title-110'>
                    <Row>
                        <Col lg={24}>
                            <AntDescriptions>
                                <Descriptions.Item label="日期" span={1}>
                                    <DatePicker style={{ width: "100%" }} onChange={value => filter.createTime = moment(value).format(constants.DEFAULT_DATE_FORMAT)} />
                                </Descriptions.Item>
                                <Descriptions.Item label="标题" span={1} >
                                    <Input placeholder="请输入主题" />
                                </Descriptions.Item>
                                <Descriptions.Item label="作者" span={1}>
                                    <Input placeholder="请输入作者" value={filter.author} onChange={(e) => filter.author = e.target.value} />
                                </Descriptions.Item>
                            </AntDescriptions>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Row align="middle">
                                <Button type='primary' onClick={() => this.viewVisible.posterVisible = true}>添加今日简报</Button>
                                <div style={{ marginLeft: "10px" }} className="copy-example">
                                    <Popover placement="rightTop" content={content} trigger="hover" className="pover-copy">
                                        <QuestionCircleOutlined /><span style={{ marginLeft: "4px" }}>简报复制实例</span>
                                    </Popover>
                                </div>
                            </Row>
                        </Col>
                        <Col flex={1} />
                        <Col>
                            <Button type='primary' onClick={() => this.handleSearch()} icon={<SearchOutlined />}>查询</Button>
                        </Col>
                    </Row>
                    <Row>
                        {dataList.map((item, index) => {
                            return (
                                <Col lg={6} xs={24}>
                                    <div className="kuaixun-global">
                                        <Row type="flex" justify="space-between">
                                            <Col lg={18}>
                                                <div>全球商旅快讯</div>
                                                <div className="gray-time">{moment(item.createTime).format(constants.DEFAULT_DATE_FORMAT)}</div>
                                            </Col>

                                            <Col lg={6}>
                                                <Row type="flex">
                                                    <IconFont onClick={() => this.handleEditBriefing(item.id)} type="icon-beizhu" className="icon-top" style={{ fontSize: "20px" }} />
                                                    <Popconfirm title="确定要删除吗？" onConfirm={() => { this.handleDel(item.id) }}>
                                                        <CloseOutlined className="icon-top" style={{ marginLeft: "10px", fontSize: "20px" }} />
                                                    </Popconfirm>

                                                </Row>
                                            </Col>
                                        </Row>


                                        {item.children.map((ic, i) => {
                                            return (
                                                <>
                                                    {ic.items.map((ii, i) => {
                                                        return (
                                                            <>
                                                                <Row type="flex" justify="space-between" align="top">
                                                                    <Col lg={2} xs={2}>
                                                                        <IconFont otype="icon-yqfweixuanzhong1" type={ii.checked ? 'icon-yqfxuanzhong' : 'icon-yqfweixuanzhong1'} className="icon-top" />
                                                                    </Col>
                                                                    <Col lg={22} xs={22}>
                                                                        <div>{ii.name}</div>
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                        )
                                                    })}
                                                </>
                                            )
                                        })}


                                    </div>
                                </Col>
                            )
                        })}
                    </Row>
                </div>

                {this.renderShortView()}

                {this.renderLongView()}

                {this.renderEditView()}

                {this.renderCopyView()}


                <Modal visible={this.viewVisible.posterVisible} onCancel={() => this.handleCancel()}
                    footer={<div></div>} title={<div>请选择输入模式</div>} className="news-paper2">
                    <Row type="flex">
                        <div className="mr10" onClick={() => {
                            this.viewVisible.posterVisible = false;
                            this.handleEditBriefing(null)
                        }}><Button type="primary">单条简报输入</Button></div>
                        <div onClick={() => {
                            this.viewVisible.posterCopyVisible = true
                            this.viewVisible.posterVisible = false;
                        }}><Button type="default">复制简报</Button></div>
                    </Row>
                </Modal>

            </div>
        )
    }

    renderEditView = () => {
        const { dataInfo } = this.store;
        return (

            <Modal title={<div>
                <div>全球商旅快讯</div>
                <div className="gray-time">{dataInfo.createTime != null ? moment(dataInfo.createTime).format(constants.DEFAULT_TIME_FORMAT) : ""}</div>
            </div>} visible={this.viewVisible.editViewVisible} onCancel={() => this.viewVisible.editViewVisible = false} className="time-modal" footer={<div>
                <Button type="default" onClick={() => this.handleOk()}>保存</Button>
                <Button type="primary" onClick={() => { this.viewVisible.shortViewVisible = true; this.viewVisible.posterViewVisible = false; }}>短版简报</Button>
                <Button type="primary" onClick={() => { this.viewVisible.longViewVisible = true; this.viewVisible.posterViewVisible = false; }}>长版简报</Button>
            </div>}>

                {dataInfo.children && dataInfo.children.map((item) => {
                    return (
                        <div className="global-kuaixun">

                            <Row type="flex">
                                <span style={{ marginRight: "10px" }}>#</span>
                                <Input type="text" value={item.title} onChange={(e) => {
                                    item.title = e.target.value;
                                }} style={{ width: "120px" }} placeholder="旅讯" />
                            </Row>

                            {item.items && item.items.map((ii, i) => {
                                return (
                                    <Row type="flex" align="top" style={{ marginTop: "10px" }}>
                                        <IconFont onClick={() => {
                                            ii.checked = !ii.checked
                                        }} type="icon-yqfweixuanzhong1" type={ii.checked ? 'icon-yqfxuanzhong' : 'icon-yqfweixuanzhong1'} className="icon-top2 icon-top" style={{ marginRight: "10px", marginTop: "6px" }} />

                                        <Col lg={20} xs={20}>
                                            <div><div>{ii.name}</div></div>
                                        </Col>
                                        <IconFont type="icon-guanbi" onClick={() => {
                                            item.items = item.items.filter(c => c.name != ii.name);
                                        }} className="icon-top2" style={{ marginLeft: "10px" }} />
                                    </Row>
                                )
                            })}

                            <Row className="num-box">
                                <div><Input placeholder="清单项" value={item.inputTitle} onChange={(e) => {
                                    item.inputTitle = e.target.value;
                                }} /></div>
                                <Button type="primary" onClick={() => { this.handleAddItem(item.idx, item.inputTitle); item.inputTitle = ""; }}><PlusOutlined className="icon-top2" style={{ color: "#fff", fontSize: "24px", marginTop: "-1px" }} /></Button>
                            </Row>
                        </div>
                    )
                })}

                <Row type="flex" style={{ marginTop: "10px" }}>
                    <span style={{ marginRight: "10px" }}>#</span>
                    <Input type="text" style={{ width: "120px" }} placeholder="今日寄语" value={dataInfo.dayTitle} onChange={(e) => dataInfo.dayTitle = e.target.value} />
                </Row>
                <div style={{ marginTop: "10px" }}><TextArea rows={4} value={dataInfo.dayContent} onChange={(e) => dataInfo.dayContent = e.target.value} /></div>
            </Modal>


        )
    }

    renderLongView = () => {

        return (
            <Modal visible={this.viewVisible.longViewVisible} onCancel={() => this.handleCancel()}
                footer={<div><Button type="primary" onClick={() => this.handleSave()}>保存简报</Button></div>} title={<div></div>} className="news-pic-modal" title={<div>
                    <div>长版简报</div>
                </div>}>
                {this.renderPosterView('long')}
            </Modal>
        )
    }

    renderShortView = () => {

        return (
            <Modal visible={this.viewVisible.shortViewVisible} onCancel={() => this.handleCancel()}
                footer={<div><Button type="primary" onClick={() => this.handleSave()}>保存简报</Button></div>} title={<div></div>} className="news-pic-modal" title={<div>
                    <div>短版简报</div>
                </div>}>
                {this.renderPosterView('short')}
            </Modal>
        )
    }

    renderPosterView = (text) => {
        const { dataInfo } = this.store;
        return (
            <>

                <Row className="news-start" type="flex" justify="space-between">
                </Row>
                {!this.viewVisible.posterViewVisible &&
                    <div id="node">
                        <div className="news-paper news-paper2">
                            <Row type="flex" justify="space-between" className="news-p" align="middle" style={{paddingTop:"55px"} }>
                                <Col style={{ width: "42%" }}>
                                    <img src={GK} style={{ width: "100%", height: "100%" }} />
                                </Col>
                                <Col lg={10} xs={10}>
                                    <div className="news-title">
                                        <img src={globalText} style={{ width: "100%", height: "100%" }} />
                                    </div>
                                    <div className="news-times">
                                        <span className="time-l" style={{color:"#fff"} }>{moment(dataInfo.createTime).format(constants.DEFAULT_DATE_FORMAT)}</span>
                                    </div>
                                </Col>
                            </Row>
                            <div className="news-pd10" style={{marginTop:"35px"} }>
                                <img src={tiaowen} style={{ width: "90%", height: "10px" }} className="biaoqian-img"/>
                                {text == 'long' ? <>
                                    {dataInfo.children && dataInfo.children.map((item) => {
                                        return (
                                            <div>
                                                <div className="travel-message travel-message2">{item.title}</div>
                                                <Row className="news-start" type="flex" justify="space-between">

                                                    {item.items && item.items.map((ii) => {
                                                        return (
                                                            <>

                                                                <Col lg={24} xs={24}>
                                                                    <Row type="flex" align="top">
                                                                        <Col lg={1} xs={1}><span className="xinghao">*</span></Col>
                                                                        <Col lg={23} xs={23}><div className="i-block  pc-text" style={{color:"#454545"} }><span style={{ display: "inlineBlock", verticalAlign: "top" }}>{ii.name}</span></div></Col>
                                                                    </Row>
                                                                </Col>
                                                            </>
                                                        )
                                                    })}


                                                </Row>
                                            </div>
                                        )
                                    })}

                                </> : <>

                                        <Row className="news-start" type="flex" justify="space-between">

                                            {dataInfo.children && dataInfo.children.map((item) => {
                                                return (
                                                    <>
                                                        {item.items && item.items.filter(c => c.checked).map((ii) => {
                                                            return (
                                                                <>

                                                                    <Col lg={24} xs={24}>
                                                                        <Row type="flex" align="top">
                                                                            <Col lg={1} xs={1}><span className="xinghao">*</span></Col>
                                                                            <Col lg={23} xs={23}>
                                                                                <div className="i-block line-over pc-text" style={{color:"#454545"} }><span style={{ display: "inlineBlock", verticalAlign: "top" }}>{ii.name}</span></div></Col>
                                                                        </Row>
                                                                    </Col>
                                                                </>
                                                            )
                                                        })}
                                                    </>
                                                )

                                            })}


                                        </Row>
                                    </>}





                                <div className="jiyu-mt10">
                                    {/*  <img src={JinriJiYu} style={{ width: "132px", height: "32px", marginRight: "15px", marginLeft: "10px" }} />*/}
                                    <span className="jiyu-bg-text">今日寄语</span>
                                </div>




                                {dataInfo.dayContent && dataInfo.dayContent.split('+').map((content) => {
                                    return (
                                        <Col lg={24} xs={24}>
                                            <Row className="jiyu-mt10" type="flex">
                                                <div className="today-language" style={{color:"#454545"} }>
                                                    <span style={{ display: "inlineBlock", verticalAlign: "middle" }}>{content}</span>
                                                </div>
                                            </Row>
                                        </Col>
                                    )
                                })}


                            </div>
                            <Row type="flex" align="bottom" className="code-pd10">

                                <img src={codeImg} style={{ width: "25%" }} />

                                <Col lg={18} xs={18}>
                                    <div className="golbal-service golbal-service-white" style={{color:"#fff"}}>
                                        <div>Global connect,Key to success</div>
                                        <div className="ziyuan">企业商旅全球资源对接服务平台</div>
                                    </div>
                                    <div className="code-message" style={{ color: "#fff" }}>扫码了解更多资讯</div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                }
                <div id="export-img"></div>






            </>
        )
    }

    renderCopyView = () => {
        const { dataInfo, filter } = this.store;

        return (
            <Modal visible={this.viewVisible.posterCopyVisible} onCancel={() => this.handleCancel()} className="toppic-modal"
                footer={<div>
                    <Button type="default" onClick={() => this.handleComperCopyText()}>保存</Button>
                    {/*
                    <Button type="primary">短版简报</Button>
                    <Button type="primary">长版简报</Button>*/}
                </div>} title={<div></div>} title={<div>
                    <div>短版简报</div>
                </div>} title={<div>
                    <div>全球商旅快讯</div>

                    <div style={{ marginTop: '10px' }}>
                        <DatePicker style={{ width: "50%" }} onChange={value => dataInfo.createTime = moment(value).format(constants.DEFAULT_DATE_FORMAT)} />
                    </div>

                </div>}>
                <Row type="flex" className="pd10">
                    <TextArea rows={14} value={filter.context} onInput={(e) => filter.context = e.target.value} />
                </Row>
            </Modal>
        )
    }
}
import { observable, action, toJS } from 'mobx';
import api from '../utils/api'
import constants from '../utils/constants'
import ExpiredStorage from 'expired-storage';

//api.events.onRequestAuthentication(async (headers) => {
    
//    var accessToken = localExpiredStorage.getItem(constants.STORAGE_KEY_ACCESSTOKEN) || sessionExpiredStorage.getItem(constants.STORAGE_KEY_ACCESSTOKEN)
//    if (!accessToken) {
//        var tokenResult = await refreshTokenCore()
//        if (tokenResult == null) {
//            console.error('刷新token失败')
//        }
//        accessToken = tokenResult.accessToken
//    }
//    headers['Authorization'] = 'Bearer ' + encodeURI(accessToken)
//})


interface User {
    id: string;
    accountNo: string;
    fullName: string;
    avatar: string;
    roloNo: string;
    roloName:string
}


class Session {
    @observable user: User = null
    @observable role = null;

    /**
    * 判断是否登录
    */
    @action.bound
    async isAuthenticated(): Boolean {
        this.user = await this.getUser();
       
        return this.user != null
    }

    /**
   * 获取当前登录用户
   */
    @action.bound
    async getUser(): User {
        var account = localStorage.getItem(constants.STORAGE_KEY_ACCOUNT);
        var role = localStorage.getItem(constants.STORAGE_KEY_ROLES);
     
        if (JSON.parse(account)) {
            if (this.user != null) {
                
                return this.user
            }
            else {
                this.user = JSON.parse(account);
                this.role = JSON.parse(role);
            }
        }
        return this.user;

    }

    /**
  * 用户注销
  */
    @action.bound
    async logout() {
        localStorage.removeItem(constants.STORAGE_KEY_ACCOUNT)
        localStorage.removeItem(constants.STORAGE_KEY_ROLES)
        this.user = null;this.role = null;
    }




    /**
  * 用户登录
  *
  * @param {String} userName 用户名
  * @param {String} password 密码
  * @param {Boolean} isPersistent 下次自动登录
  */
    @action.bound
    async login(userName, password, isPersistent) {

        var result = await api.account.loginMongoDb({
            userName: userName,
            password: password
        })
        console.log("result", result);
        this.user = result.user;
        this.role = result.role;
        //await api.account.profile();
        localStorage.setItem(constants.STORAGE_KEY_ACCOUNT, JSON.stringify(result.user));
        localStorage.setItem(constants.STORAGE_KEY_ROLES, JSON.stringify(result.role));
        return result;
        
    }


}



const self = new Session();
export default self;
import { observable, action, toJS } from "mobx"
import api from "../utils/api"


export default class AdvertiStore {
    @observable dataList = []
    @observable dataInfo = {}
    @observable loading = false

    @observable pagination = {
        current: 1,
        pageSize: 15,
        total: 0,
    }

    @observable filter = {
        title: '',
        isPublish: null,
        style:null

    }


    @action.bound
    async fetch(pagination) {
        pagination = {
            ...this.pagination,
            ...pagination,
        }

        try {
            this.loading = true;
            const { current, pageSize } = pagination;
            const {
                title,
                isPublish,
                style
            } = this.filter;

            var result = await api.news.getNewsAdverti({
                pageCount: current,
                pageSize,
                title,
                style,
                isPublish
            });


            this.pagination = {
                current,
                pageSize,
                total: result.totalResults
            }

            this.dataList = result.items
            console.log("result", result);
        } catch (e) {
            throw e
        } finally {
            this.loading = false;
        }



    }


    @action.bound
    async gets(id) {
        try {
            this.loading = true;
            var res = await api.news.newsAdvertiById({ id: id});
            console.log("res", toJS(res));

            this.dataInfo = res.info;
        } catch (e) {
            throw e
        } finally {
            this.loading = false;
        }
    }

    @action.bound
    async save() {

        console.log("res", toJS(this.dataInfo));
        var res = await api.news.newsAdvertiEdit(this.dataInfo);

        return res;
    }

    @action.bound
    async del(id) {
        try {
            this.loading = true;
            var res = await api.news.newsAdvertiDel({ id: id })
            return res;

        } catch (e) {
            throw e
        } finally {
            this.loading = false;
        }
    }
}
import { observable, action, toJS } from "mobx"
import api from "../utils/api"
import constants from "../utils/constants"
import utils from '../utils'

export default class StaffCompanyStore {

    @observable dataList = []
    @observable dataInfo = {}
    @observable loading = false
  

    @observable pagination = {
        current: 1,
        pageSize: 15,
        total: 0,
    }


    @observable filter = {
        fullName: '',
        companyCode: '',
        departmentCode: ''
    }

    @action.bound
    async fetch() {
        try {
            this.loading = true;
            var res = await api.account.getCompanys();
            this.dataList = res.items
        } catch{

        } finally {
            this.loading = false;
        }
      
    }

    

    @action.bound
    async save() {
        var res = await api.account.companyEdit(this.dataInfo);
        console.log("res", res);
        return res;
    }

    @action.bound
    async del() {
        var res = await api.account.companyDel(this.dataInfo);
        return res;
    }





}
import { observable, action, toJS } from "mobx"
import api from "../utils/api"


export default class StaffStore {

    @observable dataList = []
    @observable dataInfo = {}
    @observable roles = []
    @observable loading = false
  

    @observable pagination = {
        current: 1,
        pageSize: 15,
        total: 0,
    }


    @observable filter = {
        fullName: '',
        companyCode: '',
        departmentCode: ''
    }

    @action.bound
    async fetch(pagination) {
        pagination = {
            ...this.pagination,
            ...pagination,
        }

        try {
            this.loading = true;
            const { current, pageSize } = pagination;
            const {
                fullName,
                companyCode,
                departmentCode
            } = this.filter;
            var result = await api.account.getStaffs({
                pageCount: current,
                pageSize,
                fullName,
                companyCode,
                departmentCode
            })
            this.pagination = {
                current,
                pageSize,
                total: result.totalResults
            }
            this.dataList = result.items
            console.log("result", result);
        } catch (e) {
            throw e
        } finally {
            this.loading = false;
        }
    }

    @action.bound
    async gets(id) {
        try {
            this.loading = true;
            var res = await api.account.getStaffById({ id: id });
            this.dataInfo = res.info;

            console.log("account", toJS(res));
            //if (utils.isNullOrEmpty(id)) {
            //    var account = res.use.accountNo;
            //    var len = parseInt(account.substring(4, account.length)) + 1;
            //    var newAccount = "HSXW";
            //    for (var i = len.toString().length; i < 4; i++) {
            //        newAccount += "0";
            //    }
            //    newAccount += len.toString();
            //    this.dataInfo.accountNo = newAccount;
            //}

            var rolesRes = await api.purview.getRoles();
            this.roles = rolesRes.items
           
        } catch (e) {
            throw e
        } finally {
            this.loading = false;
        }
    }

    @action.bound
    async save() {
        var res = await api.account.editStaff(this.dataInfo);
        console.log("res", res);
        return res;
    }

    @action.bound
    async del() {
        var res = await api.account.delAccount(this.dataInfo);
        console.log("res", res);
        return res;
    }



}
import { observable, action, toJS } from "mobx"
import api from "../utils/api"
import constants from "../utils/constants"
import utils from '../utils'

export default class MemberStore {
    @observable datas = null
    @observable info = {}

    @observable loading = false

    @observable pagination = {
        current: 1,
        pageSize: 15,
        total: 0,
    }


    @observable filter = {
        phone: '',
        companyName: '',
        cate: '',
        activityId: '',
        buyId:null

    }
    @action.bound
    async fetch_gnk(pagination) {
        try {
           
            pagination = {
                ...this.pagination,
                ...pagination,
            }
      
            this.loading = true;
            const { current, pageSize } = pagination;
            const {
                phone

            } = this.filter;
          
            var res = await api.register.getsGnk({
                pageCount: current,
                pageSize,
                phone
            });
            this.pagination = {
                current,
                pageSize,
                total: res.totalResults
            }
            console.log("res", res);
            this.datas = res.items
        } catch{

        } finally {
            this.loading = false;
        }
    }

    @action.bound
    async fetch(pagination) {
        try {
            pagination = {
                ...this.pagination,
                ...pagination,
            }
            this.loading = true;
            const { current, pageSize } = pagination;
            const {
                phone,
               companyName,
                cate,
                activityId,
                buyId
            } = this.filter;

            var res = await api.activity.getActivityBuyList({
                pageCount: current,
                pageSize,
                phone,
                companyName,
                cate,
                activityId,
                buyId
            });
            this.pagination = {
                current,
                pageSize,
                total: res.totalResults
            }
            console.log("res", res);

            this.datas = res.items
        } catch{

        } finally {
            this.loading = false;
        }
    }

    @action.bound
    async fetch_merchant(pagination) {
        try {
            pagination = {
                ...this.pagination,
                ...pagination,
            }
            this.loading = true;
            const { current, pageSize } = pagination;
            const {
                phone,
                companyName,
                cate,
                activityId,
                buyId
            } = this.filter;

            var res = await api.register.gets({
                pageCount: current,
                pageSize,
                phone,
                companyName,
                cate,
                activityId,
                buyId
            });
            this.pagination = {
                current,
                pageSize,
                total: res.totalResults
            }

            console.log("res.items", res.items);
            this.datas = res.items.map((d) => {
                console.log("d.categoryJson", toJS(d.categoryJson));
                var ds = d.categoryJson ? JSON.parse(d.categoryJson) : "";
                console.log("ds",ds);
                return {
                    ...d,
                    xm: ds ? ds.xm : d.name,
                    gsmc: ds ? (ds.GSMC || ds.companyName) : d.companyName,
                    zw: ds ? ds.ZW : d.legalPerson,
                    bm: ds ? ds.BM : '',
                    dh: ds ? ds.SJHM : '',
                    email: ds ? ds.YX : ''
                }
               
            })

            console.log("res", toJS(this.datas));
        } catch {

        } finally {
            this.loading = false;
        }
    }

    @action.bound
    async save() {
        var res = await api.register.edit(this.info);
        console.log("res", res);
        return res;
    }

    @action.bound
    async del(id) {
        var res = await api.activity.activityBuyDel({
            id:id
        });
        console.log("res", res);
        return res;
    }

    @action.bound
    async del_merchant(id) {
        var res = await api.register.del({
            id: id
        });
        console.log("res", res);
        return res;
    }

}
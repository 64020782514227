import React, { Component } from 'react';
import { Form, Button, Input, Row, Col, Tooltip, Popconfirm, PageHeader, Descriptions, Select, Divider, message, Badge } from 'antd';
import { AntTable, AntDescriptions } from '../../components'
import { observable } from 'mobx';
import { observer } from "mobx-react"
import { Element } from 'react-scroll'
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment'

import constants from '../../utils/constants'
import AdvertiStore from '../../stores/advertiStore';
const { Option } = Select;
export default @observer class ActivityAdverti extends Component {

    store = new AdvertiStore()

    async componentDidMount() {
        this.store.filter.style = 1;
        await this.store.fetch();
    }

    handleIsPublish = async (data) => {
        this.store.dataInfo = data;
        this.store.dataInfo.isPublish = !data.isPublish;
        await this.store.save();
        await this.store.fetch();
    }

    handleSearch = async () => {
        const pagination = {
            ...this.store.pagination,
            current: 1,
        }

        try {
            await this.store.fetch(pagination)
        }
        catch (e) {
            message.error(e.message)
        }
    }

    handleDel = async (id) => {
        var res = await this.store.del(id);
        console.log("res", res);
        if (res.code == 0) {
            message.success("删除成功");
            await this.store.fetch();
        } else {
            message.error("删除失败")

        }

    }

    render() {
        const { filter, pagination, dataList, loading } = this.store
        return (
            <>
                <PageHeader title='广告管理' style={{ paddingBottom: 0 }} />

                <div className='filter-box filter-box-title-110'>
                    <AntDescriptions>

                        <Descriptions.Item label="是否发布" span={1}>
                            <Select
                                style={{ width: '60%' }}
                                value={filter.isPublish}
                                onChange={(value, options) => {
                                    filter.isPublish = value;
                                }}
                            >
                                <Option value={null}>请选择...</Option>
                                <Option value={true}>是</Option>
                                <Option value={false}>否</Option>
                            </Select>
                        </Descriptions.Item>

                        <Descriptions.Item label="关键字" span={1}>
                            <Input defaultValue={filter.title} onChange={e => filter.title = e.target.value} allowClear />
                        </Descriptions.Item>
                    </AntDescriptions>



                    <Row>
                        <Col>
                            <Button type='primary' onClick={() => this.props.history.push('/activity/adverti-editor/add')}>添加广告</Button>
                        </Col>
                        <Col flex={1} />
                        <Col>
                            <Button type='primary' onClick={this.handleSearch} icon={<SearchOutlined />} loading={this.loading}>查询</Button>
                        </Col>
                    </Row>
                </div>


                <Element name="scrollElement">
                    <AntTable
                        dataSource={dataList}
                        loading={loading}
                        rowKey={record => record.code}
                        pagination={{
                            ...pagination,
                            showSizeChanger: true
                        }}
                        onChange={this.handleTableChange}
                        columns={[
                            {
                                title: '图片',
                                width: 150,

                                render: (text, record) => (
                                    <img src={constants.IMAGE_MDD_DOMAIN + record.logoImages[0].fileName} width={150} height={100} />
                                ),

                            },

                            {
                                title: '标题',
                                width: 100,
                                dataIndex: 'title',
                                ellipsis: true,
                                render: (text, record) => (
                                    <Tooltip placement="topLeft" title={record.title}>
                                        {record.title}
                                    </Tooltip>
                                ),
                            },

                            {
                                title: '创建时间',
                                width: 100,
                                render: (text, record) => (
                                    moment(record.createTime).format(constants.DEFAULT_DATETIME_FORMAT)
                                ),
                            },
                            {
                                title: '状态',
                                width: 90,
                                align: 'center',
                                render: (text, record) => (
                                    <Badge
                                        color={record.isPublish ? 'green' : '#d9d9d9'}
                                        text={record.isPublish ? '已发布' : '未发布'}
                                    />
                                ),
                            },
                            {
                                title: '操作',
                                width: 150,
                                align: 'center',
                                fixed: 'right',
                                render: (text, record) => (
                                    <>
                                        <Button type='link' onClick={() => { this.handleIsPublish(record) }}>{record.isPublish ? "取消发布" : "发布"}</Button>
                                        <Divider type="vertical" />
                                        <Button type='link' onClick={() => this.props.history.push(`/activity/adverti-editor/modify?id=${record.id}`)}>修改</Button>
                                        <Divider type="vertical" />
                                        <Popconfirm title="确定要删除吗?" disabled={record.isPublish} onConfirm={() => { this.handleDel(record.id) }}>
                                            <Button type='link' disabled={record.isPublish}>删除</Button>
                                        </Popconfirm>

                                    </>
                                ),
                            },
                        ]}
                    />
                </Element>
            </>
        )
    }
}
import React, { Component } from 'react';
import { Form, Button, Input, Row, Col, Menu, Radio,Popconfirm, Select, PageHeader, DatePicker, Modal, Divider, message, Badge, Descriptions } from 'antd';
import { AntTable, AntDescriptions } from '../../components'
import { observable, toJS } from 'mobx';
import { observer } from "mobx-react"
import { Element } from 'react-scroll'
import { SearchOutlined, SaveOutlined } from '@ant-design/icons';
import ActivityEditorPartial from './layouts/activity-editor.partial'
import ActivityExhibitorsPartial from './layouts/activity-exhibitors-list.partial'
import ActivityVideoListPartial from './layouts/activity-video-list.partial'
import ActivitImageListPartial from './layouts/activity-image-list.partial'
import ActivityConfigPartial from './layouts/activity-config.partial'
import qs from 'qs'
import utils from '../../utils';
import moment from 'moment'
import constants from '../../utils/constants'
import ActivityStore from '../../stores/activityStore';
const { Option } = Select;
const { TextArea } = Input;
export default @observer class ActivityList extends Component {

    store = new ActivityStore()
    @observable isEnd = false;
    //'活动基础信息', '活动视频', '活动图片', '参展商信息', '买家信息','商贸配对'
    @observable menusKey = '活动基础信息';
    @observable menusList = ['活动基础信息', '活动视频', '活动图片', '参展商信息', '买家信息', '观众']
    constructor(props) {
        super(props)
        console.log("store", toJS(this.store));
        this.query = qs.parse(props.location.search.substr(1))
        this.store.dataInfo.id = this.query.id
        this.state = {
            menus: []
        }
    }
    async componentDidMount() {
       
        await this.store.gets(this.query.id);

        let menu = this.store.dataInfo.permissions;
        menu.splice(0, 0, '活动基础信息')
        let idx = menu.indexOf('参展商信息')
        if (idx > -1 || menu.indexOf('买家信息') > -1 || menu.indexOf('观众') > -1) {
            //  menu.splice(idx, 0, '表单配置')
            menu.push("表单配置");
        }
      
        this.setState({
            menus: menu
        });
        this.isEnd = true;
        console.log("menus", toJS(this.state.menus));
    }

    handleTableChange = async () => {
        try {
            await this.store.fetch()

        }
        catch (e) {
            message.error(e.message)
        }
    }

    handleSearch = async () => {


        try {
            await this.store.fetch()
        }
        catch (e) {
            message.error(e.message)
        }
    }
    handleIsPublish = async (data) => {
        this.store.dataInfo = data;
        this.store.dataInfo.isPublish = !data.isPublish;
        await this.store.save();
        await this.store.fetch();
    }


    handleSave = async () => {
        const { dataInfo } = this.store;
        if (utils.isNullOrEmpty(dataInfo.name)) {
            message.error("请输入活动名称"); return;
        }

        var res = await this.store.save();
        if (res.code == 0) {
            this.modalVisible = false;
            message.success("保存成功");
            await this.store.fetch();
        } else {
            message.error(res.message);
        }

    }



    render() {
        const { dataInfo, pagination, dataList, loading, filter } = this.store
        const { menus } = this.state;
        return (
            <>
                <PageHeader title='资讯活动' style={{ paddingBottom: 0 }}

                    onBack={() => {
                        this.props.history.push('../activity/index')
                    }}
                />

                <div className='filter-box filter-box-title-110'>

                    <Menu onClick={this.handleClick} selectedKeys={[this.menusKey]} mode="horizontal">
                        {menus.map((m) => {
                            return (
                                <Menu.Item key={m} onClick={() => {
                                    this.menusKey = m;
                                }}>
                                    {m}
                                </Menu.Item>
                              )
                        })}
                      
                      
                    </Menu>
                </div>

                <div>
                    {this.menusKey == this.menusList[0] && this.isEnd && <ActivityEditorPartial store={this.store} props={this.props} />}
                    {this.menusKey == this.menusList[5] && <ActivityExhibitorsPartial store={this.store} buyId={7} props={this.props} />}
                    {this.menusKey == this.menusList[4] && <ActivityExhibitorsPartial store={this.store} buyId={1} props={this.props} />}
                    {this.menusKey == this.menusList[3] && <ActivityExhibitorsPartial store={this.store} buyId={0}/>}
                    {this.menusKey == this.menusList[1] && <ActivityVideoListPartial store={this.store} />}
                    {this.menusKey == this.menusList[2] && <ActivitImageListPartial store={this.store} />}

                    {['表单配置'].includes(this.menusKey) && <ActivityConfigPartial store={this.store} props={this.props} key={this.menusKey}/>}
                </div>
              


           
            </>
        )
    }
}
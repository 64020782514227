
import { Button, Divider, PageHeader, Popconfirm, message, Row, Col, Select, Space } from 'antd';
import React, { Component } from 'react';
import { observable } from 'mobx';
import { toJS } from 'mobx';
import { observer } from "mobx-react"
import { AntTable } from '../../components';
import qs from 'qs'
import CompanyCmpt from '../components/company.cmpt'
import PurviewRoleStore from '../../stores/purviewRoleStore';
const { Option } = Select;
export default @observer class PurviewRole extends Component {

    store = new PurviewRoleStore()

    constructor(props) {
        super(props)

    }

    async componentDidMount() {
        await this.store.fetch();
    }

    render() {
        const { loading, roleList } = this.store
        return (
            <>
                <PageHeader
                    title="管理角色"
                
                />
                <div style={{ padding: '0 24px 16px' }}>
                    <Button
                        type='primary'
                        onClick={() => {
                            this.props.history.push('/purview/role-editor/add')
                        }}
                    >添加角色</Button>
                </div>

                <AntTable
                    dataSource={toJS(roleList)}
                    loading={loading}
                    rowKey={record => record.id}
                    pagination={{
                        hideOnSinglePage: true,
                        defaultPageSize: 9999
                    }}
                    onChange={this.handleTableChange}
                    columns={[
                        {
                            title: '角色名称',
                            ellipsis: true,
                            width: 200,
                            dataIndex: 'roleName',
                        },
                        {
                            title: '角色编号',
                            ellipsis: true,
                            width: 200,
                            dataIndex: 'roleNr',
                        },
                        {
                            title: '备注',
                            ellipsis: true,
                            width: 300,
                            dataIndex: 'remark',
                        },
                        {
                        },
                        {
                            title: '操作',
                            width: 200,
                            align: 'center',
                            fixed: 'right',
                            render: (text, record) => (
                                <>
                                    <Button type='link' onClick={() => this.props.history.push(`/purview/role/permission?${
                                        qs.stringify(
                                            {
                                                role_id: record.id,
                                            }
                                        )}`
                                    )}>设置权限</Button>
                                    <Divider type="vertical" />
                                    <Button type='link'>修改</Button>
                                    <Divider type="vertical" />
                                    <Popconfirm title={record && `确定要删除“${record.roleName}”吗?`} onConfirm={() => this.handleDelete(record)}>
                                        <Button type='link'>删除</Button>
                                    </Popconfirm>
                                </>
                            ),
                        },
                    ]}
                />
            </>
        )
    }

}
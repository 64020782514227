import React, { Component } from 'react';
import { Form, Button, Input, Row, Col, Checkbox, Alert, PageHeader, message, Descriptions, DatePicker, Spin, Select } from 'antd';
import { AntTable, PicturesWall, RichEditor } from '../../components'
import { observable } from 'mobx';
import { observer } from "mobx-react"
import { Element } from 'react-scroll'
import stores, { news } from '../../stores'
import constants from '../../utils/constants'
import DepartmentCmpt from '../components/department.cmpt'
import CompanyCmpt from '../components/company.cmpt'
import BraftEditor from 'braft-editor'
import StaffStore from '../../stores/staffStore'
import '../base-editor.scss'
import qs from 'qs'
import utils from '../../utils';
const { Option } = Select;
export default @observer class NewsEditor extends Component {

    store = new StaffStore()
    @observable labelName = ""
    constructor(props) {
        super(props)
        this.query = qs.parse(props.location.search.substr(1))

    }

    async componentDidMount() {
        try {
            await this.store.gets(this.query.id);
        } catch (e) {
            message.error(e.message)
        }
    }



    publish = () => {
        this.store.dataInfo.isPublish = true;
        this.save();
    }

    save = async () => {
        console.log("this.store.dataInfo", this.store.dataInfo);
        let { dataInfo } = this.store;

        if (utils.isNullOrEmpty(this.store.dataInfo.accountNo)) {
            message.error("请输入登录名"); return;
        }
        if (utils.isNullOrEmpty(this.store.dataInfo.PassWord)) {
            message.error("请输入密码"); return;
        }
        if (utils.isNullOrEmpty(this.store.dataInfo.staffWorkPhone)) {
            message.error("请输入联系电话"); return;
        }

        if (utils.isNullOrEmpty(this.store.dataInfo.roleNo)) {
            message.error("请选择角色"); return;
        }

        var res = await this.store.save();
        if (res.code == 0) {
            message.success("操作成功");
            this.props.history.push('../index');
        } else {
            message.error(res.msg);
        }
    }

    render() {
        const { editorType } = this.props;
        const { loading, dataInfo, roles } = this.store
        return (
            <>
                <PageHeader
                    title={editorType == constants.EDITOR_TYPE_ADD ? '员工添加' : '员工修改'}
                    onBack={() => this.props.history.push('../index')}
                />
                <div className='product-single' >
                    <Spin spinning={loading} size='large'>
                        <Form className='product-single-form'>
                            {/*  
                            <Form.Item style={{ marginBottom: 0 }} label="名称" name="title">
                                <Form.Item>
                                    <Input style={{ width: '60%' }} name="title" value={dataInfo.fullName} onChange={e => dataInfo.fullName = e.target.value} />
                                </Form.Item>
                                </Form.Item>
                             */}
                            <Form.Item style={{ marginBottom: 0 }} label="登录名" name="title">
                                <Form.Item>
                                    <Input style={{ width: '60%' }} name="title" value={dataInfo.accountNo} onChange={e => dataInfo.accountNo = e.target.value} />
                                </Form.Item>
                            </Form.Item>

                            <Form.Item style={{ marginBottom: 0 }} label="联系电话" name="title">
                                <Form.Item>
                                    <Input style={{ width: '60%' }} name="title" value={dataInfo.staffWorkPhone} onChange={e => dataInfo.staffWorkPhone = e.target.value} />
                                </Form.Item>
                            </Form.Item>
                         

                           

                            <Form.Item style={{ marginBottom: 0 }} label="登录密码" name="title">
                                <Form.Item>
                                    <Input style={{ width: '60%' }} type="password" name="title" value={dataInfo.PassWord} onChange={e => dataInfo.PassWord = e.target.value} />
                                </Form.Item>
                            </Form.Item>

                            <Form.Item label="角色" >
                                <Form.Item>
                                    <Select
                                        style={{ width: '30%' }}
                                        value={dataInfo.roleNo}
                                        onChange={(value, options) => {
                                            console.log("options", options);
                                            dataInfo.roleNo = options.value;
                                            dataInfo.roleName = options.children;
                                        }}
                                    >
                                        <Option value={null}>请选择...</Option>
                                        {roles.map((item) => {
                                            return (
                                                <Option value={item.roleNr}>{item.roleName}</Option>
                                                )
                                        })}
                                    
                                       
                                    </Select>
                                </Form.Item>
                            </Form.Item>
                         

                            <Form.Item label="公司" >
                                <Form.Item>
                                    <CompanyCmpt
                                        companyCode={dataInfo.companyCode}
                                        onChange={(options) => {
                                            this.store.dataInfo.companyCode = options.value;
                                            this.store.dataInfo.companyName = options.children;
                                        }}
                                    />
                                </Form.Item>
                            </Form.Item>

                            <Form.Item label="部门">
                                <Form.Item>
                                    <DepartmentCmpt
                                        departmentCode={dataInfo.departmentCode}
                                        onChange={(options) => {
                                            this.store.dataInfo.departmentCode = options.value;
                                            this.store.dataInfo.departmentName = options.children;
                                        }}
                                    />
                                </Form.Item>
                            </Form.Item>
                          

                            <div className='save-btnbox flex-center'>
                                <Button className='btn btn-primary' onClick={() => this.save()}>保存</Button>
                            
                                <Button className='btn btn-customize' onClick={() => {
                                    this.props.history.push('../index')
                                }}>取消</Button>
                            </div>

                        </Form>
                    </Spin>
                </div>
            </>
        )
    }
}
import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from "mobx-react"
import { Form, message, Descriptions, DatePicker, Spin, Select } from 'antd';
import StaffDepartmentStore from '../../stores/staffDepartmentStore';
const { Option } = Select;
export default @observer class DepartmentCmpt extends Component {
    store = new StaffDepartmentStore()
    static defaultProps = {
        departmentCode:null
    }

    constructor(props) {
        super(props)

    }

    async componentDidMount() {
        await this.store.fetch();
    }

    render() {
        const { dataList } = this.store
        const { departmentCode } = this.props
        return (
            <>
                <Select
                    style={{ width: '100%' }}
                    value={departmentCode}
                    onChange={(value, options) => {
                        this.props.onChange(options)
                    }}
                >
                    <Option value={null}>请选择...</Option>
                    {dataList.map((item) => {
                        return (
                            <Option value={item.departmentCode}>{item.departmentName}</Option>
                            )
                    })}
                </Select> 

            </>
        )
    }

}
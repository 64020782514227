import { observable, action, toJS } from "mobx"
import api from "../utils/api"


export default class VideoStore {
    @observable dataList = []
    @observable dataInfo = {
        activityId: null,
        videoTypeId: null
    }
    @observable loading = false
    @observable productDetail = {};
    @observable pagination = {
        current: 1,
        pageSize: 15,
        total: 0,
    }

    @observable filter = {
        title: '',
        createTime: null,
        author: '',
        context: ''
    }


    @action.bound
    async fetch(pagination) {
        pagination = {
            ...this.pagination,
            ...pagination,
        }

        try {
            this.loading = true;
            const { current, pageSize } = pagination;
            const {
                title,
                isPublish,
                createTime
     
            } = this.filter;

            var result = await api.video.gets({
                pageCount: current,
                pageSize,
                title,
                createTime,
                isPublish
            });


            this.pagination = {
                current,
                pageSize,
                total: result.totalResults
            }

            this.dataList = result.items
            console.log("result", result);
        } catch (e) {
            throw e
        } finally {
            this.loading = false;
        }



    }



    @action.bound
    async gets(id) {
        try {
            this.loading = true;
            var res = await api.video.get({ id: id });
            console.log("res", toJS(res));
            this.dataInfo = res.info;

        } catch (e) {
            throw e
        } finally {
            this.loading = false;
        }
    }



    @action.bound
    async save() {

        console.log("res", toJS(this.dataInfo));
        var res = await api.video.edit(this.dataInfo);

        return res;
    }

    @action.bound
    async del(id) {
        try {
            this.loading = true;
            var res = await api.video.del({ id: id })
            return res;

        } catch (e) {
            throw e
        } finally {
            this.loading = false;
        }
    }

    @action.bound
    async fetch_category(pagination) {
        pagination = {
            ...this.pagination,
            ...pagination,
        }

        try {
            this.loading = true;
            const { current, pageSize } = pagination;
            const {
                title,
                isPublish,
                createTime
  
            } = this.filter;

            var result = await api.video.getsCategory({
                pageCount: current,
                pageSize,
                title,
                createTime,
                isPublish
            });


            this.pagination = {
                current,
                pageSize,
                total: result.totalResults
            }

            this.dataList = result.items
            console.log("result", result);
        } catch (e) {
            throw e
        } finally {
            this.loading = false;
        }



    }

    @action.bound
    async save_category() {

        console.log("res", toJS(this.dataInfo));
        var res = await api.video.editCategory(this.dataInfo);

        return res;
    }

    @action.bound
    async del_category(id) {
        try {
            this.loading = true;
            var res = await api.video.delCategory({ id: id })
            return res;

        } catch (e) {
            throw e
        } finally {
            this.loading = false;
        }
    }
}
import React, { Component } from 'react';
import { Form, Button, Input, Row, Col, Checkbox, message, PageHeader, Descriptions, Select, Radio, Menu, Spin } from 'antd';
import { AntTable, RichEditor,PicturesWall } from '../../components'
import { observable, toJS } from 'mobx';
import { observer } from "mobx-react"
import staticData from '../../utils/staticData.json'
import moment from 'moment'
import BraftEditor from 'braft-editor'
import constants from '../../utils/constants'
import serviceStore from '../../stores/serviceStore';
import utils from '../../utils';
import qs from 'qs'
const { TextArea } = Input;
const { Option } = Select;

export default @observer class ServiceEdit extends Component {

    store = new serviceStore()

    @observable menusKey = 0;
    @observable query = null
    @observable isEnd = false;
    constructor(props) {
        super(props)
        
        this.query = qs.parse(this.props.location.search.substr(1))
        this.store.dataInfo.buyId = parseInt(this.query.buyId);

        
    }
    async componentDidMount() {
        if (!utils.isNullOrEmpty(this.query.id)) {
            await this.store.get(this.query.id);

            if (!utils.isNullOrEmpty(this.store.dataInfo.intro)) {
                this.store.dataInfo.intro = BraftEditor.createEditorState(this.store.dataInfo.intro);
               
            }

         
            if (!utils.isNullOrEmpty(this.store.dataInfo.service)) {
                this.store.dataInfo.service = this.store.dataInfo.service.split(',');
            }

            //if (!utils.isNullOrEmpty(this.store.dataInfo.categorys)) {
            //    this.store.dataInfo.categorys = this.store.dataInfo.categorys.split(',');
            //}

            this.isEnd = true;

          
        }
    }

    getImageFileList = (images) => {
        let array = [];
        if (!images) {
            return array;
        }
        for (let i = 0; i < images.length; i++) {
            let item = images[i];
            if (item.action != 3) {
                array.push({ uid: i, id: (i + 1), status: 'done', url: item });
            }
        }
        return array;

    }

    save = async () => {
        var items = { ...this.store.dataInfo }
        const { buyId, service, logoImgs, intro, companyName, categorys } = items


        if (utils.isNullOrEmpty(companyName)) {
            message.error("请输入公司名称"); return;
        }

        //if (utils.isNullOrEmpty(name)) {
        //    message.error("请输入联系人姓名"); return;
        //}

        //if (utils.isNullOrEmpty(phone)) {
        //    message.error("请输入联系人手机"); return;
        //}
        //if (logoImgs.length==0) {
        //    message.error("请上传公司LOGO"); return;
        //}

        if (utils.isNullOrEmpty(items.pepType)) {
            items.pepType = "先生";
        }

        if (utils.isNullOrEmpty(service)) {
           
            message.error(`请选择${buyId == 2 ? '提供服务' :'采购需求'}`); return;
        }

        if (!utils.isNullOrEmpty(service)) {
            items.service = items.service.join(',');
        }

        //if (!utils.isNullOrEmpty(categorys)) {
        //    items.categorys = items.categorys.join(',');
        //}

        if (!utils.isNullOrEmpty(intro)) {
            items.intro = items.intro.toHTML().replaceAll("<p></p>","<br/>");
        } else {
            message.error("请输入简介"); return;
        }

        console.log("items", toJS(items));
        var res = await this.store.save_service(items);
        console.log("res", res);

        if (res.code == 0) {
            this.onLink();
        } else {
            message.error(res.msg); return;
        }
    }

    onLink = () => {
        const { buyId } = this.query
        if (buyId == 2) {
            this.props.history.push('../service/index')
        } else {
            this.props.history.push('../service/company')
        }
    }

 


    render() {
        const {  loading } = this.store
        const { editorType } = this.props;

        const { buyId } = this.query
        const { dataInfo } = this.store
        console.log("dataInfo", toJS(dataInfo));
        return (
            <>
                <PageHeader
                    title={editorType == constants.EDITOR_TYPE_ADD ? `添加${buyId == 2 ? '服务商' : '企业'}` : `修改${buyId == 2 ? '服务商' : '企业'}`}
                    onBack={() => {
                        this.onLink();
                    }}
                />

                <div className='product-single' >
                    <Spin spinning={loading} size='large'>
                        <Form className='product-single-form'>
                            <Form.Item style={{ marginBottom: 0 }} label="公司名称" name="companyName" rules={[{ required: true }]}>
                                <Form.Item>
                                    <Input style={{ width: '60%' }} name="companyName" value={dataInfo.companyName} onChange={e => dataInfo.companyName = e.target.value} />
                                </Form.Item>
                            </Form.Item>

                            
                            <Form.Item style={{ marginBottom: 0 }} label="英文名称" name="companyEnName">
                                <Form.Item>
                                    <Input style={{ width: '60%' }} name="companyEnName" value={dataInfo.companyEnName} onChange={e => dataInfo.companyEnName = e.target.value} />
                                </Form.Item>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label="联系人姓名" name="name" rules={[{ required: true }]}>
                                <Form.Item>
                                    <Input style={{ width: '50%' }} name="name" value={dataInfo.name} onChange={e => dataInfo.name = e.target.value} /> <Radio.Group style={{ marginLeft: '10px' } } onChange={(e) => {
                                        dataInfo.pepType = e.target.value;
                                     
                                    }} value={dataInfo.pepType}>
                                        <Radio value="先生">先生</Radio>
                                        <Radio value="女士">女士</Radio>
                                 
                                    </Radio.Group>

                              
                                </Form.Item>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label="联系人手机" name="phone" rules={[{ required: true }]}>
                                <Form.Item>
                                    <Input style={{ width: '60%' }} name="phone" value={dataInfo.phone} onChange={e => dataInfo.phone = e.target.value} />
                                </Form.Item>
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }} label="联系人邮箱" name="email">
                                <Form.Item>
                                    <Input style={{ width: '60%' }} name="email" value={dataInfo.email} onChange={e => dataInfo.email = e.target.value} />
                                </Form.Item>
                            </Form.Item>
                 
                            <Form.Item label="联系人职能" >
                                <Form.Item>
                                    <Select
                                        style={{ width: '60%' }}
                                        value={dataInfo.position}
                                        onChange={(value, options) => {
                                            dataInfo.position = value;
                                        }}
                                    >
                                            
                                        {staticData.positions.map((d) => {
                                            return (
                                                <Option value={d}>{ d}</Option>
                                                )
                                        })}
                                    
                                    </Select>
                                  

                                </Form.Item>
                            </Form.Item>
                            <Form.Item label={`${buyId == 2 ? '提供服务' : '采购需求'}`} rules={[{ required: true }]} name="service">
                                <Form.Item>
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        style={{ width: '60%' }}
                                        value={dataInfo.service}
                                        onChange={(value, options) => {
                               
                                            dataInfo.service = value 
                                        }}
                                    >
                                      
                                        {staticData.provideServices.map((d) => {
                                            return (
                                                <Option value={d}>{d}</Option>
                                            )
                                        })}

                                    </Select>


                                </Form.Item>

                            </Form.Item>

                            <Form.Item label={`${buyId == 2 ? '产品类别' : '行业类别'}`}>
                                <Form.Item>
                                    <Select
                                        mode="multiple"
                                        style={{ width: '60%' }}
                                        value={dataInfo.categorys ? (dataInfo.categorys.some(c => c.id) ? dataInfo.categorys.map((d) => { return d.id }) : dataInfo.categorys):[]}
                                        onChange={(value, options) => {

                                            dataInfo.categorys = options.map((d) => {
                                                return {
                                                    "id": d.value,
                                                    "cate": d.children
                                                }
                                            })
                                        }}
                                    >

                                        {buyId == 2 && staticData.service.map((d) => {
                                            return (
                                                <Option value={d.id}>{d.title}</Option>
                                            )
                                        })}

                                        {buyId == 3 && staticData.company.map((d) => {
                                            return (
                                                <Option value={d.title}>{d.title}</Option>
                                            )
                                        })}

                                    </Select>


                                </Form.Item>

                            </Form.Item>
                            {buyId == 2 &&
                                <Form.Item label="服务商级别">
                                    <Form.Item>
                                        <Select
                                           
                                        
                                            style={{ width: '60%' }}
                                            value={dataInfo.level}
                                            onChange={(value, options) => {
                                                console.log("value", value);
                                                dataInfo.level = value
                                            }}
                                        >

                                            <Option value={null}>请选择级别</Option>
                                            <Option value={1}>一级</Option>
                                            <Option value={2}>二级</Option>
                                            <Option value={3}>三级</Option>
                                          

                                        </Select>
                                    </Form.Item>
                                </Form.Item>
                            }

                            <Form.Item style={{ marginBottom: 0 }} label="显示联系资料" name="email">
                                <Form.Item>
                                    <Checkbox checked={dataInfo.isDisplay} onChange={(e) => {
                                        
                                        dataInfo.isDisplay = e.target.checked

                                    }} />
                                </Form.Item>
                            </Form.Item>

                            <Form.Item label="公司LOGO" rules={[{ required: true }]} name="logoImgs">
                                <Form.Item>
                                    <PicturesWall
                                        key={dataInfo.logoImgs}
                                        style={{ width: '20%', height: '100' }}
                                      fileList={this.getImageFileList(dataInfo.logoImgs)}
                                        defaultDomain={constants.IMAGE_MDD_DOMAIN}
                                        showPosterIcon={true}
                                        bucketName={constants.IMAGE_MDD_BUCKETNAME}
                                        onChange={(images) => {
                                            var that = this;
                                            that.store.dataInfo.logoImgs = [];

                                            images.forEach(function (img) {
                                                that.store.dataInfo.logoImgs.push(img.fileName);
                                            })

                                        }}
                                    />
                                </Form.Item>


                            </Form.Item>


                            <Form.Item label="简介">
                                
                                    <RichEditor placeholder="简介" value={dataInfo.intro}
                                        onChange={(value) => {
                                            dataInfo.intro = value;

                                        }}

                                        contentStyle={{ height: 800, boxShadow: 'inset 0 1px 3px rgba(0,0,0,.1)' }} />
                                
                            </Form.Item>

                          
                     

                            <div className='save-btnbox flex-center'>
                                <Button className='btn btn-primary' onClick={() => this.save()}>保存</Button>
                            
                                <Button className='btn btn-customize' onClick={() => {
                                    this.onLink();
                                }}>取消</Button>
                            </div>

                        </Form>
                    </Spin>
                </div>


              
            </>
        )
    }


 
   
}
import React, { Component } from 'react';
import { observable, toJS } from 'mobx';
import { observer } from "mobx-react"
import { Form, Button, Input, Modal, Row, Col, Checkbox, Badge, PageHeader, message, Descriptions, DatePicker, Spin, Select } from 'antd';
import { AntTable, PicturesWall, AntDescriptions } from '../../components'
import { SearchOutlined } from '@ant-design/icons';
import constants from '../../utils/constants'
import moment from 'moment'
import ActivityStore from '../../stores/activityStore';
import CategorySelectCmpt from '../components/categorySelect.cmpt'
const { Option } = Select;
export default @observer class ActivityListCmpt extends Component {
    store = new ActivityStore()
  
    static defaultProps = {
   
    }

    constructor(props) {
        super(props)

    }

    async UNSAFE_componentWillReceiveProps(props) {
     
    }

    async componentDidMount() {
        await this.store.fetch();
    }

    handleTableChange = async (pagination) => {
        try {
            await this.store.fetch(pagination)

        }
        catch (e) {
            message.error(e.message)
        }
    }

    handleSearch = async () => {
        const pagination = {
            ...this.store.pagination,
            current: 1,
        }

        try {
            await this.store.fetch(pagination)
        }
        catch (e) {
            message.error(e.message)
        }
    }



   

  
    render() {
        const { visible, onCancel } = this.props;
        const { filter, pagination, dataList, loading } = this.store
        return (
            <>
                <Modal
                    width='80%'
                    title="查询活动列表"
                    visible={visible}
                    onCancel={() => { this.props.onClose() }}
                    onOk={() => { this.props.onClose() }}
                >
                    


                    <div className='inner'>
                        <AntTable
                            dataSource={dataList}
                            loading={loading}
                            rowKey={record => record.code}
                            pagination={{
                                ...pagination,
                                showSizeChanger: true
                            }}
                            onChange={this.handleTableChange}
                            columns={[
                                {
                                    title: '活动名称',
                                    width: 150,
                                    ellipsis: true,
                                    dataIndex: 'name',

                                },

                                {
                                    title: '活动时间',
                                    width: 100,

                                    render: (text, record) => (
                                        moment(record.startDate).format('YYYY.MM.DD') + "-" + moment(record.endDate).format('YYYY.MM.DD')
                                    )
                                },
                                {
                                    title: '状态',
                                    width: 90,

                                    render: (text, record) => (
                                        <Badge
                                            color={record.isEditing ? 'yellow' : record.isPublish ? 'green' : '#d9d9d9'}
                                            text={record.isEditing ? '编辑中' : record.isPublish ? '已发布' : '未发布'}
                                        />
                                    ),
                                },
                                {
                                    title: '编辑人',
                                    width: 50,

                                    dataIndex: 'fullName',
                                },
                                {
                                    title: '创建时间',
                                    width: 100,

                                    render: (text, record) => (
                                        moment(record.createTime).format(constants.DEFAULT_DATETIME_FORMAT)
                                    ),
                                },
                                {
                                    title: '操作',
                                    width: 110,
                                    align: 'center',
                                    fixed: 'right',
                                    render: (text, record) => (
                                        <>
                                            <Button type='link' onClick={() => { this.props.onOk(record) }}>选择</Button>
                                          

                                        </>
                                    ),
                                },
                               
                            ]}
                        />
                    </div>

                </Modal>
            </>
            )
    }
}
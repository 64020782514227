import React, { Component } from 'react';
import { observable, toJS } from 'mobx';
import { observer } from "mobx-react"
import { Form, Button, Input, Modal, Row, Col, Checkbox, Badge, PageHeader, message, Descriptions, DatePicker, Spin, Select } from 'antd';
import { AntTable, PicturesWall, AntDescriptions } from '../../components'
import { SearchOutlined } from '@ant-design/icons';
import constants from '../../utils/constants'
import moment from 'moment'
import NewsStore from '../../stores/newsStore';
import CategorySelectCmpt from '../components/categorySelect.cmpt'
const { Option } = Select;
export default @observer class NewsListCmpt extends Component {
    store = new NewsStore()
  
    static defaultProps = {
   
    }

    constructor(props) {
        super(props)

    }

    async UNSAFE_componentWillReceiveProps(props) {
     
    }

    async componentDidMount() {
        await this.store.fetch();
    }

    handleTableChange = async (pagination) => {
        try {
            await this.store.fetch(pagination)

        }
        catch (e) {
            message.error(e.message)
        }
    }

    handleSearch = async () => {
        const pagination = {
            ...this.store.pagination,
            current: 1,
        }

        try {
            await this.store.fetch(pagination)
        }
        catch (e) {
            message.error(e.message)
        }
    }



   

  
    render() {
        const { visible, onCancel } = this.props;
        const { filter, pagination, dataList, loading } = this.store
        return (
            <>
                <Modal
                    width='80%'
                    title="查询新闻列表"
                    visible={visible}
                    onCancel={() => { this.props.onClose() }}
                    onOk={() => { this.props.onClose() }}
                >
                    <div>
                        <AntDescriptions>

                            <Descriptions.Item label="栏目" span={1}>
                                <CategorySelectCmpt style={{ width: '100%' }} multiple={false} placeholder="请选择" value={filter.columnId} onChange={(val, name) => { filter.columnName = name[0] }} />
                            </Descriptions.Item>
                            <Descriptions.Item label="关键字" span={1}>
                                <Input defaultValue={filter.title} onChange={e => filter.title = e.target.value} allowClear />
                            </Descriptions.Item>
                            <Descriptions.Item label="是否发布" span={1}>
                                <Select
                                    style={{ width: '100%' }}
                                    value={filter.isPublish}
                                    onChange={(value, options) => {
                                        filter.isPublish = value;
                                        
                                    }}
                                >
                                    <Option value={null}>请选择...</Option>
                                    <Option value={true}>是</Option>
                                    <Option value={false}>否</Option>
                                </Select> 
                            </Descriptions.Item>
                            
                        </AntDescriptions>
                        <Row>
                            <Col>
                                <Button type='primary' onClick={this.handleSearch} icon={<SearchOutlined />} loading={this.loading}>查询</Button>
                            </Col>
                        </Row>
                    </div>


                    <div className='inner'>
                        <AntTable
                            dataSource={dataList}
                            loading={loading}
                            rowKey={record => record.code}
                            pagination={{
                                ...pagination,
                                showSizeChanger: true
                            }}
                            onChange={this.handleTableChange}
                            columns={[
                                {
                                    title: '栏目',
                                    width: 150,
                                    align: 'center',
                                    dataIndex: 'columnName',

                                },

                                {
                                    title: '标题',
                                    width: 100,
                                    dataIndex: 'title',
                                },
                                {
                                    title: '标签',
                                    width: 100,
                                    render: (text, record) => (
                                        record.label && record.label.toString()
                                    ),
                                },
                                {
                                    title: '作者',
                                    width: 100,
                                    dataIndex: 'fullName',
                                },
                                {
                                    title: '创建时间',
                                    width: 100,
                                    render: (text, record) => (
                                        moment(record.createTime).format(constants.DEFAULT_DATETIME_FORMAT)
                                    ),
                                },
                                {
                                    title: '状态',
                                    width: 90,
                                    align: 'center',
                                    render: (text, record) => (
                                        <Badge
                                            color={record.isEditing ? 'yellow' : record.isPublish ? 'green' : '#d9d9d9'}
                                            text={record.isEditing ? '编辑中' : record.isPublish ? '已发布' : '未发布'}
                                        />
                                    ),
                                },
                                {
                                    title: '操作',
                                    width: 110,
                                    align: 'center',
                                    fixed: 'right',
                                    render: (text, record) => (
                                        <>
                                            <Button type='link' onClick={() => { this.props.onOk(record) }}>选择</Button>
                                          

                                        </>
                                    ),
                                },
                               
                            ]}
                        />
                    </div>

                </Modal>
            </>
            )
    }
}
import { observable, action, toJS } from "mobx"
import api from "../utils/api"
import utils from '../utils'

export default class ServiceStore {


    @observable imgInfo = {
        imgPath: []
    };


    @observable dataList = []
    @observable quoteds = [];
    @observable dataInfo = {
        isActivity: true,
        permissions: []
    }
    @observable loading = false

    @observable pagination = {
        current: 1,
        pageSize: 15,
        total: 0,
    }

    @observable filter = {
        title: '',
        serviceType: '',
        companyName: '',
        cate: null,
        maxPrice: null,
        minPrice: null,
        userId: null,
        enterpriseBuyId:null
    }


    @action.bound
    async fetch() {


        try {
            this.loading = true;

            const { current, pageSize } = this.pagination;

            var result = await api.service.gets({
                pageCount: current,
                pageSize,
                ...this.filter
            });

            this.dataList = result.items
            console.log("result", result);
        } catch (e) {
            throw e
        } finally {
            this.loading = false;
        }



    }
    @action.bound
    async del(id) {
        try {
            this.loading = true;
            var res = await api.service.del({ id: id });
            return res;

        } catch (e) {
            throw e
        } finally {
            this.loading = false;
        }
    }

    @action.bound
    async get(id) {
        try {
            this.loading = true;
            var res = await api.service.get({ id: id });
            console.log("res", toJS(res));
            this.dataInfo = res.info;

        } catch (e) {
            throw e
        } finally {
            this.loading = false;
        }
    }

    @action.bound
    async save_service(params) {
        try {
            this.loading = true;
            let res = await api.service.edit(params);
            return res;
        } catch (ex) {

        } finally {
            this.loading = false;
        }
    }


    @action.bound
    async save() {

        console.log("res", toJS(this.dataInfo));
        var res = await api.activityCategory.edit(this.dataInfo);

        return res;
    }



 

    @action.bound
    async fetch_demand(pagination) {
        pagination = {
            ...this.pagination,
            ...pagination,
        }

        try {
            this.loading = true;
            const { current, pageSize } = pagination;
            const {
                title,
                userId,
                companyName,
                maxPrice,
                minPrice,
                serviceType,
                enterpriseBuyId
            } = this.filter;
            console.log(" this.filter", toJS(this.filter));
            var result = await api.service.getsDemand({
                pageCount: current,
                pageSize,
                serviceType,
                title,
                userId,
                companyName,
                maxPrice,
                minPrice,
                enterpriseBuyId
            });

            console.log("result", result);
            this.pagination = {
                current,
                pageSize,
                total: result.totalResults
            }
            this.quoteds = result.items
        } catch (e) {
            throw e
        } finally {
            this.loading = false;
        }



    }

    @action.bound
    async fetch_purchare_detail(id) {
        try {
            this.loading = true;
            let res = await api.service.getPurchaseById({
                id: id
            });
            console.log("res", res);
            return res;

        } catch (e) {

        } finally {
            this.loading = false;
        }
    }



    

    @action.bound
    async fetch_quote(filter) {
        try {
            this.loading = true;
            var res = await api.service.getQuote(filter);
            console.log("fetch_quote", res);
            return res;
        } catch (ex) {

        } finally {
            this.loading = false;
        }
    }

    @action.bound
    async fetch_demand_detail(id) {
        try {
            this.loading = true;
            let res0 = await api.service.needGet({
                id: id
            });
            let res1 = await api.service.getMessageList({
                serviceID:id
            })

            let res = {
                ...res0,
                meg: res1.items
            }
            console.log("res", res);
            return res;
           



          
        } catch (e) {

        } finally {
            this.loading = false;
        }
    }

}
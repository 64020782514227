import React, { Component } from 'react';
import { Space, Button, Row, Avatar, Comment, Spin, PageHeader, Collapse, Select, Card, Menu, Empty  } from 'antd';
import { AntTable, AntDescriptions } from '../../components'
import { observable } from 'mobx';
import { observer } from "mobx-react"
import { Element } from 'react-scroll'
import { CaretRightOutlined } from '@ant-design/icons';
import moment from 'moment'
import constants from '../../utils/constants'
import serviceStore from '../../stores/serviceStore';
import qs from 'qs'
import staticData from '../../utils/staticData.json'
const { Option } = Select;
const { Panel } = Collapse;
export default @observer class Purchase extends Component {

    store = new serviceStore()
    @observable query = null;
    @observable datas = null
    constructor(props) {
        super(props)

        this.query = qs.parse(this.props.location.search.substr(1))
        console.log(" this.query ", this.query);
    }
    async componentDidMount() {

        let res = await this.store.fetch_demand_detail(this.query.id);

        this.datas = res;

    }





    render() {
        const { filter, pagination, dataList, loading } = this.store

        
        return (
            <>
                <PageHeader
                    title="采购详情"
                    onBack={() => {
                        this.props.history.push(`detail?id=${this.query.companyID}&service=${this.query.service}`)
                    }}
                />
                <Spin spinning={loading} size='large'>
                    <Menu mode="horizontal">
                        <Menu.Item onClick={() => {

                        }}>
                            采购详情
                    </Menu.Item>


                    </Menu>
                    {this.datas &&
                        <div className='product-single' >

                        <Card title={this.datas.companyName} extra={<a>{this.datas.info.currentState}</a>} style={{ width: "100%", marginTop: 15 }}>
                                <div style={{ color: 'grey', fontSize: 10 }}>
                                    需求类别
                                    </div>
                                <p>
                                {this.datas.info.serviceType}
                            </p>
                                <div style={{ color: 'grey', fontSize: 10 }}>
                                    预算
                                    </div>
                                <p style={{ color: 'red' }}>
                                {this.datas.info.amount} 元
                            </p>
                                <div style={{ color: 'grey', fontSize: 10 }}>
                                    需求标题
                                    </div>
                                <p>
                                {this.datas.info.title}
                            </p>
                                <div style={{ color: 'grey', fontSize: 10 }}>
                                    需求描述
                                    </div>
                                <p>
                                {this.datas.info.intro}
                            </p>

                            </Card>


                            <Menu mode="horizontal" style={{ marginBottom: 15 }}>
                                <Menu.Item onClick={() => {

                                }}>
                                    回复详情
                    </Menu.Item>


                            </Menu >
                                {this.datas.quotedPrices.length == 0 &&
                                    <Empty>
                                    </Empty>
                                }

                        {this.datas.quotedPrices.map((qp) => {
                            return (
                                <>
                                    <div className="space-align-container" style={{ backgroundColor: '#f5f5f5', width: "100%" }}>

                                        <div style={{ paddingTop: 15, width: "100 %", height: 130, paddingLeft: 20 }}>
                                            <div style={{ height: 80 }}>
                  

                                                <Avatar src={`${qp.company.logoImgs.length > 0 ? ('https://img10.yiqifei.com' + qp.company.logoImgs[0]) : ''}`} style={{ width: 50, height: 50, float: "left" }} />

                                                <div style={{ float: "left", marginLeft: 15 }}>
                                                    <p>{qp.company.companyName}</p>
                                                    <div>
                                                        <p style={{ float: "left" }}> 报价：</p><p style={{ float: "left", color: "red" }}>{qp.price} 元</p>
                                                    </div>
                                                </div>
                                                <div style={{ float: 'right', marginRight: 20 }}>
                                                    <Button onClick={() => {
                                                        this.props.history.push(`quote?id=${qp.serviceID}&service=${this.query.service}&companyID=${this.query.companyID}&buyId=${this.query.buyId}&back=purchase`)
                                                    }}>报价详情</Button>
                                                </div>


                                            </div>
                                            <div>
                                                {moment(qp.createTime).format("YYYY-MM-DD HH:mm")}
                                            </div>
                                        </div>


                                        {this.datas.meg.filter(c => c.quotedPricesID == qp.id).map(q => {
                                            return (

                                                <Comment style={{ backgroundColor: '#f5f5f5', paddingLeft: 20 }}
                                                    actions={[<span key="comment-nested-reply-to">{moment(q.createTime).format("YYYY-MM-DD HH:mm")}</span>]}
                                                    author={<a>{q.companyName}</a>}
                                                    content={
                                                        <p>
                                                            {q.replyName != "" &&
                                                                <>
                                                                回复 <a> {q.replyName} </a>
                                                                </>
                                                            }
                                                            {q.text}
      </p>
                                                    }
                                                >
                                                </Comment>
                                                )
                                        })}

                                    </div>

                                    </>
                                )
                        })}
                           

                        </div>
                    }
                </Spin>


            </>
        )
    }
}
import { observable, action, toJS } from "mobx"
import api from "../utils/api"
import constants from "../utils/constants"
import utils from '../utils'

export default class SurveyStore {

    @observable loading = false;

    @observable pagination = {
        current: 1,
        pageSize: 15,
        total: 0,
    }

    @observable dataList = [];
    @observable filter = {

    }

   
}
import React, { Component } from 'react';
import { Form, Button, Input, Row, Col, Checkbox, Text, PageHeader, Descriptions, Select, DatePicker , Modal, Badge, message } from 'antd';
import { AntTable, AntDescriptions } from '../../components'
import { observable, toJS } from 'mobx';
import { observer } from "mobx-react"
import { SearchOutlined } from '@ant-design/icons';
import { Element } from 'react-scroll'
import moment from 'moment'
import ExportJsonExcel from "js-export-excel"
import constants from '../../utils/constants'
import cardStore from '../../stores/cardStore';
const { Option } = Select;
const { RangePicker } = DatePicker;
export default @observer class surveyData extends Component {
    store = new cardStore();

    @observable cardVisible = false

    @observable cardModify = {
        visible: false,
        edit: 'add'
    }

    @observable flag = 1;
    async componentDidMount() {
        await this.store.fetch_survey();
    }


    handleTableChange = async (pagination) => {
        try {
            await this.store.fetch_survey(pagination)

        }
        catch (e) {
            message.error(e.message)
        }
    }

    handleSearch = async () => {
        const pagination = {
            ...this.store.pagination,
            current: 1,
        }

        try {
            await this.store.fetch(pagination)
        }
        catch (e) {
            message.error(e.message)
        }
    }

    async save() {
        this.cardModify.visible = false;
        var res= await this.store.saveCard();
        if (res.code == 0) {
            await this.store.fetch();
            message.success("操作成功");
        }

     
    }

    handleDel = async (id) => {
        var res = await this.store.cardDel(id);
        console.log("res", res);
        if (res.code == 0) {
            message.success("删除成功");
            await this.store.fetch();
        } else {
            message.error("删除失败")

        }

    }

    handleSearch = async () => {
        const pagination = {
            ...this.store.pagination,
            current: 1,
        }
        await this.store.fetch_survey(pagination, false);
    }

    handleExcel = async () => {

        const pagination = {
            current: 1,
            pageSizeExcel: 9999
        }
        await this.store.fetch_survey(pagination, true);
        let option = {};
        if (this.store.excelData.length == 0) {
            message.error("没有相关数据，导出数据失败"); return;
        }
        this.store.excelData.forEach(e => {
            let jsonData = JSON.parse(e.content);
            e.dests = jsonData.dests.map((t, i) => t + (jsonData.dests.length > i + 1 ? ',' : ''));
            e.consume = jsonData.consume;
            if (this.flag == 2) {
                e.remark = e.remark;
                e.gloaItems = jsonData.gloaItems.map((t, i) => t + (jsonData.gloaItems.length > i + 1 ? ',' : ''));
                e.xuqiu = jsonData.xuqiu.map((t, i) => t + (jsonData.xuqiu.length > i + 1 ? ',' : ''));
                e.xuqiucp = jsonData.xuqiucp.map((t, i) => t + (jsonData.xuqiucp.length > i + 1 ? ',' : ''));
            }
           
            e.hs = jsonData.hs.map((t, i) => t + (jsonData.hs.length > i + 1 ? ',' : ''))
            if (this.flag == 1) {
                e.isPluralism = jsonData.isPluralism == 1 ? '是' : '否';
            }
            e.createTime = moment(e.createTime).format(constants.DEFAULT_DATETIME_FORMAT);
        });  //数据源
        option.fileName = "问卷表" + moment().format('YYYYMMDDHHmmss');  //excel文件名称

        if (this.flag == 1) {
            option.datas = [
                {
                    sheetData: this.store.excelData,
                    sheetName: '问卷表',
                    sheetFilter: ['companyName', 'companyForName', 'phone', 'email', 'cardNo', 'dests', 'consume', 'hs', 'isPluralism', 'createTime'],
                    sheetHeader: ['企业名称', '企业商旅联系人', '手机', '邮箱', '卡号', '出差目的地', '平均每月消费金额', '航司', '有没专职或 兼职的差旅成本管控经理', '提交时间'],
                }
            ]
        } else {
            option.datas = [
                {
                    sheetData: this.store.excelData,
                    sheetName: '问卷表',
                    sheetFilter: ['companyName', 'companyForName', 'phone', 'email', 'cardNo', 'dests', 'gloaItems', 'gloaItems', 'hs', 'xuqiu', 'xuqiucp','remark','createTime'],
                    sheetHeader: ['企业名称', '企业商旅联系人', '手机', '邮箱', '卡号', '出差目的地', '国际差旅', '采购金额', '航空公司', '采购需求', '商旅产品', '其他采购需求','提交时间'],
                }
            ]
        }
        let toExcel = new ExportJsonExcel(option);  //生成excel文件
        toExcel.saveExcel();  //下载excel文件
    }

    render() {
        const { filter, loading, dataList, pagination, dataInfo, surveyFilter } = this.store

   
        console.log("dataList", toJS(dataList));
        return (
            <>
                <PageHeader title='问卷列表' style={{ paddingBottom: 0 }} extra={<div className='supplierbox'>
                    <div className={this.flag == 1 ? 'item active' : 'item'} onClick={async () => {
                        this.flag = 1;
                        this.store.surveyFilter.types = null;
                        this.handleSearch();

                    }}>问卷（1）</div>
                    <div className={this.flag == 2 ? 'item active' : 'item'} onClick={async () => {
                        this.flag = 2;
                        this.store.surveyFilter.types = 2;
                        this.handleSearch(); 

                    }}>问卷（2）</div>
                </div>
                } />
              

                <div className='filter-box filter-box-title-110'>
                     
                    <AntDescriptions>

                        <Descriptions.Item label="时间" span={1}>
                            <RangePicker
                                format="YYYY-MM-DD HH:mm"
                                showTime
                                defaultValue={surveyFilter.createDateRange}
                                onChange={value => surveyFilter.createDateRange = value}
                                style={{ width: '100%' }}
                            />

                         
                        </Descriptions.Item>

                        <Descriptions.Item label="类型" span={1}>
                            <Select
                                style={{ width: '100%' }}
                                value={surveyFilter.classVal}
                                onChange={(value, options) => {
                                    surveyFilter.classVal = value

                                }}
                            >
                                <Option value={null}>请选择...</Option>
                                <Option value="VIP0">VIP0</Option>
                                <Option value="VIP1">VIP1</Option>
                                <Option value="VIP2">VIP2</Option>
                                <Option value="VIP3">VIP3</Option>
                            </Select>


                        </Descriptions.Item>
                        <Descriptions.Item label="标签" span={1}>
                            <Select
                                style={{ width: '100%' }}
                                value={surveyFilter.lable}
                                onChange={(value, options) => {
                                    surveyFilter.lable = value

                                }}
                            >
                                <Option value={null}>请选择...</Option>
                                <Option value="GDHR">GDHR</Option>
                                <Option value="SOA">SOA</Option>

                            </Select>

                        </Descriptions.Item>

                    </AntDescriptions>

                    <Row>
                        <Col>
                            <Button type='primary' onClick={() => {
                                this.handleExcel()
                            }}>导出Excel</Button>
                        </Col>
                        <Col flex={1} />
                        <Col>
                           
                            <Button type='primary' onClick={this.handleSearch} icon={<SearchOutlined />} loading={this.loading} style={{ marginRight: '20px' }}>查询</Button>
                   
                        </Col>
                    </Row>

                  
                </div>

                {this.flag ==1?

                    <Element name="scrollElement">
                        <AntTable
                            dataSource={dataList}
                            loading={loading}
                            rowKey={record => record.code}
                          
                            pagination={{
                                ...pagination,
                                showSizeChanger: true
                            }}
                            onChange={this.handleTableChange}
                            columns={[
                                {
                                    title: '卡号',
                                    width: 100,
                                    ellipsis: true,
                                    dataIndex: 'cardNo',
                                },
                                {
                                    title: '企业名称',
                                    width: 100,
                                    ellipsis: true,
                                    dataIndex: 'companyName',

                                },

                                {
                                    title: '企业联系人',
                                    width: 70,
                                    ellipsis: true,
                                    dataIndex: 'companyForName',
                                },
                                {
                                    title: '手机号码',
                                    width: 80,
                                    dataIndex: 'phone',
                                },

                                {
                                    title: '邮箱',
                                    width: 80,
                                    ellipsis: true,
                                    dataIndex: 'email',
                                },
                                {
                                    title: '目的地',
                                    width: 120,
                                    ellipsis: true,
                                    render: (text, record) => (
                                        <>
                                            {JSON.parse(record.content).dests.join(",")}
                                        </>
                                    ),
                                },
                                {
                                    title: '消费金额',
                                    width: 100,
                                    ellipsis: true,
                                    render: (text, record) => (
                                        <>
                                            {JSON.parse(record.content).consume}

                                        </>
                                    ),
                                },

                                {
                                    title: '航空公司',
                                    width: 100,
                                    ellipsis: true,
                                    render: (text, record) => (
                                        <>
                                            {JSON.parse(record.content).hs.join(",")}
                                        </>
                                    ),
                                },
                                {
                                    title: '兼职',
                                    width: 50,
                                    dataIndex: '',
                                    render: (text, record) => (
                                        <Badge
                                            color={record.isEditing ? 'yellow' : JSON.parse(record.content).isPluralism == 1 ? 'green' : '#d9d9d9'}
                                            text={record.isEditing ? '编辑中' : JSON.parse(record.content).isPluralism == 1 ? '是' : '否'}
                                        />
                                    ),
                                },
                            ]}
                        />
                    </Element> :


                    <Element name="scrollElement">
                        <AntTable
                            dataSource={dataList}
                            loading={loading}
                            rowKey={record => record.code}
                            autoHeight="true"
                            pagination={{
                                ...pagination,
                                showSizeChanger: true
                            }}
                            onChange={this.handleTableChange}
                            columns={[
                                {
                                    title: '卡号',
                                    width: 80,
                                    ellipsis: true,
                                    dataIndex: 'cardNo',
                                },
                                {
                                    title: '企业名称',
                                    width: 120,
                                    ellipsis: true,
                                    dataIndex: 'companyName',

                                },

                                {
                                    title: '企业联系人',
                                    width: 80,
                                    ellipsis: true,
                                    dataIndex: 'companyForName',
                                },
                                {
                                    title: '手机号码',
                                    width: 100,
                                    dataIndex: 'phone',
                                },

                                {
                                    title: '邮箱',
                                    width: 80,
                                    ellipsis: true,
                                    dataIndex: 'email',
                                },
                                {
                                    title: '目的地',
                                    width: 100,
                                    ellipsis: true,
                                    render: (text, record) => (
                                        <>
                                            {JSON.parse(record.content).dests.map((d) => {
                                                return (
                                                    <div>
                                                        {d}
                                                    </div>
                                                )
                                            })}

                                        </>
                                    ),
                                },

                                {
                                    title: '国际差旅',
                                    width: 90,
                                    ellipsis: true,
                                    render: (text, record) => (
                                        <>
                                            {JSON.parse(record.content).gloaItems && JSON.parse(record.content).gloaItems.map((d) => {
                                                return (
                                                    <div>
                                                        {d}
                                                    </div>
                                                )
                                            })}

                                        </>
                                    ),
                                },
                                {
                                    title: '采购金额',
                                    width: 90,
                                    ellipsis: true,
                                    render: (text, record) => (
                                        <>
                                            {JSON.parse(record.content).consume}

                                        </>
                                    ),
                                },

                                {
                                    title: '航空公司',
                                    width: 90,
                                    ellipsis: true,
                                    render: (text, record) => (
                                        <>
                                            {JSON.parse(record.content).hs.map((d) => {
                                                return (
                                                    <div>
                                                        {d}
                                                    </div>
                                                )
                                            })}

                                        </>
                                    ),
                                },
                                {
                                    title: '采购需求',
                                    width: 90,
                                    ellipsis: true,
                                    render: (text, record) => (
                                        <>
                                            {JSON.parse(record.content).xuqiu && JSON.parse(record.content).xuqiu.map((d) => {
                                                return (
                                                    <div>
                                                        {d}
                                                    </div>
                                                )
                                            })}

                                        </>
                                    ),
                                },
                                {
                                    title: '商旅产品',
                                    width: 90,
                                    ellipsis: true,
                                    render: (text, record) => (
                                        <>
                                            {JSON.parse(record.content).xuqiucp && JSON.parse(record.content).xuqiucp.map((d) => {
                                                return (
                                                    <div>
                                                        {d}
                                                    </div>
                                                )
                                            })}

                                        </>
                                    ),
                                },
                                {
                                    title: '其他采购需求',
                                    width: 100,
                                    ellipsis: true,
                                    dataIndex: 'remark',
                                },
                            ]}
                        />
                    </Element>
                }


                <Modal width='30%'
                    title={this.cardModify.edit == 'add' ? '添加' : '编辑'}
                    visible={this.cardModify.visible}
                    onCancel={() => { this.cardModify.visible = false }}
                    onOk={() => { this.save() }}>

                    <div>
                      
                            <Form className='product-single-form'>
                                <Form.Item label="卡类型" >
                                    <Select
                                        style={{ width: '50%' }}
                                    value={dataInfo.class}
                                    onChange={(value, options) => {
                                        dataInfo.class = value

                                        }}
                                    >
                                        <Option value={null}>请选择...</Option>
                                        <Option value="1">卡类型1</Option>
                                        <Option value="2">卡类型2</Option>

                                    </Select>

                                </Form.Item>

                                <Form.Item label="卡标签" >
                                    <Select
                                        style={{ width: '50%' }}
                                    value={dataInfo.lable}
                                    onChange={(value, options) => {
                                        dataInfo.lable = value

                                        }}
                                    >
                                        <Option value={null}>请选择...</Option>
                                        <Option value="GDHR">GDHR</Option>


                                    </Select>

                                </Form.Item>

                                <Form.Item label="是否激活" >
                                    <Checkbox checked={dataInfo.isActivation} onChange={(e) => { dataInfo.isActivation = e.target.checked }} />
                            </Form.Item>
                            {this.cardModify.edit == 'add' &&
                                <Form.Item label="是否批量" >
                                    <div>
                                        <Checkbox checked={dataInfo.isPl} onChange={(e) => { dataInfo.isPl = e.target.checked }} />
                                    </div>
                                </Form.Item>
                            }
                                {dataInfo.isPl &&
                                <Form.Item label="批量数" >

                                <Select
                                    style={{ width: '50%' }}
                                    value={dataInfo.num}
                                    onChange={(value, options) => {
                                        dataInfo.num = parseInt(value)

                                    }}
                                >
                                    <Option value={1}>请选择...</Option>
                                    <Option value="5">5</Option>
                                    <Option value="10">10</Option>
                                    <Option value="15">15</Option>
                                    <Option value="20">20</Option>
                                    <Option value="25">25</Option>

                                </Select>
                                  
                                    </Form.Item>
                                }

                                
                            </Form>
                       
                    </div>

                </Modal>
            </>
        )
    }
}
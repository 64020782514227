import React, { Component } from 'react';
import { Form, Button, Input, Row, Col, Select, Popconfirm, PageHeader, Radio, Modal, Divider, message } from 'antd';
import { AntTable, AntDescriptions } from '../../components'
import { observable, toJS } from 'mobx';
import { observer } from "mobx-react"
import { Element } from 'react-scroll'
import { SearchOutlined } from '@ant-design/icons';
import stores, { news } from '../../stores'
import moment from 'moment'
import utils from '../../utils';
import constants from '../../utils/constants'
import CategoryStore from '../../stores/categoryStore';
import NewsStore from '../../stores/newsStore';

import columnsStyleImg_1 from '../../assets/image/kuaixun.png'
import columnsStyleImg_2 from '../../assets/image/shouyeyaowen.png'
import columnsStyleImg_3 from '../../assets/image/hezuozhongx.png'
const { Option } = Select;
export default @observer class Category extends Component {

    store = new CategoryStore()

    newsStore = new NewsStore()
    @observable categoryListCreateFormParms = {
        type: '',
        visible: false,
        parentRecord: null,
        modifyRecord: null,
        categoryId:null
    }

    @observable columnStype = 1;

    @observable moveCategory = [];

    @observable categoryDelsParms = {

    }

    async componentDidMount() {
        await this.store.fetch();
    }

    categoryModalCreate(values) {

        const { parentRecord, type, modifyRecord } = this.categoryListCreateFormParms
        if (type == 'add') {
            if (utils.isNullOrEmpty(parentRecord)) {
                this.store.categoryInfo = values;
            } else {
                values.categoryID = utils.guid();
                this.store.categoryInfo = this.getParentCategory(parentRecord);
                if (!utils.isNullOrEmpty(this.store.categoryInfo.children)) {
                    for (var item of [this.store.categoryInfo]) {
                        this.categoryChildrens(parentRecord.categoryID, item, values);
                      
                    }
                } else {
                    parentRecord.children = [];
                    parentRecord.children.push({ ...values });
                    this.store.categoryInfo = parentRecord;
                }

            }
        } else {
            this.store.categoryInfo = this.getParentCategory(modifyRecord);
           
            if (this.store.categoryList.some(c => c.categoryID == modifyRecord.categoryID)) {
             //   console.log("modifyRecord", modifyRecord);
                this.store.categoryInfo = this.store.categoryList.find(c => c.categoryID == modifyRecord.categoryID)
                this.store.categoryInfo.categoryName = values.categoryName;
                this.store.categoryInfo.columnStype = values.columnStype;
                this.store.categoryInfo.icont = values.icont;
                this.store.categoryInfo.isNewCategoty = values.isNewCategoty;
                this.store.categoryInfo.externalLink = values.externalLink;
            } else {
                if (!utils.isNullOrEmpty(this.store.categoryInfo.children)) {
                    for (var item of [this.store.categoryInfo]) {
                        this.categoryChildrens(modifyRecord.categoryID, item, values,"modify");
                    }
                } 
            }
        }
      
        this.categoryListCreateFormParms.visible = false
        console.log(" this.store.categoryInfo", this.store.categoryInfo);
       
        this.save();
    }


    categoryChildrens(categoryID, item, values, type = 'add') {
    
        let flag = false;
        if (item.categoryID == categoryID) {
            if (type == 'add') {
                if (utils.isNullOrEmpty(item.children)) {
                    item.children = [];
                }
                item.children.push({ ...values });
            } else {
                item.categoryName = values.categoryName;
                item.isNewCategoty = values.isNewCategoty;
                item.columnStype = values.columnStype;
                item.icont = values.icont;
                item.externalLink = values.externalLink;
            }
            flag = true;
        } else {
            if (item.children != null) {
                for (var it of item.children) {
                    this.categoryChildrens(categoryID, it, values, type);
                }
            }
        }

        console.log("flag", flag);

        if (flag) return;





    }


    getParentCategory(info) {
        let categoryID = null;
        this.store.categoryList.forEach((item) => {
            //带有子集菜单children
            if (!utils.isNullOrEmpty(item.children) && item.children.length>0) {
                this.childrens(item.children, item.categoryID, info.categoryID);
                categoryID = this.categoryListCreateFormParms.categoryId;
              
            }
        })

      
        if (categoryID != null) {
          
            return this.store.categoryList.find(c => c.categoryID == categoryID);
        } else {
            return this.store.categoryList.find(c => c.categoryID == info.categoryID);
        } 
    }

    childrens(array, bic, id) {
        let flag = false;
        for (let item of array) {
            if (item.categoryID == id) {
                flag = true;
            } 
        }
        if (flag) {
            this.categoryListCreateFormParms.categoryId = bic;
        } else {
            for (let item of array) {
                if (item.children != null) {
                    this.childrens(item.children, bic, id);
                }
            }
        }
    }

    delChildrens = async (record) => {
        var category = this.getParentCategory(record);
        console.log("record", toJS(record));
        let that = this;
        if (this.store.categoryList.some(c => c.categoryID == record.categoryID)) {
            this.store.categoryList = this.store.categoryList.filter(c => c.categoryID != record.categoryID);
            this.store.categoryInfo = record;
            this.del();
            return;
        } else {
            this.store.categoryList.forEach((item) => {
                if (item.children != null) {
                    that.delChildrensAgen(item, item.children, record.categoryID);
                }
            })
        }
        console.log("this.store.categoryInfo ", toJS(this.store.categoryInfo));
        this.save();
        this.categoryListCreateFormParms.categoryId = null;
        
    }

    delChildrensAgen(category,childrens, categoryID) {
        let that = this;
        if (childrens.some(c => c.categoryID == categoryID)) {
            childrens.remove(childrens.find(c => c.categoryID == categoryID));
            this.store.categoryInfo = category;
           
            return;
        }
        childrens.forEach((item) => {
            if (item.children != null) {
                that.delChildrensAgen(category,item.children, categoryID);
            }
        })
    }

    del = async () => {
        try {
            var res = await this.store.del();
            if (res.code == 0) {
                message.success("保存成功");
                this.store.categoryInfo = {};
                await this.store.fetch();
            } else {
                message.error(res.msg);
            }
        }
        catch (e) {
            message.error(e.message)
        }
    }

    save = async () => {
        try {
            var res = await this.store.save();
            if (res.code == 0) {
                message.success("保存成功");
                this.store.categoryInfo = {};
                this.categoryListCreateFormParms.categoryId = null;
                await this.store.fetch();
            } else {
                message.error(res.message);
            }
        }
        catch (e) {
            message.error(e.message)
        }
    }

    moveMenu = (record,me) => {
        let parent = this.getParentCategory(record);
        let categoryItem = this.store.categoryList.find(c => c.categoryID == parent.categoryID);
        this.moveChildrens(categoryItem.children, record.categoryID, me);
        this.store.categoryInfo = categoryItem;
        console.log(" this.store.categoryInfo ", toJS(this.store.categoryInfo));
        this.save();

    }

  

    moveChildrens(array,id,me) {
        if (array.some(c => c.categoryID == id)) {
            let idx = array.findIndex(c => c.categoryID == id);
            console.log("idx", idx);
            if (me == 'up') {
                if (idx != 0) {
                    this.arraySwap(array, idx - 1, idx);
                }
            } else {
                if (idx != array.length - 1) {
                    this.arraySwap(array, idx + 1, idx);
                }
            }
        } else {
            for (let item of array) {
                if (item.children != null) {
                    this.moveChildrens(item.children, id, me);
                }
            }
        }
    }

   

    arraySwap(array, first, second) {
        var tmp = array[second];
        array[second] = array[first];
        array[first] = tmp;
        return array;
    }

    handleNews = (record) => {
        console.log("record", record);

        if (record.addSuccess) {
            this.props.history.push(`/news/news-editor/modify?categoryID=${record.categoryID}&categoryName=${record.categoryName}`);
        } else {
            this.props.history.push(`/news/news-editor/add?categoryID=${record.categoryID}&categoryName=${record.categoryName}`);
        }
    }

    //moveVisible = (record, me) => {
    //    console.log(toJS(record));
    //    let parent = this.getParentCategory(record);
    //    let visible = false;
    //    let categoryItem = this.store.categoryList.find(c => c.categoryID == parent.categoryID);
    //    if (me == 'up') {
    //        if (categoryItem.children != null) {
    //            let move= this.moveChildrensVisible(categoryItem.children, record.categoryID, me);
    //            if (move == 0) {
    //                visible = true;
    //            }
    //        }
    //    }

    //    return visible;
    //}


    render() {

  
        const {  categoryList, loading } = this.store
        return (
            <>
                <div className='filter-box filter-box-title-110'>
                    <PageHeader
                        title='栏目管理'
                        style={{ paddingBottom: 0 }}
                    />
                    <div className='filter-box filter-box-title-110'>
                        <Row>
                            <Col>
                                <Button type='primary' onClick={() => {
                                    this.categoryListCreateFormParms.visible = true
                                    this.categoryListCreateFormParms.type = "add"
                                }}>添加顶级栏目</Button>
                            </Col>


                        </Row>
                    </div>
                    <Element name="scrollElement">
                        {categoryList.length>0  &&
                            <AntTable
                            dataSource={toJS(categoryList)}
                            loading={loading}
                            size='small'
                            rowKey={record => record.categoryID}
                            
                             
                                expandable={{ defaultExpandAllRows: true }}
                                pagination={{
                                    defaultPageSize: 9999,
                                    hideOnSinglePage: true,
                                }}
                                columns={[
                                    {
                                        title: '类别名称',
                                        dataIndex: 'categoryName',
                                        editable: true,
                                        required: true,
                                        width: 300,
                                        ellipsis: true,
                                        render: (text, record) => {
                                            return text || '(未命名)'
                                        },
                                    },
                                    {
                                        title: '是否资讯类别',
                                        dataIndex: 'isNewCategory',
                                        editable: true,
                                        width: 300,
                                        ellipsis: true,
                                    },
                                    {
                                        title: '操作',
                                        width: 290,
                                        align: 'center',
                                        fixed: 'right',
                                        render: (text, record) => (
                                            <>

                                                <Button
                                                    disabled={!record.isNewCategoty}
                                                    type='link'
                                                    onClick={() => this.handleNews(record)}

                                                >{`${record.addSuccess ? '编辑' :'添加'}`}新闻</Button>
                                              
                                                <Divider type="vertical" />
                                                <Button
                                                    type='link'
                                                    onClick={() => {
                                                        this.categoryListCreateFormParms.type = 'add'
                                                        this.categoryListCreateFormParms.parentRecord = record
                                                        this.categoryListCreateFormParms.visible = true
                                                       
                                                        console.log("record", toJS(record));
                                                    }}
                                                 
                                                >添加子栏目</Button>
                                                <Divider type="vertical" />
                                                <Button
                                                    type='link'
                                                    onClick={() => {
                                                        this.categoryListCreateFormParms.type = 'modify'
                                                        this.categoryListCreateFormParms.modifyRecord = record
                                                        this.categoryListCreateFormParms.visible = true
                                                        console.log(toJS(this.categoryListCreateFormParms));
                                                    }}
                                                >修改</Button>
                                                <Divider type="vertical" />
                                                <Popconfirm disabled={record.children != null && record.children.length > 0} title={record && `确定要删除“${record.categoryName}”吗?`} onConfirm={() => { this.delChildrens(record) }}>
                                                    <Button type='link' disabled={record.children != null && record.children.length>0}>删除</Button>
                                                </Popconfirm>
                                                <Divider type="vertical" />
                                                <Button
                                                    type='link'
                                                    onClick={() => this.moveMenu(record,'up')}
                                                
                                                >上移</Button>
                                                <Divider type="vertical" />
                                                <Button
                                                    type='link'
                                                    onClick={() => this.moveMenu(record,'down')}
                                                  
                                                >下移</Button>

                                            </>
                                        )
                                    },
                                ]}
                            />
                        }
                    </Element>

                    {this.categoryListCreateFormParms.visible &&
                        <Modal
                        visible={this.categoryListCreateFormParms.visible}
                        title={this.categoryListCreateFormParms.type == 'add' ? '添加栏目' : '编辑栏目'}
                            mask
                            maskClosable={false}
                            onCancel={() => {
                                this.form.resetFields()

                                this.categoryListCreateFormParms.visible = false;
                            }}
                            onOk={() => {
                                this.form
                                    .validateFields()
                                    .then(values => {
                                        console.log("values", values);
                                        this.form.resetFields()
                                        this.categoryModalCreate(values);
                                    })
                                    .catch(info => {
                                        message.error("Form表单错误:" + info);
                                    });
                            }}
                        >
                            <Form
                                layout="vertical"
                            initialValues={this.categoryListCreateFormParms.type == 'add' ? null : this.categoryListCreateFormParms.modifyRecord}
                                name="form_in_modal"
                                ref={ref => this.form = ref}
                            >
                                <Form.Item
                                    name="categoryName"
                                    label="栏目名称"
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                        },
                                    ]}
                                >
                                    <Input maxLength={20} />
                            </Form.Item>

                            <Form.Item
                                name="isNewCategoty"
                                label="新闻详情"

                            >
                                <Select
                                    style={{ width: '100%' }}
                                >
                                    <Option value={null}>否</Option>
                                    <Option value={true}>是</Option>
                                </Select> 
                            </Form.Item>

                            <Form.Item label="外链地址"
                                name="externalLink">
                                <Input  placeholder="添加了外链列表内容优先跳转外链地址"/>
                            </Form.Item>

                            <Form.Item label="手机端图标"
                                name="icont">
                                <Input placeholder="手机端导航图标" />
                            </Form.Item>

                            <Form.Item
                                label="列表样式"
                                name="columnStype"
                            >

                                <Radio.Group onChange={(val) => {
                                    this.columnStype = val.target.value;
                                }} value={this.columnStype}>
                                    <Radio value={1}>样式1</Radio>
                                    <Radio value={2}>样式2</Radio>
                                    <Radio value={3}>样式3</Radio>
                                </Radio.Group>

                            </Form.Item>



                            <Form.Item>
                                {this.render_img()}
                            </Form.Item>

                            </Form>
                        </Modal>
                    }

                </div>

             
            </>
        )
    }

    render_img = () => {
        switch (this.columnStype) {
            case 3:
                return (
                    <img src={columnsStyleImg_3} />
                )
            case 2:
                return (
                    <img src={columnsStyleImg_2} />
                )
            default:
                return (
                    <img src={columnsStyleImg_1} />
                )
        }
    }
}
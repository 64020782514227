import React, { Component } from 'react';
import { Form, Button, Input, Row, Col, Checkbox, Alert, PageHeader, message, InputNumber, Modal, Spin, Select   } from 'antd';
import { AntTable, PicturesWall, RichEditor } from '../../components'
import { observable, toJS } from 'mobx';
import { observer } from "mobx-react"
import { Element } from 'react-scroll'
import { SaveOutlined } from '@ant-design/icons';
import stores, { news } from '../../stores'
import constants from '../../utils/constants'
import BraftEditor from 'braft-editor'
import NewsStore from '../../stores/newsStore'
import '../base-editor.scss'
import qs from 'qs'
import 'braft-editor/dist/index.css'
import givestar from '../../assets/image/givestar.png'
import CategoryCmpt from '../components/category.cmpt'
import CategorySelectCmpt from '../components/categorySelect.cmpt'
import ActivityCmpt from '../components/activity.cmpt'
import utils from '../../utils';
import { CloseCircleFilled } from '@ant-design/icons';
const { TextArea } = Input;
const { Option } = Select;
export default @observer class NewsEditor extends Component {

    store = new NewsStore()
    @observable labelName = ""
    @observable activityVisible = false
    @observable activityChecked = false;

    @observable ordersZm = [{ key: "A", val: 1 }, { key: "B", val: 2 }, { key: "C", val: 3 }, { key: "D", val: 4 }, { key: "E", val: 5 }, { key: "F", val: 6 }, { key: "G", val: 7 }, { key: "H", val: 8 }, { key: "I", val: 9 }, { key: "J", val: 10 }, { key: "K", val: 11 }, { key: "L", val: 12 }, { key: "M", val: 13 }, { key: "N", val: 14 }, { key: "O", val: 15 }, { key: "P", val: 16 }, { key: "Q", val: 17 }, { key: "R", val: 18 }, { key: "S", val: 19 }, { key: "T", val: 20 }, { key: "U", val: 21 }, { key: "V", val: 22 }, { key: "W", val: 23 }, { key: "X", val: 24 }, { key: "Y", val: 25 }, { key: "Z", val: 26 }];
    constructor(props) {
        super(props)
        this.query = qs.parse(props.location.search.substr(1))
    }

    async componentDidMount() {
        
        try {

            console.log("this.query", this.query);
            if (!utils.isNullOrEmpty(this.query.categoryID)) {
                this.store.dataInfo.columnId = this.query.categoryID;
            }
            
            await this.store.gets(this.query.id);

            if (!utils.isNullOrEmpty(this.query.categoryName)) {
                this.store.dataInfo.columnName = this.query.categoryName;
            }

            if (!utils.isNullOrEmpty(this.store.dataInfo.content)) {
                this.store.dataInfo.content = BraftEditor.createEditorState(this.store.dataInfo.content);
            }

            if (utils.isNullOrEmpty(this.store.dataInfo.logoImages)) {
                this.store.dataInfo.logoImages = [];
            }



        } catch (e) {
            message.error(e.message)
        }
    }



    publish = () => {
        this.store.dataInfo.isPublish = true;
        this.save();
    }

    save = async () => {
     
        let { dataInfo } = this.store; 
        const user = stores.session.user


        if (utils.isNullOrEmpty(this.store.dataInfo.title)) {
            message.error("请输入标题"); return;
        }
        dataInfo.accountNo = user.accountNo;
        dataInfo.fullName = user.fullName;
        if (!utils.isNullOrEmpty(dataInfo.content)) {
            dataInfo.content = dataInfo.content.toHTML();
        }
        dataInfo.orderBy = parseInt(dataInfo.orderBy);
       
        console.log("dataInfo", toJS(dataInfo));
        var res = await this.store.save();
        if (res.code == 0) {
            message.success("操作成功");
            if (!utils.isNullOrEmpty(this.query.categoryID)) {
                this.props.history.push(`/news/category`);
            } else {
                this.props.history.push('../index');
            }
        } else {
            message.error(res.msg);
        }
        
    }

    saveActivity = async () => {
        let { activityInfo } = this.store;

        if (utils.isNullOrEmpty(activityInfo.activityCode)) {
            message.error("请输入活动编码"); return;
        }

        if (utils.isNullOrEmpty(activityInfo.activityName)) {
            message.error("请输入活动编码"); return;
        }

        var res = await this.store.saveActivity();
        if (res.code == 0) {
            message.success("操作成功");
            this.activityVisible = false;
        }
        else {
            message.error(res.msg);
        }
    }

    getImageFileList = (images) => {
      
        let array = [];
        if (!images) {
            return array;
        }
        for (let i = 0; i < images.length; i++) {
            let item = images[i];
            if (item.action != 3) {
                array.push({ uid: i, id: (i + 1), status: 'done', url: item.fileName });
            }
           
        }

      
       
        return array;

    }




    render() {
        const { editorType } = this.props;
        const { filter, loading, dataInfo } = this.store
     
        return (
            <>
                <PageHeader
                    title={editorType == constants.EDITOR_TYPE_ADD ? '添加资讯' : '修改资讯'}
                    onBack={() => {
                        if (!utils.isNullOrEmpty(this.query.categoryID)) {
                            this.props.history.push('../category')
                        } else {
                            this.props.history.push('../index')
                        }
                    }}
        />


                <div className='product-single' >
                    <Spin spinning={loading} size='large'>
                        <Form className='product-single-form'>
                            <Form.Item style={{ marginBottom: 0 }} label="文章标题" name="title">
                                <Form.Item>
                                    <Input style={{ width: '60%' }} name="title" value={dataInfo.title} onChange={e => dataInfo.title = e.target.value} />
                                </Form.Item>
                            </Form.Item>
                            <Form.Item label="资讯栏目" name="presaleParticipants" className='product-single-form'>
                                <Form.Item>
                                    <CategorySelectCmpt multiple={false} placeholder="请选择" value={dataInfo.columnId} onChange={(val, name) => { dataInfo.columnId = val; dataInfo.columnName = name[0] }} />

                                </Form.Item>
                            </Form.Item>
                            {false &&
                                <Form.Item label="活动类别" style={{ marginBottom: 0 }} className='product-single-form'>
                                    <Form.Item>
                                        {/*<ActivityCmpt multiple={false} placeholder="请选择" onChange={(val, name) => { }} add={true}/>*/}
                                        <Row type="flex" className="product-choose-item">
                                            <div style={{ position: "relative" }}>
                                                <Input placeholder="请选择" style={{ width: "327px" }} />
                                                <div className="product-choose">
                                                    <Row type="flex" justify="space-between" className="product-choose-item">
                                                        <span>1</span>
                                                        <div>
                                                            <span style={{ color: "#1890ff" }}>Add</span>
                                                            <span style={{ color: "#1890ff" }}>del</span>

                                                        </div>
                                                    </Row>
                                                </div>
                                            </div>
                                            <button type="button" className='btn btn-primary ml10' onClick={() => {
                                                this.activityVisible = true;
                                            }}>添加类别</button>
                                        </Row>
                                    </Form.Item>
                                </Form.Item>
                            }
                            {false &&
                                <Form.Item label="专属活动" style={{ marginBottom: 0 }} className='product-single-form'>
                                    <Form.Item>
                                        <Checkbox checked={this.activityChecked} onChange={(e) => {
                                            this.activityChecked = !this.activityChecked
                                        }}>是</Checkbox>
                                    </Form.Item>
                                </Form.Item>
                            }
                            <Form.Item label="标签"   className='product-single-form'>
                                <div className='flex-start'>
                                    <Input placeholder='请输入标签' onChange={(e) => this.labelName = e.target.value} />
                                    <button type="button" className='btn btn-primary ml10' onClick={() => {

                                        if (!utils.isNullOrEmpty(this.labelName)) {
                                            if (utils.isNullOrEmpty(dataInfo.label)) {
                                                dataInfo.label = [];
                                            }
                                            dataInfo.label.push(this.labelName);
                                        }
                                    }}>添加标签</button>
                                </div>
                                {dataInfo.label &&
                                    <div className='featurebox'>
                                    {dataInfo.label.map((cr, i) => {
                                            return (
                                                <div className='list' key={i}>
                                                    <CloseCircleFilled className='del' onClick={() => {
                                                        dataInfo.label.remove(cr);
                                                    }} />
                                                    <img src={givestar} />
                                                    <span className='text'>{cr}</span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                }
                            </Form.Item>

                            <Form.Item label="排序" >
                                <Select
                                    style={{ width: '60%' }}
                                    value={dataInfo.orderBy && this.ordersZm.find(oz=>dataInfo.orderBy == oz.val).key}
                                    onChange={(value, options) => {
                                        dataInfo.orderBy = value;
                                  
                                    }}
                                >
                                    <Option value={null}>请选择...</Option>
                                    {this.ordersZm.map(oz => {
                                        return (
                                            <Option value={oz.val}>{oz.key}</Option>
                                            )
                                    })}
                                  
                               
                                </Select> 
                               
                            </Form.Item>

                            <Form.Item  label="简介" >
                                <TextArea style={{ width: '60%' }} rows={2} value={dataInfo.intro} onChange={e => dataInfo.intro = e.target.value} />
                            </Form.Item>

                            <Form.Item label="海报图" >
                                {dataInfo.logoImages &&
                                    <PicturesWall
                                    style={{ width: '60%', height: '100' }}
                                    fileList={this.getImageFileList(dataInfo.logoImages)}
                                    defaultDomain={constants.IMAGE_MDD_DOMAIN}
                                    showPosterIcon={true}
                                    bucketName={constants.IMAGE_MDD_BUCKETNAME}
                                    onChange={(images) => {
                                        dataInfo.logoImages = [];
                                        images.forEach(function (img) {
                                            dataInfo.logoImages.push({
                                                fileName: img.fileName
                                            });
                                        })

                                    }}
                                    />
                                }
                              
                            </Form.Item>

                            <Form.Item label="咨询外链" >
                                <Input style={{ width: '60%' }} placeholder="添加了外链列表内容优先跳转外链地址" value={dataInfo.externalLink} onChange={e => dataInfo.externalLink = e.target.value} />
                            </Form.Item>

                            <Form.Item label="资讯内容" >
                                <RichEditor placeholder="资讯内容" value={dataInfo.content}
                                    onChange={(value) => {
                                        dataInfo.content = value;

                                    }}

                                  contentStyle={{ height: 800, boxShadow: 'inset 0 1px 3px rgba(0,0,0,.1)' }} />
                            </Form.Item>


                            <div className='save-btnbox flex-center'>
                                <Button className='btn btn-primary' onClick={() => this.save()}>保存</Button>
                                <Button className='btn btn-primary' onClick={() => this.publish()}>保存并发布</Button>
                                <Button className='btn btn-customize' onClick={() => {
                                    this.props.history.push('../index')
                                }}>取消</Button>
                            </div>

                       </Form>
                    </Spin>


                </div>

                {this.renderActivity()}
            </>
        )
    }

    renderActivity() {
        const { activityInfo } = this.store
        return (
            <Modal
                title="部门编辑"
                centered

                visible={this.activityVisible}
                onCancel={() => this.activityVisible = false}
                okText='保存'
                onOk={() => { this.saveActivity() }}
                okButtonProps={{ icon: <SaveOutlined />, htmlType: 'submit' }}
            >

                <Form.Provider>

                    <Form.Item label="活动编码">
                        <Input style={{ width: '300px' }} value={activityInfo.activityCode} onChange={(e) => { activityInfo.activityCode = e.target.value }}/>

                    </Form.Item>

                    <Form.Item label="活动名称">
                        <Input style={{ width: '300px' }} value={activityInfo.activityName} onChange={(e) => { activityInfo.activityName = e.target.value }}/>

                    </Form.Item>

                    <Form.Item label="活动排序">
                        <InputNumber style={{ width: '300px' }} value={activityInfo.level} onChange={(e) => { activityInfo.level = e }} />
                    </Form.Item>

                </Form.Provider>

            </Modal>
            )
    }
}
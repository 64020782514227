import { observable, action, toJS } from "mobx"
import api from "../utils/api"
import constants from "../utils/constants"
import utils from '../utils'


export default class DrillStore {

    @observable loading = false;
    @observable dataList = [];
    @observable dataInfo = {}

    @observable filter = {
        title: '',
        category:''

    }
    @observable pagination = {
        current: 1,
        pageSize: 15,
        total: 0
    }

    @action.bound
    async fetch(pagination) {
        pagination = {
            ...this.pagination,
            ...pagination,
        }

        try {
            this.loading = true;
            const { current, pageSize } = pagination;
            const {
                title,
                category
            } = this.filter;


            var result = await api.drill.gets({
                pageCount: current,
                pageSize: pageSize,
                title: title,
                category: category
            });
            this.pagination = {
                current,
                pageSize,
                total: result.totalResults
            }

            this.dataList = result.items
        } catch (e) {
            throw e
        } finally {
            this.loading = false;
        }
    }



    @action.bound
    async save() {

        console.log("res", toJS(this.dataInfo));
        try {
            this.loading = true;
            var res = await api.drill.edit(this.dataInfo);
            return res;

        } catch (e) {
            throw e
        } finally {
            this.loading = false;
        }


    }


    @action.bound
    async get(id) {
        try {
            this.loading = true;
            var res = await api.drill.get({ id: id });
            console.log("res", toJS(res));
            this.dataInfo = res.info;

        } catch (e) {
            throw e
        } finally {
            this.loading = false;
        }
    }



    @action.bound
    async del(id) {
        try {
            this.loading = true;
            var res = await api.drill.del({ id: id });
            return res;

        } catch (e) {
            throw e
        } finally {
            this.loading = false;
        }
    }
}
import { observable, action, toJS } from "mobx"
import api from "../utils/api"
import constants from "../utils/constants"

import moment from 'moment'
export default class CardStore {

    @observable loading = false;

    @observable pagination = {
        current: 1,
        pageSize: 15,
        total: 0,

        pageSizeExcel: 9999
    }

    @observable dataList = [];
    @observable filter = {
        classVal: '',
        lable:''
    }

    @observable dataInfo = {
        cardNo: '',
        lable: '',
        class: '',
        isActivation: false,
        isPl: false,
        num: 1

    }

    @observable excelData = [];



    @action.bound
    async fetch(pagination, excel = false) {
        pagination = {
            ...this.pagination,
            ...pagination,
        }

        try {
            this.loading = true;
            const { current, pageSize, pageSizeExcel} = pagination;
            const {
                classVal,
                lable

            } = this.filter;

            console.log("", {
                pageCount: current,
                pageSize: !excel ? pageSize : pageSizeExcel,
                class: classVal,
                lable

            });
            var result = await api.survey.getCards({
                pageCount: current,
                pageSize: !excel ? pageSize : pageSizeExcel,
           
                class: classVal,
                lable

            });
            this.pagination = {
                current,
                pageSize,
                total: result.totalResults
            }

            if (!excel) {
                this.dataList = result.items
            } else {
                this.excelData = result.items
            }
            console.log("this.excelData ", this.excelData );
        } catch (e) {
            throw e
        } finally {
            this.loading = false;
        }
    }

    @action.bound
    async saveCard() {

        console.log("res", toJS(this.dataInfo));
        try {
            this.loading = true;
            var res = await api.survey.saveCard(this.dataInfo);
            return res;

        } catch (e) {
            throw e
        } finally {
            this.loading = false;
        }
      
     
    }

    @action.bound
    async cardDel(id) {
        try {
            this.loading = true;
            var res = await api.survey.cardDel({ id: id });
            return res;

        } catch (e) {
            throw e
        } finally {
            this.loading = false;
        }
    }

    @observable surveyFilter = {
        lable: '',
        classVal: '',
        createTime: null,
        createDateRange: [],
        types:null
    }

    @action.bound
    async fetch_survey(pagination,excel=false) {
        pagination = {
            ...this.pagination,
            ...pagination,
        }

        try {
            this.loading = true;
            const { current, pageSize, pageSizeExcel} = pagination;
            const { lable, classVal, createDateRange, types } = this.surveyFilter;
            console.log("createDateRange", {
                pageCount: current,
                pageSize: !excel ? pageSize : pageSizeExcel,
                class: classVal,
                types: types,
                lable: lable,
                startDate: createDateRange.length > 0 ? moment(createDateRange[0]).format(constants.DEFAULT_DATETIME_FORMAT) : null,
                endDate: createDateRange.length > 1 ? moment(createDateRange[1]).format(constants.DEFAULT_DATETIME_FORMAT) : null

            });
            var result = await api.survey.geDataSheet({
                pageCount: current,
                pageSize: !excel ? pageSize : pageSizeExcel,
                class: classVal,
                types: types,
                lable: lable,
                startDate: createDateRange.length > 0 ? moment(createDateRange[0]).format(constants.DEFAULT_DATETIME_FORMAT) :null,
                endDate: createDateRange.length > 1 ? moment(createDateRange[1]).format(constants.DEFAULT_DATETIME_FORMAT) :null

            });
            this.pagination = {
                current,
                pageSize,
                total: result.totalResults
            }
            if (!excel) {
                this.dataList = result.items
            } else {
                this.excelData = result.items
            }
            console.log("result", result);
        } catch (e) {
            throw e
        } finally {
            this.loading = false;
        }
    }
}
import React, { Component } from 'react';
import { Form, Button, Input, Row, Col, Checkbox, PageHeader, Descriptions, message, Spin, Select } from 'antd';
import { AntTable, PicturesWall, AntDescriptions } from '../../components'

import { observable, toJS } from 'mobx';
import { observer } from "mobx-react"
import { Element } from 'react-scroll'
import { SearchOutlined } from '@ant-design/icons';
import stores, { news } from '../../stores'
import constants from '../../utils/constants'
import BraftEditor from 'braft-editor'

import '../base-editor.scss'
import qs from 'qs'
import 'braft-editor/dist/index.css'
import AdvertiStore from '../../stores/advertiStore';
import ActivityListCmpt from '../components/activity.list.cmpt'
import utils from '../../utils';
import { CloseCircleFilled } from '@ant-design/icons';
const { TextArea } = Input;
const { Option } = Select;
export default @observer class AdvertiActivityEditor extends Component {

    store = new AdvertiStore()
    @observable activityVisible = false

    constructor(props) {
        super(props)
        this.query = qs.parse(props.location.search.substr(1))

    }

    async componentDidMount() {

        await this.store.gets(this.query.id);

        if (utils.isNullOrEmpty(this.store.dataInfo.logoImages)) {
            this.store.dataInfo.logoImages = [];
        }

        console.log(" this.store.dataInfo", this.store.dataInfo);
    }



    publish = () => {
        this.store.dataInfo.isPublish = true;
        this.save();
    }

    save = async () => {

        let { dataInfo } = this.store;

        if (utils.isNullOrEmpty(dataInfo.title)) {
            message.error("请输入标题"); return;
        }

        if (dataInfo.logoImages.length == 0) {
            message.error("请选择封面图"); return;
        }

        //if (utils.isNullOrEmpty(dataInfo.url)) {
        //    message.error("请输入链接"); return;
        //}
        if (!utils.isNullOrEmpty(dataInfo.weights)) {
            dataInfo.weights = parseInt(dataInfo.weights);
        }
        console.log("dataInfo", dataInfo);
        dataInfo.style = 1;
        var res = await this.store.save();
        if (res.code == 0) {
            message.success("操作成功");
            this.props.history.push(`/activity/activity-adverti`);

        } else {
            message.error(res.msg);
        }




    }

    getImageFileList = (images) => {

        let array = [];
        if (!images) {
            return array;
        }
        for (let i = 0; i < images.length; i++) {
            let item = images[i];
            if (item.action != 3) {
                array.push({ uid: i, id: (i + 1), status: 'done', url: item.fileName });
            }

        }



        return array;

    }

    render() {
        const { editorType } = this.props;
        const { filter, loading, dataInfo } = this.store


        return (
            <>
                <PageHeader
                    title={editorType == constants.EDITOR_TYPE_ADD ? '添加广告' : '修改广告'}
                    onBack={() => this.props.history.push(`/activity/activity-adverti`)}
                />


                <div className='product-single' >
                    <Spin spinning={loading} size='large'>
                        <Form className='product-single-form'>
                            <Form.Item style={{ marginBottom: 0 }} label="广告标题" name="title">
                                <Form.Item>
                                    <Input style={{ width: '60%' }} name="title" value={dataInfo.title} onChange={e => dataInfo.title = e.target.value} />
                                </Form.Item>
                            </Form.Item>
                          
                            <Form.Item label="选择活动">
                                <div className='flex-start'>
                                    <Input placeholder='请选择活动' value={dataInfo.newsTitle} />
                                    <button type="button" className='btn btn-primary ml10' onClick={() => {
                                        this.activityVisible = true;
                                    }}>选择活动</button>
                                </div>

                            </Form.Item>


                            <Form.Item style={{ marginBottom: 0 }} label="链接" name="title">
                                <Form.Item>
                                    <Input style={{ width: '60%' }} name="title" value={dataInfo.url} onChange={e => dataInfo.url = e.target.value} />
                                </Form.Item>
                            </Form.Item>

                            <Form.Item style={{ marginBottom: 0 }} label="排序权重" name="title">
                                <Form.Item>
                                    <Input style={{ width: '60%' }} name="weights" value={dataInfo.weights} onChange={e => dataInfo.weights = e.target.value} />
                                </Form.Item>
                            </Form.Item>

                            <Form.Item label="封面图" >
                                {dataInfo.logoImages &&
                                    <div>
                                    <PicturesWall
                                        style={{ width: '20%', height: '100' }}
                                        fileList={this.getImageFileList(dataInfo.logoImages)}
                                        defaultDomain={constants.IMAGE_MDD_DOMAIN}
                                        showPosterIcon={true}
                                        bucketName={constants.IMAGE_MDD_BUCKETNAME}
                                        onChange={(images) => {
                                            dataInfo.logoImages = [];
                                            images.forEach(function (img) {
                                                dataInfo.logoImages.push({
                                                    fileName: img.fileName
                                                });
                                            })

                                        }}
                                    />
                                    <div style={{ color: "red" }}>建议尺寸：750x234</div>
                                    </div>
                                }


                            </Form.Item>


                            <div className='save-btnbox flex-center'>
                                <Button className='btn btn-primary' onClick={() => this.save()}>保存</Button>
                                <Button className='btn btn-primary' onClick={() => this.publish()}>保存并发布</Button>
                                <Button className='btn btn-customize' onClick={() => {
                                    this.props.history.push('../activity-list')
                                }}>取消</Button>
                            </div>

                        </Form>
                    </Spin>
                </div>


                <ActivityListCmpt
                    visible={this.activityVisible}
                    onClose={() => {
                        this.activityVisible = false;
                    }}
                    onOk={(val) => {
                     
                        this.activityVisible = false;
                        dataInfo.newsTitle = val.name;
                        dataInfo.newsId = val.id;

                        dataInfo.url = `/pages/index2/index?id=${val.id}`
                      
                    }}
                />
            </>
        )
    }
}
import React, { Component } from 'react';
import { Form, Button, Input, Row, Col, Checkbox, Popconfirm, Radio, Popover, Select, PageHeader, Tooltip, Modal, Divider, Menu, message, Badge, Descriptions } from 'antd';
import { AntTable, AntDescriptions } from '../../components'
import { observable, toJS } from 'mobx';
import { observer } from "mobx-react"
import { Element } from 'react-scroll'
import { SearchOutlined, SaveOutlined } from '@ant-design/icons';
import utils from '../../utils';
import moment from 'moment'
import constants from '../../utils/constants'
import ActivityStore from '../../stores/activityStore';
import staticData from '../../utils/staticData.json'
const { Option } = Select;
const { TextArea } = Input;
const { SubMenu } = Menu;
export default @observer class ActivityDemand extends Component {

    store = new ActivityStore()

    @observable modalVisible = false;
    @observable flag = 1;
    constructor(props) {
        super(props)
        console.log("store", this.store);
    }
    async componentDidMount() {

        await this.store.fetch_company_demand();
    }

    handleTableChange = async (pagination) => {
        try {
            await this.store.fetch_company_demand(pagination)

        }
        catch (e) {
            message.error(e.message)
        }
    }

   
    handleSearch = async () => {
        const pagination = {
            ...this.store.pagination,
            current: 1,
        }

        try {
            await this.store.fetch_company_demand(pagination)
        }
        catch (e) {
            message.error(e.message)
        }
    }
    handleIsPublish = async (data) => {
        console.log("data", toJS(data));
        this.store.dataInfo = data;
        this.store.dataInfo.isPublish = !data.isPublish;
        await this.store.save();
        await this.store.fetch();
    }


    handleSave = async () => {
        const { dataInfo } = this.store;
        if (utils.isNullOrEmpty(dataInfo.name)) {
            message.error("请输入活动名称"); return;
        }

        var res = await this.store.save();
        if (res.code == 0) {
            this.modalVisible = false;
            message.success("保存成功");
            await this.store.fetch();
        } else {
            message.error(res.message);
        }

    }



    render() {
        const { dataInfo, pagination, dataList, loading, filter } = this.store

        return (
            <>
                <PageHeader title='企业需求' style={{ paddingBottom: 0 }} />

                <div className='filter-box filter-box-title-110'>

                   


                    <AntDescriptions style={{ marginTop: 20 }}>

                        <Descriptions.Item label="类型类型" span={1}>
                            <Select
                                style={{ width: '100%' }}
                                value={filter.title}
                                onChange={(value, options) => {
                                    filter.title = value

                                }}
                            >
                                <Option value={null}>请选择...</Option>
                                {staticData.CompanyDemand.map((d) => {
                                    return (
                                        <Option value={d}>{d}</Option>
                                        )
                                })}
                               
                          

                            </Select>
                        </Descriptions.Item>

                        <Descriptions.Item  span={1}>
                            <Button type='primary' onClick={this.handleSearch} icon={<SearchOutlined />} loading={this.loading}>搜索</Button>
                        </Descriptions.Item>
                    </AntDescriptions>

                   
                </div>


                <Element name="scrollElement">
                    <AntTable

                        dataSource={dataList}
                        loading={loading}
                        rowKey={record => record.code}
                        pagination={{
                            ...pagination,
                            showSizeChanger: true
                        }}
                       
                        onChange={this.handleTableChange}
                        columns={[
                            {
                                title: 'IP地址',
                                width: 100,
                                ellipsis: true,
                                dataIndex: 'ip',

                            },

                            {
                                title: '需求类型',
                                width: 100,

                                render: (text, record) => (
                                    record.category && record.category.join(',')
                                )
                            },
                           
                            {
                                title: '内容',
                                width: 100,
                                ellipsis: true,
                                dataIndex: 'content',
                                render: (text, record) => (
                                    <Tooltip placement="topLeft" title={record.content}>
                                        {record.content}
                                    </Tooltip>
                                ),
                            },
                            {
                                title: '添加时间',
                                width: 100,

                                render: (text, record) => (
                                    moment(record.createTime).format(constants.DEFAULT_DATETIME_FORMAT)
                                ),
                            }
                        ]}
                    />
                </Element>



            </>
        )
    }


  
}
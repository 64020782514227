import React, { Component } from 'react';

export default class Dashboard extends Component {
    render() {
        return (
            <pre>
             公告：欢迎使用资讯管理系统
            </pre>
        )
    }
}
import React, { Component } from 'react';
import { Form, Button, Input, Row, Col, Checkbox, Popconfirm, PageHeader, Descriptions, Select, Divider, Modal, Badge, message } from 'antd';
import { AntTable, AntDescriptions } from '../../components'
import { observable, toJS } from 'mobx';
import { observer } from "mobx-react"
import { SearchOutlined } from '@ant-design/icons';
import { Element } from 'react-scroll'
import ExportJsonExcel from "js-export-excel"
import moment from 'moment'
import utils from '../../utils';
import constants from '../../utils/constants'
import cardStore from '../../stores/cardStore';
const { Option } = Select;
export default @observer class Card extends Component {
    store = new cardStore();

    @observable cardVisible = false

    @observable cardModify = {
        visible: false,
        edit: 'add'
    }
    async componentDidMount() {
        await this.store.fetch();
    }


    handleTableChange = async (pagination) => {
        try {
            await this.store.fetch(pagination)

        }
        catch (e) {
            message.error(e.message)
        }
    }

    handleSearch = async () => {
        const pagination = {
            ...this.store.pagination,
            current: 1,
        }

        try {
            await this.store.fetch(pagination)
        }
        catch (e) {
            message.error(e.message)
        }
    }

    async save() {
        this.cardModify.visible = false;
        var res= await this.store.saveCard();
        if (res.code == 0) {
            await this.store.fetch();
            message.success("操作成功");
        }

     
    }

    handleDel = async (id) => {
        var res = await this.store.cardDel(id);
        console.log("res", res);
        if (res.code == 0) {
            message.success("删除成功");
            await this.store.fetch();
        } else {
            message.error("删除失败")

        }

    }

    handleExcel = async () => {
     
        const pagination = {
            current: 1,
            pageSizeExcel: 9999
        }
        await this.store.fetch(pagination, true);
        let option = {};
        if (this.store.excelData.length == 0) {
            message.error("没有相关数据，导出数据失败"); return;
        }
        this.store.excelData.forEach(e => {
            e.isActivation = e.isActivation ? '是' : '否';
            e.activaTime = utils.isNullOrEmpty(e.activaTime) ? "" : moment(e.activaTime).format(constants.DEFAULT_DATETIME_FORMAT)
        });  //数据源
        option.fileName = "卡号表" + moment().format('YYYYMMDDHHmmss');  //excel文件名称
        option.datas = [
            {
                sheetData: this.store.excelData,  
                sheetName: 'Sheet1',  
                sheetFilter: ['cardNo', 'lable', 'class', 'isActivation', 'activaTime'],  
                sheetHeader: ['卡号', '标签', '类型', '是否激活', '激活时间'], 
            }
        ]
        let toExcel = new ExportJsonExcel(option);  //生成excel文件
        toExcel.saveExcel();  //下载excel文件
    }

    render() {
        const { filter, loading, dataList, pagination, dataInfo } = this.store
        return (
            <>
                <PageHeader title='卡号管理' style={{ paddingBottom: 0 }} />

                <div className='filter-box filter-box-title-110'>
                    <AntDescriptions>

                        <Descriptions.Item label="类型" span={1}>
                            <Select
                                style={{ width: '100%' }}
                                value={filter.classVal}
                                onChange={(value, options) => {
                                    filter.classVal= value

                                }}
                            >
                                <Option value={null}>请选择...</Option>
                                <Option value="VIP0">VIP0</Option>
                                <Option value="VIP1">VIP1</Option>
                                <Option value="VIP2">VIP2</Option>
                                <Option value="VIP3">VIP3</Option>
                            </Select>

                           
                        </Descriptions.Item>
                        <Descriptions.Item label="标签" span={1}>
                            <Select
                                style={{ width: '100%' }}
                                value={filter.lable}
                                onChange={(value, options) => {
                                    filter.lable = value

                                }}
                            >
                                <Option value={null}>请选择...</Option>
                                <Option value="GDHR">GDHR</Option>
                                <Option value="SOA">SOA</Option>

                            </Select>
                           
                        </Descriptions.Item>
                     
                    </AntDescriptions>

                    <Row>
                        <Col>
                            <Button type='primary' onClick={() => {
                                this.cardModify = {
                                    visible: true,
                                    edit: 'add'
                                }
                            }}>添加卡号</Button>
                        </Col>
                        <Col flex={1} />
                        <Col>
                            <Button type='primary' onClick={this.handleSearch} icon={<SearchOutlined />} loading={this.loading} style={{ marginRight:'20px' }}>查询</Button>

                            <Button type='primary' onClick={this.handleExcel} icon={<SearchOutlined />}>导出Excel</Button>
                        </Col>
                    </Row>
                </div>


                <Element name="scrollElement">
                    <AntTable
                        dataSource={dataList}
                        loading={loading}
                        rowKey={record => record.code}
                        pagination={{
                            ...pagination,
                            showSizeChanger: true
                        }}
                        onChange={this.handleTableChange}
                        columns={[
                            {
                                title: '卡号',
                                width: 150,
         
                                dataIndex: 'cardNo',

                            },

                            {
                                title: '类型',
                                width: 100,
                                dataIndex: 'class',
                            },
                            {
                                title: '标签',
                                width: 100,
                                dataIndex: 'lable',
                            },
                            {
                                title: '状态',
                                width: 100,
                                dataIndex: '',
                                render: (text, record) => (
                                    <Badge
                                        color={record.isEditing ? 'yellow' : record.isActivation ? 'green' : '#d9d9d9'}
                                        text={record.isEditing ? '编辑中' : record.isActivation ? '已激活' : '未激活'}
                                    />
                                ),
                            },
                            {
                                title: '激活时间',
                                width: 100,
                               
                                render: (text, record) => (
                                    <>
                                        {record.isActivation ? moment(record.activaTime).format(constants.DEFAULT_DATETIME_FORMAT):''}
                              
                                      </>    
                                    ),
                                },
    
                            {
                                title: '操作',
                                width: 110,
                                align: 'center',
                                fixed: 'right',
                                render: (text, record) => (
                                    <>
                                        <Button type='link' onClick={() => {
                                            this.cardModify = { visible: true, edit: 'edit' }
                                            this.store.dataInfo = record
                                        }}>修改</Button>
                                        <Divider type="vertical" />

                                        <Popconfirm title="确定要删除吗？" onConfirm={() => this.handleDel(record.id)}>
                                            <Button type='link'>删除</Button>
                                        </Popconfirm>

                                    </>
                                ),
                            },
                        ]}
                    />
                </Element>



                <Modal width='30%'
                    title={this.cardModify.edit == 'add' ? '添加' : '编辑'}
                    visible={this.cardModify.visible}
                    onCancel={() => { this.cardModify.visible = false }}
                    onOk={() => { this.save() }}>

                    <div>
                      
                            <Form className='product-single-form'>
                                <Form.Item label="卡类型" >
                                    <Select
                                        style={{ width: '50%' }}
                                    value={dataInfo.class}
                                    onChange={(value, options) => {
                                        dataInfo.class = value

                                        }}
                                    >
                                        <Option value={null}>请选择...</Option>
                                        <Option value="VIP0">VIP0</Option>
                                        <Option value="VIP1">VIP1</Option>
                                        <Option value="VIP2">VIP2</Option>
                                        <Option value="VIP3">VIP3</Option>
                                    </Select>

                                </Form.Item>

                                <Form.Item label="卡标签" >
                                    <Select
                                        style={{ width: '50%' }}
                                    value={dataInfo.lable}
                                    onChange={(value, options) => {
                                        dataInfo.lable = value

                                        }}
                                    >
                                        <Option value={null}>请选择...</Option>
                                        <Option value="GDHR">GDHR</Option>
                                         <Option value="SOA">SOA</Option>

                                    </Select>

                                </Form.Item>

                                <Form.Item label="是否激活" >
                                    <Checkbox checked={dataInfo.isActivation} onChange={(e) => { dataInfo.isActivation = e.target.checked }} />
                            </Form.Item>
                            {this.cardModify.edit == 'add' &&
                                <Form.Item label="是否批量" >
                                    <div>
                                        <Checkbox checked={dataInfo.isPl} onChange={(e) => { dataInfo.isPl = e.target.checked }} />
                                    </div>
                                </Form.Item>
                            }
                                {dataInfo.isPl &&
                                <Form.Item label="批量数" >

                                <Select
                                    style={{ width: '50%' }}
                                    value={dataInfo.num}
                                    onChange={(value, options) => {
                                        dataInfo.num = parseInt(value)

                                    }}
                                >
                                    <Option value={1}>请选择...</Option>
                                    <Option value="5">5</Option>
                                    <Option value="10">10</Option>
                                
                                    <Option value="20">20</Option>
                                    <Option value="100">100</Option>
                                    <Option value="500">500</Option>
                                    <Option value="1000">1000</Option>

                                </Select>
                                  
                                    </Form.Item>
                                }

                                
                            </Form>
                       
                    </div>

                </Modal>
            </>
        )
    }
}